import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import api from "@/services/api.js";

import { useParams } from "react-router-dom";
import useStore from "@/services/store";
import { HiOutlineDownload } from "react-icons/hi";

const METHODS = {
  dcf: "DCF",
  vc: "VC",
  dilutive: "Dilutive",
  comparative: "Comparative",
  scorecard: "Scorecard",
  berkus: "Berkus",
  stepup: "Stepup",
};

const Result = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useStore();

  const [company, setCompany] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        const data = res.data;
        setCompany(data);
      }
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
  };

  if (company && company.valuation_status === "FINISHED" && company.valuation_document.file_url)
    return (
      <div className="w-full h-full space-y-8 px-40">
        <h1 className="font-semibold text-xl">Voici votre rapport de valorisation {user.first_name} !</h1>
        <p className="text-base text-gray-500">{t("result.finish.text")}</p>

        <div className="flex justify-end">
          <a href={company.valuation_document.file_url} target="_blank" rel="noreferrer" className="primary-button flex gap-2">
            {t("result.download")}
            <HiOutlineDownload className="w-5" />
          </a>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {Object.keys(METHODS).map((method, index) => (
            <div key={index} className="border border-gray-200 rounded-xl p-4">
              <h3 className="text-sm font-semibold mb-4">Méthode {METHODS[method]}</h3>
              <div className="flex justify-center p-4">
                <span className="text-xl font-semibold">{Math.round(company[`valuation_${method}`] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="w-full h-full space-y-8 px-40">
      <h1 className="font-semibold text-center text-xl">{t("result_not_done.title")}</h1>
      <p className="text-base text-center  text-gray-500">{t("result_not_done.text")}</p>
    </div>
  );
};

export default Result;
