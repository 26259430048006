import React from "react";
import { RiCloseLine } from "react-icons/ri";

import Modal from "@/components/Modal";

const ContactModal = ({ show, onNext, onClose }) => {
  return (
    <Modal show={show} onClose={onClose} className="w-[90%] md:w-[700px] !p-4">
      <div className="max-h-[80vh] relative">
        <div className="absolute top-2 right-2">
          <button onClick={onClose} className="p-2">
            <RiCloseLine className="w-6 h-6" />
          </button>
        </div>
        <div className="truncate px-10 py-4">
          <div className="flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-4">Demande de mise en relation</h3>
          </div>

          <p className="font-semibold text-center mt-10">Souhaitez vous contacter le propriétaire de l'annonce ?</p>

          <div className="flex justify-center items-center mt-10">
            <button onClick={onNext} className="primary-button text-sm">
              Contacter le propriétaire
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
