import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { DocumentTextIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";

import { MdDeleteOutline } from "react-icons/md";

import api from "@/services/api";
import ImageUploadSVG from "@/assets/svg/upload-image.svg";
import UploadFileSVG from "@/assets/svg/upload-file.svg";
import ConfirmModal from "@/components/ConfirmModal";
import Loader from "@/components/Loader";

import MultiSelect from "../components/Multiselect";

import Modal from "../../../components/Modal";

import { CALENDLY_COMMERCIAL } from "@/services/config";

const TYPES = ["Professionel indépendant", "Entreprise de 1-19 employés", "Startup émergente", "Entreprise de 20+ employés"];

const ACTIVITIES = [
  "Agroalimentaire",
  "Banque / Assurance",
  "Bois / Papier / Carton / Imprimerie",
  "BTP / Matériaux de construction",
  "Chimie / Parachimie",
  "Commerce / Négoce / Distribution",
  "Édition / Communication / Multimédia",
  "Électronique / Électricité",
  "Études et conseils",
  "Industrie pharmaceutique",
  "Informatique / Télécoms",
  "Machines et équipements / Automobile",
  "Métallurgie / Travail du métal",
  "Transports / Logistique",
  "Services aux entreprises",
  "Plastique / Caoutchouc",
  "Textile / Habillement / Chaussure",
];

const Logo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "LOGO" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "TEAM" });
      if (res.ok) {
        navigate(`/investisseurs/${id}/create/team`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <h1 className="text-primary text-xl">Logo</h1>
      <div className="flex items-center justify-center self-center w-[30%]">
        <ImageInput folder="/logo" name="logo" value={values.logo} onChange={(e) => setValues({ ...values, logo: e.target.value })} />
      </div>

      <div className="flex right-0 bg-white items-center justify-between gap-4 w-full p-4">
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../criteria" className="empty-button w-48">
            Précédent
          </Link>
          <button type="button" className="primary-button w-48" onClick={handleNext}>
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}
      >
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

export default Logo;
