import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { MdDeleteOutline } from "react-icons/md";

import useStore from "@/services/store";
import ImageUploadSVG from "@/assets/svg/upload-image.svg";
import Loader from "@/components/Loader";
import api from "@/services/api";

const Account = () => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const [values, setValues] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await api.put(`/user`, values);
      if (!res.ok) throw new Error(res);
      setUser(res.data);
      toast.success("Updated successfully");
    } catch (error) {
      console.error("error", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="p-8 flex flex-col items-center">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Mon profil</h1>
        <p className="text-base text-gray-500">Gérez vos informations personnelles et professionnelles.</p>
      </header>
      <form onSubmit={handleSubmit} className="mt-10 flex flex-col items-center justify-center space-y-10 w-4/5">
        <div className="gap-y-2 flex flex-col items-center">
          <label className="text-base font-medium" htmlFor="avatar">
            Votre Photo Profile
          </label>
          <div className="w-24 h-24">
            <ImageInput folder="/logo" name="avatar" value={values.avatar} onChange={(e) => setValues({ ...values, avatar: e.target.value })} />
          </div>
        </div>

        <div className="flex-1 grid grid-cols-2 gap-x-4 gap-y-8">
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="first_name">
              Prénom
            </label>
            <input
              type="text"
              name="first_name"
              className="input"
              value={values.first_name}
              onChange={(e) => setValues({ ...values, first_name: e.target.value })}
              placeholder="Entrez le nom de votre association"
            />
          </div>
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="last_name">
              Nom
            </label>
            <input
              type="text"
              name="last_name"
              className="input"
              value={values.last_name}
              onChange={(e) => setValues({ ...values, last_name: e.target.value })}
              placeholder="Entrez le nom de votre association"
            />
          </div>
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="email">
              Adresse e-mail
            </label>
            <input
              type="email"
              name="email"
              disabled
              className="input"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              placeholder="Entrez votre adresse e-mail"
            />
          </div>
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="phone">
              Numéro de téléphone
            </label>
            <input name="phone" className="input" value={values.phone} onChange={(e) => setValues({ ...values, phone: e.target.value })} placeholder="01 23 34 56 78" />
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <button className="primary-button" type="submit">
            Mettre à jour le profil
          </button>
        </div>
      </form>
    </div>
  );
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}
      >
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

export default Account;
