import { Route, Routes } from "react-router-dom";

import SME from "./sme";
import StartUp from "./startup";

const ValuationGameResult = () => {
  return (
    <Routes>
      <Route path="sme/:companyId" element={<SME />} />
      <Route path="start-up/:companyId" element={<StartUp />} />
    </Routes>
  );
};

export default ValuationGameResult;
