import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import CompanyForm from "./form";
import Product from "./product";

const New = () => {
  const [company, setCompany] = useState({
    levee: true,
    levee_plan: "",
    company_name: "",
    company_siren: "",
    company_category: "",
    company_activity: "",
  });
  return (
    <Routes>
      <Route path="/" element={<Product company={company} setCompany={setCompany} />} />
      <Route path="/form" element={<CompanyForm company={company} setCompany={setCompany} />} />
    </Routes>
  );
};

export default New;
