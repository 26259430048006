import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import toast from "react-hot-toast";
import api from "@/services/api";
import Loader from "@/components/Loader";

const PaymentSuccess = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updatePayment = async () => {
      // recover a token that validates the payment
      setLoading(true);
      try {
        const token = searchParams.get("token");
        const res = await api.post(`/payment/${companyId}/confirm`, { token });
        if (res.ok) {
          toast.success("Payment réussi");
          setSearchParams({});
        } else {
          toast.error("Payment échoué");
          navigate(`../product?failed=true`);
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setLoading(false);
    };
    updatePayment();
  }, [companyId]);

  if (loading) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <h1 className="text-center text-2xl font-semibold my-8">Paiement validé</h1>
      <p className="text-center">Cliquer sur le bouton ci-dessous pour commencer le formulaire</p>
      <div className="flex justify-center w-full">
        <Link to={`../form-human`} className="primary-button px-10">
          Commencer le formulaire
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
