import api from "@/services/api";
import { CheckIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const New = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    annonce: true,
    annonce_type: "",
    annonce_created_at: new Date(),
  });

  const handleCreate = async () => {
    try {
      if (values.annonce_type === "investor" || values.annonce_type === "buyer") {
        const res = await api.post("/investor", values);
        if (res.ok) {
          navigate(`/investisseurs/${res.data._id}/create`);
        }
      } else {
        const res = await api.post("/company", values);
        if (res.ok) {
          navigate(`/annonce/${res.data._id}/create`);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="w-full h-full p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Ajouter une annonce</h1>
        <p className="text-base text-gray-500"> </p>
      </header>
      <div className="space-y-10 flex flex-col items-center mt-10">
        <h1 className="text-primary text-xl font-semibold">Quel type d'annonce souhaitez-vous déposer ?</h1>
        <div className="relative flex space-x-16 w-3/4">
          <div className="space-y-5 w-1/2 h-full">
            <button
              className={`flex items-center justify-between p-4 border-secondary rounded-lg w-full cursor-pointer border text-sm ${
                values.annonce_type === "buyer" ? "bg-secondary" : ""
              }`}
              onClick={() => setValues({ ...values, annonce_type: "buyer" })}
            >
              <p className="text-sm">Je souhaite déposer une annonce de reprise</p>
              {values.annonce_type === "buyer" && (
                <div className="p-1 rounded-full bg-primary flex justify-center items-center mr-4">
                  <CheckIcon className="w-4 h-4 text-white" />
                </div>
              )}
            </button>
            <button
              className={`flex items-center justify-between border-secondary rounded-lg p-4 w-full cursor-pointer border text-sm ${
                values.annonce_type === "investor" ? "bg-secondary" : ""
              }`}
              onClick={() => setValues({ ...values, annonce_type: "investor" })}
            >
              <p className="text-sm">Je souhaite déposer une annonce d'investissement</p>
              {values.annonce_type === "investor" && (
                <div className="p-1 rounded-full bg-primary flex justify-center items-center mr-4">
                  <CheckIcon className="w-4 h-4 text-white" />
                </div>
              )}
            </button>
          </div>
          <div className="w-px border-r border-dashed border-slate-300"></div>

          <div className="space-y-5 w-1/2">
            <button
              className={`flex items-center justify-between p-4 border-secondary rounded-lg w-full cursor-pointer border text-sm ${
                values.annonce_type === "cession" ? "bg-secondary" : ""
              }`}
              onClick={() => setValues({ ...values, annonce_type: "cession" })}
            >
              <p className="text-sm">Je souhaite déposer une annonce de cession</p>
              {values.annonce_type === "cession" && (
                <div className="p-1 rounded-full bg-primary flex justify-center items-center mr-4">
                  <CheckIcon className="w-4 h-4 text-white" />
                </div>
              )}
            </button>
            <button
              className={`flex items-center justify-between border-secondary rounded-lg p-4 w-full cursor-pointer border text-sm ${
                values.annonce_type === "levee" ? "bg-secondary" : ""
              }`}
              onClick={() => setValues({ ...values, annonce_type: "levee" })}
            >
              <p className="text-sm">Je souhaite déposer une annonce de levée de fonds</p>
              {values.annonce_type === "levee" && (
                <div className="p-1 rounded-full bg-primary flex justify-center items-center mr-4">
                  <CheckIcon className="w-4 h-4 text-white" />
                </div>
              )}
            </button>
          </div>
        </div>

        <button type="button" disabled={!values.annonce_type} className="primary-button mt-10" onClick={handleCreate}>
          Commencer
        </button>
      </div>
    </div>
  );
};

export default New;
