import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import SingleSelect from "../components/SingleSelect";
import MultiSelect from "../components/MultiSelect";

const InnovationStateOfArt = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-innovation-rnd`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <MultiSelect question={questions?.find((q) => q.key === "type_of_innovation")} values={values} setValues={setValues} />

      <SingleSelect question={questions?.find((q) => q.key === "technology_origin")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "tech_maturity_stage")} values={values} setValues={setValues} />

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.is_your_offer_based_on_innovative_tech")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_yes"
              name="is_product_based_on_others_tech_innovation"
              onChange={() => setValues((prev) => ({ ...prev, is_product_based_on_others_tech_innovation: true }))}
              checked={values?.is_product_based_on_others_tech_innovation === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="competing_solution_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_no"
              name="is_product_based_on_others_tech_innovation"
              onChange={() => setValues((prev) => ({ ...prev, is_product_based_on_others_tech_innovation: false }))}
              checked={values?.is_product_based_on_others_tech_innovation === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="competing_solution_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <SingleSelect question={questions?.find((q) => q.key === "others_tech_maturity_stage")} values={values} setValues={setValues} />

      <div className="flex justify-between w-full">
        <Link to={`../form-strategy-growth`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InnovationStateOfArt;
