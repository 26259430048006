import { Link } from "react-router-dom";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";

const Valuation = ({ company, setCompany }) => {
  const isDataValid = () =>
    company.valuation_game_strategy &&
    company.valuation_game_offering &&
    company.valuation_game_market &&
    company.valuation_game_hr &&
    company.valuation_game_innovation &&
    company.valuation_game_finance;
  return (
    <div className="flex flex-col px-8 py-8 w-[42rem] h-full justify-center bg-white">
      <div className="flex items-center mb-2">
        <div className="w-24 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-1 mr-2">&times;</div>
        <div className="w-32 mb-4">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-8">
        <h1 className="text-xl text-primary font-semibold">Indiquez les atouts de votre société par le biais de l'évaluation suivante :</h1>
        <div className="w-full grid grid-cols-2 gap-y-4">
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Stratégie</p>
            <div className="flex gap-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <button
                  key={value}
                  className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                  ${company.valuation_game_strategy === value ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_game_strategy: value })}
                >
                  <p className="text-sm text-center font-semibold">{value}</p>
                </button>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Offre</p>
            <div className="flex gap-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <button
                  key={value}
                  className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                  ${company.valuation_game_offering === value ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_game_offering: value })}
                >
                  <p className="text-sm text-center font-semibold">{value}</p>
                </button>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Marché</p>
            <div className="flex gap-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <button
                  key={value}
                  className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                  ${company.valuation_game_market === value ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_game_market: value })}
                >
                  <p className="text-sm text-center font-semibold">{value}</p>
                </button>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Humain</p>
            <div className="flex gap-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <button
                  key={value}
                  className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                  ${company.valuation_game_hr === value ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_game_hr: value })}
                >
                  <p className="text-sm text-center font-semibold">{value}</p>
                </button>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Innovation</p>
            <div className="flex gap-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <button
                  key={value}
                  className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                  ${company.valuation_game_innovation === value ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_game_innovation: value })}
                >
                  <p className="text-sm text-center font-semibold">{value}</p>
                </button>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Finance</p>
            <div className="flex gap-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <button
                  key={value}
                  className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                  ${company.valuation_game_finance === value ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_game_finance: value })}
                >
                  <p className="text-sm text-center font-semibold">{value}</p>
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <Link to="/valuation-game/start-up/values" className="primary-button px-8">
            Précédent
          </Link>

          {isDataValid() ? (
            <Link to="/valuation-game/start-up/personal-info" className="primary-button px-8">
              Suivant
            </Link>
          ) : (
            <button disabled className="primary-button px-8">
              Suivant
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Valuation;
