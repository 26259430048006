import { Navigate, Route, Routes } from "react-router-dom";

import Company from "./company";
import Values from "./values";
import Valuation from "./valuation";
import PersonalInfo from "./personal-info";

const StartUp = ({ company, setCompany }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"./company"} />} />
      <Route path="/company" element={<Company company={company} setCompany={setCompany} />} />
      <Route path="/values" element={<Values company={company} setCompany={setCompany} />} />
      <Route path="/valuation" element={<Valuation company={company} setCompany={setCompany} />} />
      <Route path="/personal-info" element={<PersonalInfo company={company} setCompany={setCompany} />} />
    </Routes>
  );
};

export default StartUp;
