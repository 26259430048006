import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const STARTING_YEAR = 2020;

const NwcTable = ({ values, setValues, computedValues }) => {
  const { t } = useTranslation();

  const KEYS = ["working_capital"];
  const KEYS_LABEL = {
    working_capital: t("business_plan.working_capital.title"),
  };

  const years = Array.from(Array(11).keys()).map((i) => STARTING_YEAR + i);

  const [data, setData] = useState([
    {
      id: 1,
      title: t("business_plan.working_capital.title"),
      y2019: "",
      y2020: "",
      y2021: "",
      y2022: "",
      y2023: "",
      y2024: "",
      y2025: "",
      y2026: "",
      y2027: "",
      y2028: "",
      y2029: "",
    },
    {
      id: 2,
      title: t("business_plan.net_working_capital.title"),
      y2019: "",
      y2020: "",
      y2021: "",
      y2022: "",
      y2023: "",
      y2024: "",
      y2025: "",
      y2026: "",
      y2027: "",
      y2028: "",
      y2029: "",
    },
  ]);

  const handleCellChange = (id, field, value) => {
    setData((prevData) => prevData.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
  };

  const getCellClassName = (index, year) => {
    const isGrayBackground = index == data.length - 1;

    const isYellowBackground = index < data.length - 1 && (year === "y2022" || year === "y2023" || year === "y2024" || year === "y2025" || year === "y2026");
    return `border p-2 ${isGrayBackground ? "bg-gray-100" : isYellowBackground ? "bg-green-200" : ""}`;
  };
  const parseToNumber = (value) => {
    if (value === "") return 0;
    if (value.includes(",") && !value.includes(".")) value = value.replace(",", ".");
    if (value.includes(" ")) value = value.replace(" ", "");
    return Number(value);
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full bg-white border rounded-lg border-gray-300 table-fixed">
        <thead>
          <tr className="">
            <th colSpan="1" className="border text-gray-700 font-normal text-sm"></th>
            <th colSpan="4" className="border p-2  text-left text-gray-700 font-normal text-sm">
              {t("business_plan.ebitda.past_balance_sheet")}
            </th>
            <th colSpan="6" className="border p-2 text-left text-gray-700 font-normal text-sm">
              {t("business_plan.ebitda.predicted_balance_sheet")}
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border p-2">{t("business_plan.ebitda.year")}</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border p-2 text-xs w-36">{KEYS_LABEL[key]}</td>
              {years.map((year, index) => (
                <td key={year} className={`border border-secondary text-xs px-2 ${1 < index && index < 5 ? "bg-orange-100" : ""} `}>
                  <input
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values[`${key}_${index}`] || 0}
                    onChange={(e) => setValues({ ...values, [`${key}_${index}`]: parseToNumber(e.target.value) })}
                  />
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td className="border p-2 text-xs w-56">{t("business_plan.net_working_capital.title")}</td>
            {years.map((year, index) => (
              <td key={year} className="bg-gray-100 text-xs px-2 text-right border">
                {computedValues.net_working_capital[index]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NwcTable;
