import { Route, Routes } from "react-router-dom";
import New from "./new";
import SME from "./sme";
import StartUp from "./startup";
import List from "./List";

const Valuation = () => {
  return (
    <div className="p-8">
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/new/*" element={<New />} />
        <Route path="/sme/:companyId/*" element={<SME />} />
        <Route path="/start-up/:id/*" element={<StartUp />} />
      </Routes>
    </div>
  );
};

export default Valuation;
