import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import CurrencyInput from "react-currency-input-field";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";

const InnovationRnD = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-finance-situation`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.enter_the_total_amount_spent_on_research_development")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.amount_spent_rnd}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, amount_spent_rnd: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.is_the_developed_solution_patentable")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="solution_patentable_yes"
              name="is_solution_patentable"
              onChange={() => setValues((prev) => ({ ...prev, is_solution_patentable: true }))}
              checked={values?.is_solution_patentable === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="solution_patentable_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="solution_patentable_no"
              name="is_solution_patentable"
              onChange={() => setValues((prev) => ({ ...prev, is_solution_patentable: false }))}
              checked={values?.is_solution_patentable === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="solution_patentable_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.how_many_tech_patents")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className="input"
          placeholder={t("placeholder_enter")}
          value={values?.patents_registered}
          onChange={(e) => setValues((prev) => ({ ...prev, patents_registered: e.target.value }))}
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.describe_tech_patents")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.patents_description || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, patents_description: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.any_RandD_partnership")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="any_RandD_partnership_yes"
              name="has_rnd_partnership"
              onChange={() => setValues((prev) => ({ ...prev, has_rnd_partnership: true }))}
              checked={values?.has_rnd_partnership === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="any_RandD_partnership_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="any_RandD_partnership_no"
              name="has_rnd_partnership"
              onChange={() => setValues((prev) => ({ ...prev, has_rnd_partnership: false }))}
              checked={values?.has_rnd_partnership === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="any_RandD_partnership_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.number_of_partnerships_signed_for_RandD")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className="input"
          placeholder={t("placeholder_enter")}
          value={values?.RnD_partnerships_count}
          onChange={(e) => setValues((prev) => ({ ...prev, RnD_partnerships_count: e.target.value }))}
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.name_of_these_partners_and_coll")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.partnerships_details || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, partnerships_details: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.personal_data")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="data_yes"
              name="is_collection_personal_data"
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data: true }))}
              checked={values?.is_collection_personal_data === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="data_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="data_no"
              name="is_collection_personal_data"
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data: false }))}
              checked={values?.is_collection_personal_data === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="data_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.personal_data_protection_procedures_set_up")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="set_up_yes"
              name="is_collection_personal_data_proctected"
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data_proctected: true }))}
              checked={values?.is_collection_personal_data_proctected === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="set_up_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="set_up_no"
              name="is_collection_personal_data_proctected"
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data_proctected: false }))}
              checked={values?.is_collection_personal_data_proctected === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="set_up_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.have_an_award")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="an_award_yes"
              name="tech_innovation_award"
              onChange={() => setValues((prev) => ({ ...prev, tech_innovation_award: true }))}
              checked={values?.tech_innovation_award === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="an_award_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="an_award_no"
              name="tech_innovation_award"
              onChange={() => setValues((prev) => ({ ...prev, tech_innovation_award: false }))}
              checked={values?.tech_innovation_award === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="an_award_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.specifiy_the_price")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.obtained_prizes || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, obtained_prizes: e.target.value }))}
        />
      </div>

      <div className="flex justify-between w-full">
        <Link to={`../form-innovation-state-of-the-art`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InnovationRnD;
