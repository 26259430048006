import { Link } from "react-router-dom";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";

const Type = ({ company, setCompany }) => {
  return (
    <div className="flex flex-col px-8 py-8 w-[42rem] h-full justify-center bg-white">
      <div className="flex items-center mb-2">
        <div className="w-24 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-1 mr-2">&times;</div>
        <div className="w-32 mb-4">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-8">
        <h1 className="text-xl text-primary font-semibold">Pour commencer, choisissez le type de votre entreprise</h1>
        <div className="flex space-x-2">
          <button
            className={`border rounded-lg py-2 w-36 cursor-pointer
                ${company.company_type === "START-UP" ? "bg-primary text-white" : "border-primary"}`}
            onClick={() => setCompany({ ...company, company_type: "START-UP" })}
          >
            <p className="text-sm text-center font-semibold">Start-up</p>
          </button>
          <button
            className={`border rounded-lg py-2 w-36 cursor-pointer
                ${company.company_type === "SME" ? "bg-primary text-white" : "border-primary"}`}
            onClick={() => setCompany({ ...company, company_type: "SME" })}
          >
            <p className="text-sm text-center font-semibold">TPE / PME</p>
          </button>
        </div>

        {!company.company_type && (
          <button disabled className="primary-button px-8">
            Suivant
          </button>
        )}

        {company.company_type === "START-UP" && (
          <Link to="/valuation-game/start-up" className="primary-button px-8">
            Suivant
          </Link>
        )}

        {company.company_type === "SME" && (
          <Link to="/valuation-game/sme" className="primary-button px-8">
            Suivant
          </Link>
        )}
      </div>
    </div>
  );
};

export default Type;
