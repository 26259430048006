import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentTextIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";

import { MdDeleteOutline } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin4Line } from "react-icons/ri";

import api from "@/services/api";
import ImageUploadSVG from "@/assets/svg/upload-image.svg";
import UploadFileSVG from "@/assets/svg/upload-file.svg";
import ConfirmModal from "@/components/ConfirmModal";
import Loader from "@/components/Loader";

import {
  INVESTOR_TYPES,
  INVESTOR_MATURITIES,
  INVESTOR_AMOUNT_RANGES,
  INVESTOR_BUSINESS_MODELS,
  INVESTOR_REGIONS,
  INVESTOR_SECTORS_PME,
  INVESTOR_SECTORS_STARTUP,
  BUYER_MATURITIES,
  BUYER_TYPES,
} from "../../constants/investor";
import MultiSelect from "./components/Multiselect";
import TeamModal from "./components/TeamModal";
import Modal from "@/components/Modal";

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [investor, setInvestor] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setInvestor(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      if (investor.annonce_status === "ACCEPTED") {
        const res = await api.put(`/investor/${id}`, { ...investor, annonce_status: "PENDING" });
        if (res.ok) {
          toast.success("Votre annonce a bien été modifiée");
        } else {
          toast.error("Une erreur est survenue");
        }
      } else {
        const res = await api.put(`/investor/${id}`, investor);
        if (res.ok) {
          toast.success("Votre annonce a bien été modifiée");
        } else {
          toast.error("Une erreur est survenue");
        }
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  const handleDelete = async () => {
    try {
      const res = await api.delete(`/investor/${id}`);
      if (res.ok) {
        toast.success("Votre annonce a bien été supprimée");
        navigate("/my-annonce");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  if (!investor)
    return (
      <div className="flex justify-center items-center">
        <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
        <p className="text-primary">Chargement...</p>
      </div>
    );

  return (
    <div className="p-8">
      <ConfirmModal
        show={showDeleteModal}
        title="Supprimer votre annonce"
        description="Êtes-vous sûr de vouloir supprimer votre annonce ?"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmClassname="red-button"
      />
      <header className="w-full flex items-center justify-between">
        <div className="space-y-2">
          <h1 className="text-3xl font-semibold text-black mb-2">{investor.annonce_type === "buyer" ? investor.buyer_title : investor.structure_name}</h1>
          <p className="text-base text-gray-500">Modifier votre annonce</p>
        </div>
        <button type="button" className="primary-button" onClick={handleSave}>
          Enregistrer
        </button>
      </header>

      <div className="grid grid-cols-2 gap-6 my-10">
        <div className="space-y-6">
          <GeneralInformation values={investor} setValues={setInvestor} />
          <ComplementaryInfos values={investor} setValues={setInvestor} />
          <Text values={investor} setValues={setInvestor} />
          <Team values={investor} setValues={setInvestor} />
        </div>
        <div className="col-start-2 col-end-3 space-y-6">
          <Logo values={investor} setValues={setInvestor} />
          <Criteria values={investor} setValues={setInvestor} />
        </div>
        <Documents investor={investor} />
      </div>

      <div className="flex items-center justify-between gap-4">
        <button className="transparent-button w-48" onClick={() => navigate(-1)}>
          Retour
        </button>

        <button type="button" className="red-button w-48" onClick={() => setShowDeleteModal(true)}>
          Supprimer
        </button>
      </div>
    </div>
  );
};

const Logo = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-6">
      <h1 className="text-xl font-medium text-primary">Logo</h1>
      <div className="flex items-center justify-center self-center w-[30%]">
        <ImageInput name="logo" value={values.logo} onChange={(e) => setValues({ ...values, logo: e.target.value })} />
      </div>
    </div>
  );
};

const GeneralInformation = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-6">
      <h1 className="text-xl font-medium text-primary">Informations générales</h1>
      {values.annonce_type === "buyer" ? (
        <>
          <div className="flex flex-col gap-3">
            <p className="text-primary text-sm font-medium">Titre de l'annonce</p>
            <input className="input flex-1 py-2" value={values.buyer_title} onChange={(e) => setValues((prev) => ({ ...prev, buyer_title: e.target.value }))} />
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-primary text-sm font-medium">Type de repreneur</p>
            <MultiSelect options={BUYER_TYPES} values={values.buyer_types} onSelect={(value) => setValues((prev) => ({ ...prev, buyer_types: value }))} />
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-primary text-sm font-medium">Budget de reprise</p>
            <div className="flex items-center gap-3">
              <p className="text-primary text-xs font-medium">Min</p>
              <input
                type="number"
                name="acquisition_min"
                placeholder="Montant minimum"
                value={values.buyer_budget_min}
                onChange={(e) => setValues({ ...values, buyer_budget_min: e.target.value })}
                className="input"
              />
            </div>
            <div className="flex items-center gap-3">
              <p className="text-primary text-xs font-medium">Max</p>

              <input
                type="number"
                name="acquisition_min"
                placeholder="Montant maximum"
                value={values.buyer_budget_max}
                onChange={(e) => setValues({ ...values, buyer_budget_max: e.target.value })}
                className="input"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-3">
            <p className="text-primary text-sm font-medium">Nom de la structure</p>
            <input className="input flex-1 py-2" value={values.structure_name} onChange={(e) => setValues((prev) => ({ ...prev, company_name: e.target.value }))} />
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-primary text-sm font-medium">Type d'investisseur</p>
            <MultiSelect options={INVESTOR_TYPES} values={values.types} onSelect={(value) => setValues((prev) => ({ ...prev, types: value }))} />
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-primary text-sm font-medium">Hauteur des investissements</p>
            <MultiSelect options={INVESTOR_AMOUNT_RANGES} values={values.amount_ranges} onSelect={(value) => setValues((prev) => ({ ...prev, amount_ranges: value }))} />
          </div>
        </>
      )}
    </div>
  );
};

const Criteria = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-6">
      <h1 className="text-xl font-medium text-primary">Critères</h1>
      <div className="flex flex-col gap-3">
        <p className="text-primary text-sm font-medium">Zones géographiques</p>
        <MultiSelect options={INVESTOR_REGIONS} values={values.regions} onSelect={(value) => setValues((prev) => ({ ...prev, regions: value }))} />
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-primary text-sm font-medium">Maturité des projets</p>
        {values.annonce_type === "buyer" ? (
          <MultiSelect options={BUYER_MATURITIES} values={values.buyer_maturities} onSelect={(value) => setValues((prev) => ({ ...prev, buyer_maturities: value }))} />
        ) : (
          <MultiSelect options={INVESTOR_MATURITIES} values={values.maturities} onSelect={(value) => setValues((prev) => ({ ...prev, maturities: value }))} />
        )}
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-primary text-sm font-medium">Modèles économiques</p>
        <MultiSelect options={INVESTOR_BUSINESS_MODELS} values={values.businesses} onSelect={(value) => setValues((prev) => ({ ...prev, businesses: value }))} />
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-primary text-sm font-medium">Secteurs d'activité</p>
        {values.annonce_type === "buyer" ? (
          <MultiSelect options={INVESTOR_SECTORS_PME} values={values.buyer_sectors} onSelect={(value) => setValues((prev) => ({ ...prev, buyer_sectors: value }))} />
        ) : (
          <MultiSelect options={INVESTOR_SECTORS_STARTUP} values={values.sectors} onSelect={(value) => setValues((prev) => ({ ...prev, sectors: value }))} />
        )}
      </div>
    </div>
  );
};

const ComplementaryInfos = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-4">
      <h1 className="text-xl font-medium text-primary">Informations complémentaires</h1>

      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Pays</p>
        <input className="input flex-1 py-2" value={values.country} onChange={(e) => setValues((prev) => ({ ...prev, country: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Code postal</p>
        <input className="input flex-1 py-2" value={values.postal_code} onChange={(e) => setValues((prev) => ({ ...prev, postal_code: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Ville</p>
        <input className="input flex-1 py-2" value={values.city} onChange={(e) => setValues((prev) => ({ ...prev, city: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Adresse</p>
        <input className="input flex-1 py-2" value={values.address} onChange={(e) => setValues((prev) => ({ ...prev, address: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Site web</p>
        <input className="input flex-1 py-2" value={values.website} onChange={(e) => setValues((prev) => ({ ...prev, website: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Linkedin</p>
        <input className="input flex-1 py-2" value={values.linkedin} onChange={(e) => setValues((prev) => ({ ...prev, linkedin: e.target.value }))} />
      </div>
    </div>
  );
};

const Text = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-6">
      <h1 className="text-xl font-medium text-primary">Texte</h1>
      <div className="flex flex-col gap-3">
        <p className="text-primary text-sm font-medium">Description</p>
        <textarea
          rows={8}
          type="text"
          name="description"
          value={values.description || ""}
          onChange={(e) => setValues({ ...values, description: e.target.value })}
          className="input"
        />
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-primary text-sm font-medium">Détails</p>
        <textarea rows={8} type="text" name="details" value={values.details || ""} onChange={(e) => setValues({ ...values, details: e.target.value })} className="input" />{" "}
      </div>
    </div>
  );
};

const Team = ({ values, setValues }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState();

  const handleEdit = async (v) => {
    setValues((prev) => ({
      ...prev,
      team: prev.team.map((member, i) => (i === elementIndex ? v : member)),
    }));
    setIsOpen(true);
  };

  const handleDelete = async (l, index) => {
    setValues((prev) => ({
      ...prev,
      team: prev.team.filter((_, i) => i !== index),
    }));
  };

  const handleAdd = async () => {
    const newMember = {};
    setValues((prev) => ({
      ...prev,
      team: [...prev.team, newMember],
    }));
    setElementIndex(values.team.length);
    setIsOpen(true);
  };

  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-6">
      <h1 className="text-xl font-medium text-primary">Votre équipe</h1>
      <div className="flex flex-col gap-2">
        {values.team?.length > 0 && (
          <>
            <Modal
              show={isOpen}
              onClose={() => {
                setIsOpen(false);
                setElementIndex(null);
              }}
            >
              <TeamModal setOpen={setIsOpen} team={values.team[elementIndex]} setTeam={(v) => handleEdit(v)} />
            </Modal>
            {values?.team?.map((member, index) => (
              <div
                key={index}
                className="appearance-none py-1  focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center"
              >
                <p>{member?.name}</p>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setElementIndex(index);
                      setIsOpen(true);
                    }}
                  >
                    <MdEdit className="w-10 p-2 h-10 border rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-50" />
                  </button>
                  <button onClick={() => handleDelete(member, index)}>
                    <RiDeleteBin4Line className="w-10 p-2 h-10 border rounded-md flex justify-center items-center  cursor-pointer hover:bg-gray-50" />
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        <button className="w-full bg-gray-200 hover:bg-gray-300 py-2 flex mt-5 justify-center items-center gap-3 rounded" onClick={() => handleAdd()}>
          Ajouter
          <FaPlus />
        </button>
      </div>
    </div>
  );
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}
      >
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

const Documents = ({ investor }) => {
  if (!investor || !investor._id) return null;

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { company_id: investor._id });
        if (res.ok) setDocuments(res.data);
        else {
          toast.error("Une erreur est survenue");
        }
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [investor._id]);

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      const { data } = await api.postFormData(`/document?company_id=${investor._id}`, formData);
      setDocuments(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <section className="w-full border border-secondary rounded-lg p-8 col-span-2">
      <div className="w-full mt-4">
        {documents.length > 0 && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="flex-1 text-sm font-semibold">Documents</h3>
              <h3 className="w-1/4 text-sm font-semibold">Date d'ajout</h3>
              <h3 className="w-20 text-sm font-semibold">Voir</h3>
              <h3 className="w-20" />
            </div>
            <div className="divide-y divide-secondary">
              {documents.map((item, index) => (
                <DocRow key={index} item={item} index={index} setDocuments={setDocuments} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="flex justify-center w-full">
        <label
          htmlFor="documents"
          className="bg-white mt-4 p-4 rounded-xl w-full h-full hover:bg-gray-50 transition-all cursor-pointer flex flex-col items-center justify-center border border-secondary border-dashed"
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
              <p className="text-primary">Chargement...</p>
            </div>
          ) : (
            <img src={UploadFileSVG} alt="upload" className="w-8 h-8" />
          )}
          <p className="mt-4">Cliquez pour déposez un fichier</p>
        </label>
        <input id="documents" type="file" className="hidden" disabled={loading} multiple onChange={handleFileUpload} />
      </div>
    </section>
  );
};

const DocRow = ({ item, index, setDocuments }) => {
  const [file_name, setFileName] = useState(item.file_name);

  useEffect(() => {
    setFileName(item.file_name);
  }, [item]);

  useEffect(() => {
    if (file_name === item.file_name) return;

    const timeout = setTimeout(async () => {
      const res = await api.put(`/document/${item._id}`, { file_name });
      if (res.ok) {
        setDocuments((prev) => {
          const newValue = [...prev];
          newValue[index].file_name = file_name;
          return newValue;
        });
        toast.success("Nom du fichier mis à jour");
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [file_name]);

  const handleDeleteFile = async () => {
    const confirm = window.confirm("Voulez-vous vraiment supprimer ce fichier ?");
    if (!confirm) return;
    try {
      const res = await api.delete(`/document/${item._id}`);
      if (res.ok) setDocuments((prev) => prev.filter((doc) => doc._id !== item._id));
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div key={index} className="flex justify-between items-center py-2">
      <div className="flex-1 items-center flex">
        <DocumentTextIcon className="w-6 h-6 text-primary mr-4" />
        <input type="text" value={file_name} className="input" onChange={(e) => setFileName(e.target.value)} />
      </div>
      <div className="w-1/4">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
      <div className="w-20 flex justify-center">
        <a href={item.file_url} target="_blank" rel="noreferrer">
          <EyeIcon className="w-6 h-6 text-primary" />
        </a>
      </div>
      <div className="w-20 flex justify-center">
        <button type="button" className="transparent-button" onClick={handleDeleteFile}>
          <TrashIcon className="w-6 h-6 text-primary cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default Edit;
