import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin4Line } from "react-icons/ri";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal";
import IncubatorModal from "./components/IncubatorModal";

const StrategyPartnership = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState({});

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-strategy-ip`);
  };
  const handleEdit = async (v) => {
    setValues((prev) => ({
      ...prev,
      incubators: prev.incubators.map((incubator, i) => (i === elementIndex ? v : incubator)),
    }));
    setIsOpen(true);
  };

  const handleDelete = async (l, index) => {
    setValues((prev) => ({
      ...prev,
      incubators: prev.incubators.filter((_, i) => i !== index),
    }));
  };

  const handleAdd = async () => {
    const newIncubator = { leader: "" };
    setValues((prev) => ({
      ...prev,
      incubators: [...prev.incubators, newIncubator],
    }));
    setElementIndex(values.incubators.length);
    setIsOpen(true);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.incubated_in_a_support_structure")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="structure_yes"
              name="support_structure"
              onChange={() => setValues((prev) => ({ ...prev, support_structure: true }))}
              checked={values?.support_structure === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="structure_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="structure_no"
              name="support_structure"
              onChange={() => setValues((prev) => ({ ...prev, support_structure: false }))}
              checked={values?.support_structure === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="structure_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <p className="text-lg mb-5 font-semibold">{t("lable.list_incubators")}</p>
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.structure_name")}</label>

        <div className="flex flex-col gap-2">
          {values?.incubators.length !== 0 &&
            values?.incubators?.map((incu, index) => (
              <div
                key={index}
                className="appearance-none py-1  focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center"
              >
                <p>{incu?.structure_name}</p>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setElementIndex(index);
                      setIsOpen(true);
                    }}
                  >
                    <MdEdit className="w-10 p-2 h-10 border rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-50" />
                  </button>
                  <button onClick={() => handleDelete(incu, index)}>
                    <RiDeleteBin4Line className="w-10 p-2 h-10 border rounded-md flex justify-center items-center  cursor-pointer hover:bg-gray-50" />
                  </button>
                </div>
              </div>
            ))}

          <button className="w-full bg-gray-200 hover:bg-gray-300 py-2 flex mt-5 justify-center items-center gap-3 rounded" onClick={() => handleAdd()}>
            Ajouter
            <FaPlus />
          </button>

          <Modal
            show={isOpen}
            onClose={() => {
              setIsOpen(false);
              setElementIndex(null);
            }}
          >
            <IncubatorModal setOpen={setIsOpen} incubator={values?.incubators[elementIndex]} setIncubator={(v) => handleEdit(v)} questions={questions} />
          </Modal>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.have_you_developed_partnerships_with_other_entities")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="developed_partnerships_yes"
              name="has_partnership"
              onChange={() => setValues((prev) => ({ ...prev, has_partnership: true }))}
              checked={values?.has_partnership === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="developed_partnerships_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="developed_partnerships_no"
              name="has_partnership"
              onChange={() => setValues((prev) => ({ ...prev, has_partnership: false }))}
              checked={values?.has_partnership === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="developed_partnerships_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      {values.has_partnership && (
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.name_of_these_partners")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <textarea
            className="input min-h-32"
            placeholder={t("placeholder_enter")}
            value={values?.partners || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, partners: e.target.value }))}
          />
        </div>
      )}

      <div className="flex justify-between w-full">
        <Link to={`../form-market-competitors`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StrategyPartnership;
