import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import Boolean from "../components/Boolean";
import MultiSelect from "../components/MultiSelect";
import SingleSelect from "../components/SingleSelect";

const OfferMonetization = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [questions, setQuestions] = useState();
  const [values, setValues] = useState();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const res = await api.post("/question/search", { limit: 300, startup: true });
        if (res.ok) {
          setQuestions(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchQuestions();

    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          if (!res.data.plan) res.data.plan = "certified";
          setValues(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-market-position`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <Boolean question={questions?.find((q) => q.key === "recurring_revenue")} values={values} setValues={setValues} />
      {values.recurring_revenue && <Boolean question={questions?.find((q) => q.key === "is_mrr_major_income")} values={values} setValues={setValues} />}

      {values.recurring_revenue && (
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.indicate_the_amount_of_monthly_revenue")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input className="input" placeholder={t("placeholder_enter")} value={values?.mrr} onChange={(e) => setValues((prev) => ({ ...prev, mrr: e.target.value }))} />
        </div>
      )}

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.indicate_current_churn")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex items-center gap-2">
          <input
            type="number"
            className="input"
            placeholder={t("placeholder_enter")}
            value={values?.current_churn_rate}
            onChange={(e) => setValues((prev) => ({ ...prev, current_churn_rate: e.target.value }))}
          />
          <span>%</span>
        </div>
      </div>

      <Boolean question={questions?.find((q) => q.key === "clv_knowledge")} values={values} setValues={setValues} />

      {values.clv_knowledge && (
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.what_is_ur_CLV")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input className="input" placeholder={t("placeholder_enter")} value={values?.clv} onChange={(e) => setValues((prev) => ({ ...prev, clv: e.target.value }))} />
        </div>
      )}

      <Boolean question={questions?.find((q) => q.key === "cac_knowledge")} values={values} setValues={setValues} />

      {values.cac_knowledge && (
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.what_is_ur_CAC")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input className="input" placeholder={t("placeholder_enter")} value={values?.cac} onChange={(e) => setValues((prev) => ({ ...prev, cac: e.target.value }))} />
        </div>
      )}

      <Boolean question={questions?.find((q) => q.key === "nps_knowledge")} values={values} setValues={setValues} />

      {values.nps_knowledge && (
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.what_is_ur_NPS")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input className="input" placeholder={t("placeholder_enter")} value={values?.nps} onChange={(e) => setValues((prev) => ({ ...prev, nps: e.target.value }))} />
        </div>
      )}

      <MultiSelect question={questions?.find((q) => q.key === "revenue_sources")} values={values} setValues={setValues} />

      <SingleSelect question={questions?.find((q) => q.key === "scalability_assessment")} values={values} setValues={setValues} />

      <div className="flex justify-between w-full pb-10">
        <Link to={`../form-offer-launch`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferMonetization;
