import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

import toast from "react-hot-toast";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";

import api from "@/services/api";

import Loader from "@/components/Loader";

const PersonalInfo = ({ company, setCompany }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const isDataValid = () => company.first_name && company.last_name && company.user_email && company.user_phone;

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { ok, data } = await api.post("/valuation-game", company);
      if (!ok) return toast.error("Une erreur s'est produite lors de l'envoi de vos informations");
      navigate(`/valuation-game/success?company_id=${data._id}`);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite lors de l'envoi de vos informations");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col px-8 py-8 w-[42rem] h-full justify-center bg-white">
      <div className="flex items-center mb-2">
        <div className="w-24 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-1 mr-2">&times;</div>
        <div className="w-32 mb-4">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-8">
        <h1 className="text-xl text-primary font-semibold">Informations personnelles</h1>
        <div className="grid grid-cols-2 gap-4 w-full ">
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Prénom</p>
            <input
              type="text"
              name="first_name"
              placeholder="Entrez votre prénom"
              value={company.first_name || ""}
              onChange={(e) => setCompany({ ...company, first_name: e.target.value })}
              className="input shadow"
            />
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Nom de famille</p>
            <input
              type="text"
              name="last_name"
              placeholder="Entrez votre nom de famille"
              value={company.last_name || ""}
              onChange={(e) => setCompany({ ...company, last_name: e.target.value })}
              className="input shadow"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 w-full ">
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Email</p>
            <input
              type="text"
              name="email"
              placeholder="Entrez votre email"
              value={company.user_email || ""}
              onChange={(e) => setCompany({ ...company, user_email: e.target.value })}
              className="input shadow"
            />
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Numéro de téléphone</p>
            <input
              type="text"
              name="user_phone"
              placeholder="Entrez votre numéro de téléphone"
              value={company.user_phone || ""}
              onChange={(e) => setCompany({ ...company, user_phone: e.target.value })}
              className="input shadow"
            />
          </div>
        </div>
        <div className="flex gap-4">
          <Link to="/valuation-game/start-up/valuation" className="primary-button px-8">
            Précédent
          </Link>

          {isDataValid() ? (
            <button onClick={handleSubmit} className="primary-button px-8">
              {loading ? <Loader size="small" /> : "Envoyer"}
            </button>
          ) : (
            <button disabled className="primary-button px-8">
              Envoyer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
