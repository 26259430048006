import React from "react";
import { IoCheckbox } from "react-icons/io5";

const SingleSelectBis = ({ question, value, onChange }) => {
  if (!question) return null;

  return (
    <div>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {question.question_fr}
        <span className="ml-2 text-red-500">*</span>
      </label>
      <div className="flex flex-col gap-3">
        {question.options.length > 0 &&
          question.options.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer ${
                value === option.option_fr ? "border-2 border-primary text-primary" : "border border-gray-300 text-gray-500"
              }`}
            >
              <input type="radio" className="hidden" name="customRadio" value={value} onChange={() => onChange(option.option_fr)} />
              {value === option.option_fr ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4"></div>}
              {option.option_fr}
            </label>
          ))}
      </div>
    </div>
  );
};

export default SingleSelectBis;
