import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

import api from "@/services/api";

import Loader from "@/components/Loader";

import { RiExchangeDollarLine, RiFundsLine, RiHandCoinLine } from "react-icons/ri";
import { IoMegaphone, IoWalletOutline } from "react-icons/io5";
import Pagination from "@/components/Pagination";

const PICTURES = [
  "https://th.bing.com/th/id/R.891a1be588a0303ba8b24c1570a01bae?rik=h932gdTVK1psTg&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/OIP.O-kSeGcdCsgSYSKEsqHVdwHaE7?w=1280&h=853&rs=1&pid=ImgDetMain",
  "https://votrepolice.ch/wp-content/uploads/2020/05/entreprises-hd.png",
  "https://www.megahubhk.com/wp-content/uploads/2015/07/Final-Company-Background-Image.jpg",
  "https://www.digitalentrepreneur.fr/wp-content/uploads/2020/05/blog-entrepreneur.jpg",
  "https://www.novalum.fr/wp-content/uploads/2020/09/entreprise-corporate-1.jpg",
  "https://alliance-sciences-societe.fr/wp-content/uploads/2019/10/lorganisation-entreprise-optimiser-activites-comment.jpg",
  "https://th.bing.com/th/id/R.9f33af7b385e6dbca0ae3107c6762dd1?rik=A0g2SBfPXtv8Ag&riu=http%3a%2f%2fwww.hsm-services.fr%2fwp-content%2fuploads%2f2019%2f08%2fmanagement-indispensable-entreprise.jpg&ehk=4BneOG2CFGqM1UBIQ1ofPd1QxEx%2btadmjH3dqrajzoc%3d&risl=&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/R.9cb56bba1d502461114b7a1fc591a639?rik=lBuClpiOga3VVw&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/OIP.KJJcNR_SJt3Gw3YkSZUj3AHaE8?rs=1&pid=ImgDetMain",
  "https://th.bing.com/th/id/R.6d3fce6fb0c0898a96ce086887b0e194?rik=SUTaab9Pccyr3Q&pid=ImgRaw&r=0",
  "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/V1xq1AADx/videoblocks-group-of-business-people-meeting-around-table-20s-4k_sncaqskgw_thumbnail-1080_01.png",
  "https://www.singaporetranscription.com/wp-content/uploads/2017/01/meeting.jpg",
  "https://c.stocksy.com/a/5ka300/z9/856225.jpg",
  "https://fellow.app/wp-content/uploads/2021/07/2-1.jpg",
  "https://wallpapercave.com/wp/wp8656243.png",
  "https://wallpapercave.com/wp/wp8656441.jpg",
  "https://www.jcount.com/wp-content/uploads/2015/12/business-meeting-to-be-productive.jpg",
  "https://fellow.app/wp-content/uploads/2021/07/2-14.jpg",
  "https://1000decos.fr/wp-content/uploads/2021/03/paysagiste2.jpg",
  "https://th.bing.com/th/id/OIP.bmQ1Z83MU9PQTkvL2JAbgAHaE8?rs=1&pid=ImgDetMain",
];

const ANNONCE_TYPE = {
  cession: <p className="text-primary font-medium border border-primary p-1 rounded shadow-sm shadow-gray-300 text-center">Cession</p>,
  levee: <p className="text-yellow-600 font-medium border border-yellow-600 p-1 rounded shadow-sm shadow-yellow-300 text-center">Levée de fonds</p>,
  buyer: <p className="text-blue-600 font-medium border border-blue-600 p-1 rounded shadow-sm shadow-blue-300 text-center">Reprise</p>,
  investor: <p className="text-green-600 font-medium border border-green-600 p-1 rounded shadow-sm shadow-green-300 text-center">Investissement</p>,
};

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({
    annonce: true,
    annonce_status: "ACCEPTED",
    annonce_type: searchParams.get("type") || "",
    search: searchParams.get("search") || "",
    limit: 15,
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchData();
  }, [query, page]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const skip = (page - 1) * query.limit;

      const resCompanies = await api.post(`/company/search`, { ...query, skip });
      const resInevstors = await api.post(`/investor/search`, { ...query, skip });

      if (!resCompanies.ok || !resInevstors.ok) return toast.error("Une erreur est survenue");

      const mergedData = [...resCompanies.data, ...resInevstors.data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setTotal(Math.max(resCompanies.total, resInevstors.total));
      setData(mergedData);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="p-8 space-y-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Annonces</h1>
        <p className="text-base text-gray-500">Explorez des opportunités</p>
        <div className="space-y-2 flex gap-2 lg:block">
          <div className="hidden lg:flex items-center flex-wrap gap-2 py-2 ml-auto">
            <div
              className={
                "flex items-center space-x-1 cursor-pointer p-2 border-2 rounded-md text-sm font-medium" +
                (query.annonce_type === ""
                  ? " text-blue-800 border-blue-800 bg-blue-50"
                  : " text-gray-500 border-gray-200 hover:bg-blue-50 hover:border-blue-300 transition-all duration-100")
              }
              onClick={() => {
                setQuery((prev) => ({ ...prev, annonce_type: "" }));
                setPage(1);
              }}
            >
              <IoMegaphone className="h-5 w-5" />
              <div>Tous les annonces</div>
            </div>
            <div
              className={
                "flex items-center space-x-1 cursor-pointer p-2 border-2 rounded-md text-sm font-medium" +
                (query.annonce_type === "levee"
                  ? " text-blue-800 border-blue-800 bg-blue-50"
                  : " text-gray-500 border-gray-200 hover:bg-blue-50 hover:border-blue-300 transition-all duration-100")
              }
              onClick={() => {
                setQuery((prev) => ({ ...prev, annonce_type: "levee" }));
                setPage(1);
              }}
            >
              <RiFundsLine className="h-5 w-5" />
              <div>Levées de fonds</div>
            </div>
            <div
              className={
                "flex items-center space-x-1 cursor-pointer p-2 border-2 rounded-md text-sm font-medium" +
                (query.annonce_type === "cession"
                  ? " text-blue-800 border-blue-800 bg-blue-50"
                  : " text-gray-500 border-gray-200 hover:bg-blue-50 hover:border-blue-300 transition-all duration-100")
              }
              onClick={() => {
                setQuery((prev) => ({ ...prev, annonce_type: "cession" }));
                setPage(1);
              }}
            >
              <RiExchangeDollarLine className="h-5 w-5" />
              <div>Cessions</div>
            </div>
            <div
              className={
                "flex items-center space-x-1 cursor-pointer p-2 border-2 rounded-md text-sm font-medium" +
                (query.annonce_type === "buyer"
                  ? " text-blue-800 border-blue-800 bg-blue-50"
                  : " text-gray-500 border-gray-200 hover:bg-blue-50 hover:border-blue-300 transition-all duration-100")
              }
              onClick={() => {
                setQuery((prev) => ({ ...prev, annonce_type: "buyer" }));
                setPage(1);
              }}
            >
              <RiHandCoinLine className="h-5 w-5" />
              <div>Reprise</div>
            </div>
            <div
              className={
                "flex items-center space-x-1 cursor-pointer p-2 border-2 rounded-md text-sm font-medium" +
                (query.annonce_type === "investor"
                  ? " text-blue-800 border-blue-800 bg-blue-50"
                  : " text-gray-500 border-gray-200 hover:bg-blue-50 hover:border-blue-300 transition-all duration-100")
              }
              onClick={() => {
                setQuery((prev) => ({ ...prev, annonce_type: "investor" }));
                setPage(1);
              }}
            >
              <IoWalletOutline className="h-5 w-5" />
              <div>Investissement</div>
            </div>
          </div>
          <div className="lg:hidden">
            <select
              className="select w-60 cursor-pointer text-black opacity-60 focus:outline-none border-2 border-gray-400"
              value={query.annonce_type}
              onChange={(event) => {
                setPage(1);
                setQuery((prev) => ({ ...prev, annonce_type: event.target.value }));
              }}
            >
              <option value="">Tous les annonces</option>
              {Object.keys(ANNONCE_TYPE).map((key) => (
                <option key={key} value={key}>
                  {ANNONCE_TYPE[key]}
                </option>
              ))}
            </select>
          </div>
          <input
            type="text"
            placeholder="Rechercher une annonce..."
            className="mt-1 block w-full px-3 py-2 border border-secondary bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            value={query.search}
            onChange={(event) => setQuery({ ...query, search: event.target.value })}
          />
        </div>
      </header>

      <Pagination page={page} setPage={setPage} last={Math.ceil(total / query.limit)} />
      {loading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 gap-4 mt-10">
            {data.length > 0 ? data.map((e, index) => <Card key={index} data={e} />) : <p className="text-center mt-10">Aucune annonce trouvée</p>}
          </div>
          <Pagination page={page} setPage={setPage} last={Math.ceil(total / query.limit)} />
        </>
      )}
    </section>
  );
};

const Card = ({ data }) => {
  const randomIndex = Math.floor(Math.random() * PICTURES.length);
  const randomPictureSrc = PICTURES[randomIndex];
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(`/annonce/${data._id}?interested=true`);
  };

  if (data.annonce_type === "buyer") {
    return (
      <Link
        to={`/buyers/${data._id}`}
        className="flex flex-col gap-4 justify-start shadow-sm border-2 border-gray-300 rounded-md p-6 hover:border-primary transition duration-300 ease-in-out"
      >
        <div className="flex flex-col justify-start space-y-4">
          <div className="flex items-center space-x-2 mb-2">{ANNONCE_TYPE[data.annonce_type]}</div>
          {data.logo ? (
            <img src={data.logo} alt={`${data.buyer_title}'s profile`} className="w-full h-48 object-contain rounded-lg" />
          ) : (
            <img src={randomPictureSrc} alt="default" className="w-full h-48 object-contain rounded-lg" />
          )}
          <h1 className="text-xl font-semibold text-gray-900 mb-4">{data.buyer_title}</h1>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-wrap items-start gap-1 border-b-2 pb-2">
              <p className="text-sm font-medium text-gray-700">Type:</p>
              {data.buyer_types.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-yellow-200 text-yellow-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.buyer_types.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-yellow-200 text-yellow-800 rounded-lg">+{data.types.length - 2}</span>}
            </div>

            <div className="flex flex-wrap items-start gap-1 border-b-2 pb-2">
              <p className="text-sm font-medium text-gray-700">Maturité:</p>
              {data.buyer_maturities.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-blue-200 text-blue-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.buyer_maturities.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-blue-200 text-blue-800 rounded-lg">+{data.maturities.length - 2}</span>}
            </div>

            <div className="flex flex-wrap items-start gap-1">
              <p className="text-sm font-medium text-gray-700">Régions:</p>
              {data.regions.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-green-200 text-green-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.regions.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-green-200 text-green-800 rounded-lg">+{data.regions.length - 2}</span>}
            </div>

            <div className="flex flex-wrap items-start gap-1">
              <p className="text-sm font-medium text-gray-700">Secteurs:</p>
              {data.buyer_sectors.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-purple-200 text-purple-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.buyer_sectors.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-purple-200 text-purple-800 rounded-lg">+{data.businesses.length - 2}</span>}
            </div>
          </div>
        </div>
      </Link>
    );
  }

  if (data.annonce_type === "investor") {
    return (
      <Link
        to={`/investisseurs/${data._id}`}
        className="flex flex-col gap-4 justify-start shadow-sm border-2 border-gray-300 rounded-md p-6 hover:border-primary transition duration-300 ease-in-out"
      >
        <div className="flex flex-col justify-start space-y-4">
          <div className="flex items-center space-x-2 mb-2">{ANNONCE_TYPE[data.annonce_type]}</div>
          {data.logo ? (
            <img src={data.logo} alt={`${data.structure_name}'s profile`} className="w-full h-48 object-contain rounded-lg" />
          ) : (
            <img src={randomPictureSrc} alt="default" className="w-full h-48 object-contain rounded-lg" />
          )}
          <h1 className="text-xl font-semibold text-gray-900 mb-4">{data.structure_name}</h1>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-wrap items-start gap-1 border-b-2 pb-2">
              <p className="text-sm font-medium text-gray-700">Type:</p>
              {data.types.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-yellow-200 text-yellow-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.types.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-yellow-200 text-yellow-800 rounded-lg">+{data.types.length - 2}</span>}
            </div>

            <div className="flex flex-wrap items-start gap-1 border-b-2 pb-2">
              <p className="text-sm font-medium text-gray-700">Maturité:</p>
              {data.maturities.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-blue-200 text-blue-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.maturities.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-blue-200 text-blue-800 rounded-lg">+{data.maturities.length - 2}</span>}
            </div>

            <div className="flex flex-wrap items-start gap-1">
              <p className="text-sm font-medium text-gray-700">Régions:</p>
              {data.regions.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-green-200 text-green-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.regions.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-green-200 text-green-800 rounded-lg">+{data.regions.length - 2}</span>}
            </div>

            <div className="flex flex-wrap items-start gap-1">
              <p className="text-sm font-medium text-gray-700">Business:</p>
              {data.businesses.slice(0, 2).map((item, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold bg-purple-200 text-purple-800 rounded-lg">
                  {item}
                </span>
              ))}
              {data.businesses.length > 2 && <span className="px-2 py-1 text-xs font-semibold bg-purple-200 text-purple-800 rounded-lg">+{data.businesses.length - 2}</span>}
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <Link
      to={`/annonce/${data._id}`}
      className="flex flex-col gap-4 justify-start shadow-sm border-2 border-gray-300 rounded-md p-6 hover:border-primary transition duration-300 ease-in-out"
    >
      <div className="flex flex-col justify-start space-y-4">
        <div className="flex items-center space-x-2 mb-2">{ANNONCE_TYPE[data.annonce_type]}</div>
        <img
          alt={data.annonce_title}
          src={data.annonce_image ? data.annonce_image : randomPictureSrc}
          className="h-48 w-auto max-w-full rounded-lg shadow object-contain self-center"
        />
        <h1 className="text-xl font-semibold text-gray-900 mb-2 h-14 overflow-hidden text-ellipsis whitespace-normal line-clamp-2">{data.annonce_title}</h1>
        {(data.annonce_financing_needs || data.annonce_selling_price) !== undefined && (
          <p className="text-sm text-gray-500">
            {/* Montant souhaité: <span>{(data.annonce_financing_needs || data.annonce_selling_price).toLocaleString("fr", { currency: "EUR", style: "currency" })}</span> */}
          </p>
        )}
      </div>

      {data.annonce_type === "cession" && (
        <button
          onClick={handleClick}
          className="flex justify-center w-full text-sm font-semibold text-primary border border-primary rounded py-2 hover:bg-primary hover:text-white transition duration-300"
        >
          Demander une mise en relation
        </button>
      )}
    </Link>
  );
};

export default List;
