import { CALENDLY_COMMERCIAL } from "@/services/config";
import { useTranslation } from "react-i18next";
import { useSearchParams, Link } from "react-router-dom";

import { HiOutlineLightningBolt } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { TbStack } from "react-icons/tb";

const Product = ({ company, setCompany }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const ACCELERATED = [
    t("product.accelerated.one"),
    t("product.accelerated.two"),
    t("product.accelerated.three"),
    t("product.accelerated.four"),
    t("product.accelerated.five"),
    t("product.accelerated.six"),
  ];

  const CERTIFIED = {
    "START-UP": [
      t("product.certified.one.startup"),
      t("product.certified.two.startup"),
      t("product.certified.three.startup"),
      t("product.certified.four.startup"),
      t("product.certified.five.startup"),
      t("product.certified.six.startup"),
      t("product.certified.seven.startup"),
      t("product.certified.eight.startup"),
      t("product.certified.nine.startup"),
      t("product.certified.ten.startup"),
    ],
    SME: [
      t("product.certified.one.sme"),
      t("product.certified.two.sme"),
      t("product.certified.three.sme"),
      t("product.certified.four.sme"),
      t("product.certified.five.sme"),
      t("product.certified.six.sme"),
      t("product.certified.seven.sme"),
      t("product.certified.eight.sme"),
      t("product.certified.nine.sme"),
      t("product.certified.ten.sme"),
    ],
  };

  return (
    <div className="px-8">
      <div className="flex flex-col items-center justify-center w-full">
        <div>
          <h1 className="text-center text-xl text-black font-semibold">Bienvenue dans l'évaluation de votre entreprise</h1>
        </div>
        <div className="flex items-center justify-center w-full mt-4 gap-4">
          <p className="text-black font-medium text-center">Vous avez déjà réalisé une Valorisation?</p>
          <Link to="/my-service" className="py-2 px-4 rounded-lg text-white text-sm font-medium bg-primary">
            Voir mes services
          </Link>
        </div>
        <div className="py-4 space-y-4">
          <p className="text-black font-semibold text-center">Vous êtes:</p>
          <div className="flex justify-center space-x-8">
            {searchParams.get("type")?.toUpperCase() === "START-UP" ? (
              <div className="space-y-4 w-full">
                <button
                  className={`flex items-center justify-between border border-secondary rounded-lg h-12 px-6 w-full cursor-pointer text-sm ${
                    company.valuation_type === "START-UP" ? "bg-secondary" : ""
                  }`}
                  onClick={() => setCompany({ ...company, valuation_type: "START-UP" })}
                >
                  <p className="text-sm">Start up</p>
                </button>
              </div>
            ) : (
              <>
                <button
                  className={`border rounded-lg py-2 w-36 cursor-pointer
                ${company.valuation_type === "START-UP" ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_type: "START-UP" })}
                >
                  <p className="text-sm text-center font-semibold">Start up</p>
                </button>
                <button
                  className={`border rounded-lg py-2 w-36 cursor-pointer
                ${company.valuation_type === "SME" ? "bg-primary text-white" : "border-primary"}`}
                  onClick={() => setCompany({ ...company, valuation_type: "SME" })}
                >
                  <p className="text-sm text-center font-semibold">TPE / PME</p>
                </button>
              </>
            )}
          </div>
          <div className="w-full space-y-8">
            <div className="w-full">
              <h1 className="text-black text-center font-semibold">{t("product.title")}</h1>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <button
                className={`border-2 ${
                  company.valuation_plan === "ACCELERATED" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                onClick={() => setCompany({ ...company, valuation_plan: "ACCELERATED" })}
              >
                <div className="flex items-center gap-1 mb-2">
                  <HiOutlineLightningBolt className="text-xl text-primary" />
                  <p className="text-lg font-semibold">{t("product.accelerated.time")}</p>
                </div>
                <div className="flex gap-2 items-end text-black">
                  <h2 className="text-3xl font-semibold">{t("product.accelerated.price")}</h2>
                  <p className="font-semibold">{t("product.accelerated.taxes")}</p>
                </div>
                <p className="mt-2 font-semibold uppercase">{t("product.accelerated.title")}</p>
                <p className="font-semibold">{t("product.accelerated.maturity")}</p>

                <div className="mt-4 flex-1">
                  <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
                  <ul className="flex flex-col gap-3 mt-4 justify-center">
                    {ACCELERATED?.map((item, i) => (
                      <li className="flex gap-2 text-sm" key={i}>
                        <div className="bg-light_blue rounded-full p-1">
                          <FaCheck className="text-xs" />
                        </div>

                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <Link to="overview" className="primary-button mt-4 px-8">
                  {t("product.select")}
                </Link>
              </button>
              <button
                className={`border-2 ${
                  company.valuation_plan === "CERTIFIED" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                onClick={() => setCompany({ ...company, valuation_plan: "CERTIFIED" })}
              >
                <div className="flex items-center gap-1 mb-2">
                  <TbStack className="text-xl text-primary" />
                  <p className="text-lg font-semibold">{t("product.certified.popular")}</p>
                </div>
                <div className="flex gap-2 items-end text-black">
                  <h1 className="text-3xl font-semibold">{t("product.certified.price")}</h1>
                  <p className="font-semibold">{t("product.certified.taxes")}</p>
                </div>
                <p className="mt-2 font-semibold uppercase">{t("product.certified.title")}</p>
                <p className="font-semibold">{t("product.certified.maturity")}</p>

                <div className="mt-4">
                  <p className="text-sm font-semibold">{t("product.certified.include")}</p>
                  <ul className="flex flex-col gap-3 mt-4 justify-center">
                    {CERTIFIED[company.valuation_type]?.map((item, i) => (
                      <li className="flex gap-2 text-sm" key={i}>
                        <div className="bg-light_blue rounded-full p-1">
                          <FaCheck className="text-xs" />
                        </div>

                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <Link to="overview" className="primary-button mt-4 px-8">
                  {t("product.select")}
                </Link>
              </button>
            </div>

            <div className="flex flex-col item-center">
              <h2 className="text-lg text-black text-center underline font-medium">{t("product.bizzdev")}</h2>
              <div className="flex items-center justify-center mt-2">
                <a href={CALENDLY_COMMERCIAL} target="_blank" className="primary-button">
                  {t("product.contact")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
