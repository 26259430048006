import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

import api from "../../services/api";

const ResetPassword = () => {
  const [seePassword, setSeePassword] = useState(false);
  const [password, setPassword] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleSend = async () => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    try {
      const res = await api.post("/user/reset-password", { password, token });
      if (res.ok) {
        toast.success("Votre mot de passe a été réinitialisé");
        navigate("/login");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div>
      <h1 className="text-center text-3xl font-semibold text-black mb-12">Créez votre nouveau mot de passe</h1>
      <div className="mb-8 text-gray-800 text-center">Entrez votre nouveau mot de passe ci-dessous.</div>
      <div>
        <div className="space-y-4">
          <label className="text-base font-medium" htmlFor="password">
            Mot de passe
          </label>
          <div className="relative">
            <input type={seePassword ? "text" : "password"} name="password" className="input" value={password} onChange={(e) => setPassword(e.target.value)} />
            <div className="absolute top-1/2 -translate-y-1/2 right-4">
              {seePassword ? (
                <EyeSlashIcon className="w-6 h-6 text-primary cursor-pointer" onClick={() => setSeePassword(false)} />
              ) : (
                <EyeIcon className="w-6 h-6 text-primary cursor-pointer" onClick={() => setSeePassword(true)} />
              )}
            </div>
          </div>
          <button disabled={!password} className="primary-button w-full !mt-10" onClick={handleSend}>
            Réinitialiser le mot de passe
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
