import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";
import { CALENDLY_COMMERCIAL } from "@/services/config";

const Funding = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_step: "FUNDS" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_step: "AD" });
      if (res.ok) {
        navigate(`/annonce/${id}/create/ad`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <h1 className="text-primary text-xl">Estimer votre levée de fond</h1>
      <div className="space-y-2">
        <label className="text-base font-medium" htmlFor="annonce_financing_needs">
          Combien souhaitez vous lever ?
        </label>
        <input className="input" type="number" value={values.annonce_financing_needs || 0} onChange={(e) => setValues({ ...values, annonce_financing_needs: e.target.value })} />
      </div>
      <div className="space-y-2">
        <label className="text-base font-medium" htmlFor="annonce_dilution">
          A quel taux de dilution ?
        </label>
        <input className="input" value={values.annonce_dilution} onChange={(e) => setValues({ ...values, annonce_dilution: e.target.value })} />
      </div>

      <div className="flex items-center flex-row  ">
        <div className="border-t border-primary w-2/5" />
        <div className="w-1/5 flex items-center justify-center">
          <div className=" w-16 h-16 border border-primary  text-primary rounded-full flex items-center justify-center">OU</div>
        </div>
        <div className="border-t border-primary w-2/5" />
      </div>
      <div className="flex items-center flex-col gap-4">
        <h1 className="text-primary flex flex-col text-center justify-center text-xl">Contacter des professionels pour estimer ces mes besoins de levée</h1>
        <a href={CALENDLY_COMMERCIAL} target="_blank" rel="noreferrer" className="primary-button w-68 mr-4">
          Contacter Estimeo by Linkera
        </a>
      </div>

      <div className={`flex fixed bottom-0 right-0 bg-white items-center justify-between gap-4 w-[calc((100%-12.5rem)*4/5)] p-4`}>
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../financials" className="empty-button w-48">
            Précédent
          </Link>
          <button type="button" className="primary-button w-48" onClick={handleNext}>
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default Funding;
