import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import ImageUploadSVG from "../../../assets/svg/upload-image.svg";
import { MdDeleteOutline } from "react-icons/md";

import api from "@/services/api";
import Loader from "@/components/Loader";

const Ad = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_step: "AD" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_status: "PENDING" });
      if (res.ok) {
        navigate("/annonce/success");
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <h1 className="text-primary text-xl">Créer votre annonce</h1>
      <p className="text-gray-500 text-sm">Vos données personnelles ne seront pas visibles par les autres utilisateurs.</p>
      <div className="space-y-2 col-span-2">
        <label className="text-base font-medium" htmlFor="annonce_title">
          Titre de l'annonce
        </label>
        <input
          type="text"
          name="annonce_title"
          value={values.annonce_title}
          onChange={(e) => setValues({ ...values, annonce_title: e.target.value })}
          className="input"
          placeholder="Exemple : Solution innovante de gestion de la relation client cherche investisseurs pour accélérer son développement"
        />
      </div>
      <div className="space-y-2 col-span-2">
        <label className="text-base font-medium" htmlFor="annonce_description">
          Description
        </label>
        <textarea
          rows={8}
          type="text"
          name="annonce_description"
          value={values.annonce_description}
          onChange={(e) => setValues({ ...values, annonce_description: e.target.value })}
          className="input"
          placeholder="Exemple : Entreprise dynamique et en croissance cherche investisseurs pour accompagner son expansion et renforcer sa présence sur le marché. Portée par une vision claire et une stratégie solide, notre activité a su conquérir une clientèle fidèle grâce à une offre unique et de qualité. Nous envisageons désormais d'élargir notre gamme de produits/services et d'explorer de nouveaux marchés. Cette levée de fonds vise à financer ces initiatives stratégiques, améliorer notre infrastructure et accroître nos efforts marketing."
        />
      </div>

      <div className="space-y-2 col-span-2">
        <label className="text-base font-medium" htmlFor="annonce_title">
          Image de l'annonce
        </label>
        <ImageInput name="image" value={values.annonce_image} onChange={(e) => setValues({ ...values, annonce_image: e.target.value })} />
      </div>

      <div className={`flex fixed bottom-0 right-0 bg-white items-center justify-between gap-4 w-[calc((100%-12.5rem)*4/5)] p-4`}>
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../funds" className="empty-button w-48">
            Précédent
          </Link>
          <button type="button" className="primary-button w-48" onClick={handleSubmit}>
            Publier
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}
      >
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

export default Ad;
