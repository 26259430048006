import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";

const Offer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState();

  const MAIN_CLIENTS = [
    t("lable.main_client._1"),
    t("lable.main_client._2"),
    t("lable.main_client._3"),
    t("lable.main_client._4"),
    t("lable.main_client._5"),
    t("lable.main_client._6"),
    t("lable.main_client._7"),
    t("lable.main_client._8"),
    t("lable.main_client._9"),
    t("lable.main_client._10"),
  ];

  const BUSINESS_MODEL_OPTIONS = [
    t("lable.type_of_business_model._1"),
    t("lable.type_of_business_model._2"),
    t("lable.type_of_business_model._3"),
    t("lable.type_of_business_model._4"),
    t("lable.type_of_business_model._5"),
    t("lable.type_of_business_model._6"),
    t("lable.type_of_business_model._7"),
    t("lable.type_of_business_model._8"),
    t("lable.type_of_business_model._9"),
    t("lable.type_of_business_model._10"),
  ];

  const PRICE_COMPETITION_STRATEGY = [
    t("lable.business_position_in_terms_of_costs_for_your_customers._1"),
    t("lable.business_position_in_terms_of_costs_for_your_customers._2"),
    t("lable.business_position_in_terms_of_costs_for_your_customers._3"),
    t("lable.business_position_in_terms_of_costs_for_your_customers._4"),
  ];

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-market`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.main_client")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {MAIN_CLIENTS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.main_clients?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="main_clients"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.main_clients?.includes(option)) {
                      return { ...prev, main_clients: prev.main_clients.filter((selectedOption) => selectedOption !== option) };
                    } else {
                      return { ...prev, main_clients: [...(prev.main_clients || []), option] };
                    }
                  })
                }
              />
              {values?.main_clients?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.any_significant_customer_reference")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-x-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="market_yes"
              name="leaderFounder"
              checked={values.has_customer_reference}
              onChange={() => setValues((prev) => ({ ...prev, has_customer_reference: true }))}
            />
            <label className="text-sm" htmlFor="market_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="market_no"
              name="leaderFounder"
              checked={!values.has_customer_reference}
              onChange={() => setValues((prev) => ({ ...prev, has_customer_reference: false }))}
            />
            <label className="text-sm" htmlFor="market_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.type_of_business_model")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {BUSINESS_MODEL_OPTIONS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.businesses_model?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="businesses_model"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.businesses_model?.includes(option)) {
                      return { ...prev, businesses_model: prev.businesses_model.filter((selectedOption) => selectedOption !== option) };
                    } else {
                      return { ...prev, businesses_model: [...(prev.businesses_model || []), option] };
                    }
                  })
                }
              />
              {values?.businesses_model?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.business_position_in_terms_of_costs_for_your_customers")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {PRICE_COMPETITION_STRATEGY.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.business_costs_position === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.business_costs_position}
                onChange={() => setValues((prev) => ({ ...prev, business_costs_position: option }))}
              />
              {values?.business_costs_position === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.recurring_revenues_major_part_of_your_income")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="major_part_yes"
              name="is_mrr_major_income"
              checked={values.is_mrr_major_income}
              onChange={() => setValues((prev) => ({ ...prev, is_mrr_major_income: true }))}
            />
            <label className="text-sm" htmlFor="major_part_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="major_part_no"
              name="is_mrr_major_income"
              checked={!values.is_mrr_major_income}
              onChange={() => setValues((prev) => ({ ...prev, is_mrr_major_income: false }))}
            />
            <label className="text-sm" htmlFor="major_part_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.indicate_the_amount_of_monthly_revenue")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          type="number"
          className={`${values?.mrr ? "border-2 border-primary text-primary filled-input" : "input"}`}
          placeholder={t("placeholder_enter")}
          value={values?.mrr || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, mrr: e.target.value }))}
        />
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-human`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Offer;
