import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import api from "@/services/api";

import Loader from "@/components/Loader";

const PRODUCT_STRINGS = {
  RELATION: "product.buyer.realtion.title",
};

const PRODUCT_PRICES = {
  RELATION: "product.buyer.realtion.price",
};

const View = () => {
  const { investorId } = useParams();
  const [investor, setCompany] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, [investorId]);

  const fetchData = async () => {
    try {
      const { ok, data } = await api.get(`/investor/${investorId}`);
      if (!ok) throw new Error("Error fetching investor data");
      if (!data.buyer_has_paid) return navigate("./product");
      setCompany(data);
    } catch (error) {
      console.error("Error fetching investor data:", error);
    }
  };

  if (!investor) return <Loader />;

  return (
    <div className="w-full space-y-8 max-w-5xl mx-auto">
      <h1 className="text-xl font-semibold my-8">
        Accompagnement Reprise
        <br />
        <br />
        <span className="text-green-700 border border-green-700 px-4 py-2 rounded mt-2 bg-green-100 font-medium">Paiement Validée</span>
      </h1>
      <div>
        <div className="space-y-4">
          <div className="border border-secondary p-3 rounded-lg grid grid-cols-2 gap-2 text-lg shadow">
            <div>
              <label className="text-primary">Plan</label>
              <p className="font-semibold">
                {t(PRODUCT_STRINGS[investor.buyer_plan])} ({t(PRODUCT_PRICES[investor.buyer_plan])})
              </p>
            </div>
            <div>
              <label className="text-sm text-primary">Date de création</label>
              <p className="font-semibold">{investor.created_at ? new Date(investor.created_at).toLocaleDateString("fr-FR") : "-"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Link to={`/my-service`} className="primary-button px-10">
          Retour a la liste
        </Link>
      </div>
    </div>
  );
};

export default View;
