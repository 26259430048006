import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { HiOutlineLightningBolt } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";

import { CALENDLY_COMMERCIAL } from "@/services/config";

const Product = ({ company, setCompany, setStep }) => {
  const { t } = useTranslation();

  const NOTATION = [t("product.notation.one"), t("product.notation.two"), t("product.notation.three"), t("product.notation.four"), t("product.notation.five")];

  return (
    <div className="p-8">
      <div className="flex flex-col items-center justify-center w-full space-y-4">
        <div>
          <h1 className="text-center text-3xl text-black font-semibold">Bienvenue dans la notation de votre start-up</h1>
        </div>
        <div className="py-4 space-y-4">
          <div className="w-full space-y-8">
            <div className="grid grid-cols-1 gap-8">
              <button
                className={`border-2 ${
                  company.valuation_plan === "NOTATION" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full text-wrap max-w-md`}
                onClick={() => setCompany({ ...company, valuation_plan: "NOTATION" })}
              >
                <HiOutlineLightningBolt className="text-4xl text-primary" />
                <p className="text-sm font-semibold mt-2 mb-4">{t("product.notation.time")}</p>
                <div className="flex gap-2 items-end text-black">
                  <h2 className="text-3xl font-semibold">{t("product.notation.price")}</h2>
                  <p className="font-semibold">{t("product.notation.taxes")}</p>
                </div>
                <p className="mt-3 font-semibold uppercase">{t("product.notation.title")}</p>
                <p className="mt-3 font-semibold">Pré & Post-revenu</p>

                <div className="mt-6 flex-1">
                  <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
                  <ul className="flex flex-col gap-3 mt-4 justify-center">
                    {NOTATION?.map((a, i) => (
                      <li className="flex gap-2 text-sm items-start" key={i}>
                        <div className="bg-light_blue rounded-full p-1">
                          <FaCheck className="text-xs" />
                        </div>

                        <p className="flex-1 text-left">{a}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <Link to="overview" className="primary-button mt-4 px-8">
                  {t("product.select")}
                </Link>
              </button>
            </div>

            <div className="flex flex-col item-center">
              <h2 className="text-lg text-black text-center underline font-medium">{t("product.bizzdev")}</h2>
              <div className="flex items-center justify-center mt-2">
                <a href={CALENDLY_COMMERCIAL} target="_blank" className="primary-button">
                  {t("product.contact")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
