import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { IoArrowBack } from "react-icons/io5";

import api from "@/services/api";

import Loader from "@/components/Loader";
import useStore from "@/services/store";

const SME = () => {
  const { companyId } = useParams();
  const [company, setCompany] = useState(null);

  const { user } = useStore();

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const { ok, data } = await api.get(`/company/${companyId}`);
      if (!ok) throw new Error("Error fetching company data");
      setCompany(data);
      if (!data.user_id) await api.put(`/company/${companyId}`, { user_id: user._id });
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  if (!company) return <Loader />;

  return (
    <div className="w-full space-y-8 max-w-5xl mx-auto my-5 p-4 bg-white">
      <h1 className="text-2xl text-gray-800 font-bold mb-6">Suite à votre questionnaire, voici la fourchette de valorisation estimée de votre entreprise</h1>

      <div className="bg-gradient-to-br from-gray-100 to-white p-5 rounded-xl shadow-md">
        <h2 className="text-xl text-primary font-semibold mb-2">Fourchette de valorisation</h2>
        <div className="border border-secondary p-4 rounded-lg flex justify-between shadow-md">
          <div>
            <label className="text-sm text-primary">Fourchette basse</label>
            <p className="font-semibold text-2xl text-primary">{company.valuation_game_low_end} €</p>
          </div>
          <div>
            <label className="text-sm text-primary">Fourchette haute</label>
            <p className="font-semibold text-2xl text-primary">{company.valuation_game_high_end} €</p>
          </div>
        </div>
      </div>

      <div className="mt-6 space-y-4">
        <h2 className="text-xl text-primary font-semibold">Informations</h2>
        <div className="grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg shadow-lg">
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">Nom de l'entreprise</label>
            <p className="font-semibold text-gray-800">{company.company_name}</p>
          </div>
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">Année de création</label>
            <p className="font-semibold text-gray-800">{company.valuation_game_creation_year}</p>
          </div>
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">Secteur d'activité</label>
            <p className="font-semibold text-gray-800">{company.valuation_game_sector}</p>
          </div>
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">Raison de l'évaluation</label>
            <p className="font-semibold text-gray-800">{company.valuation_game_reason}</p>
          </div>
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">Client</label>
            <p className="font-semibold text-gray-800">{company.valuation_game_client_name}</p>
          </div>
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">Email</label>
            <p className="font-semibold text-gray-800">{company.user_email}</p>
          </div>
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">CA lors du dernier exercice</label>
            <p className="font-semibold text-gray-800">{company.valuation_game_ca} €</p>
          </div>
          <div className="col-span-1 p-3 border border-secondary rounded-lg shadow-sm">
            <label className="text-sm text-primary">EBE lors du dernier exercice</label>
            <p className="font-semibold text-gray-800">{company.valuation_game_ebe} €</p>
          </div>
        </div>
      </div>

      <div className="flex">
        <Link to={`/my-service`} className="primary-button flex items-center pl-3 pr-5">
          <IoArrowBack size={22} className="mr-2" />
          Retour a la liste
        </Link>
      </div>
    </div>
  );
};

export default SME;
