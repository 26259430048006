import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";

import KnownBpTable from "./components/KnownBpTable";
import EbitdaTable from "./components/EbitdaTable";
import NwcTable from "./components/NwcTable";
import NetTotal from "./components/NetTotal";

const STARTING_YEAR = 2020;

const FinanceBusinessPlan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();

  const years = Array.from(Array(11).keys()).map((i) => STARTING_YEAR + i);
  const computedValues = {
    net_operating_income: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    net_working_capital: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    free_cash_flow: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    actualized_free_cash_flow: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };

  if (values) {
    computedValues.net_operating_income = years.map((_, index) => (values[`ebitda_${index}`] || 0) - (values[`dna_${index}`] || 0) - (values[`other_charges_${index}`] || 0));
    computedValues.net_working_capital = years.map((_, index) => (index === 0 ? 0 : (values[`working_capital_${index}`] || 0) - (values[`working_capital_${index - 1}`] || 0)));
    computedValues.free_cash_flow = years.map(
      (_, index) => (values[`ebitda_${index}`] || 0) - (values[`taxes_${index}`] || 0) - (values[`investments_${index}`] || 0) - (values[`working_capital_${index}`] || 0)
    );
    computedValues.actualized_free_cash_flow = years.map((_, index) => (values[`free_cash_flow_${index}`] || 0) / Math.pow(1 + (values[`discount_rate`] || 0), index + 1));
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-impact-cse`);
  };
  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-20">
      <p className="mt-5 text-lg font-semibold">{t("business_plan.4tables_at_disposition")}</p>

      <ul className=" list-disc text-sm  list-inside text-gray-500 flex flex-col gap-1 mt-3">
        <li>{t("business_plan.known_bp")}</li>
        <li>{t("business_plan.computation_of_the_free_cashflow")}</li>
        <li>{t("business_plan.computation_of_NWC")}</li>
        <li>{t("business_plan.computation_with_net_total")}</li>
      </ul>

      <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg border border-red-400 dark:text-red-400 mt-7" role="alert">
        <FaInfoCircle className="text-red-800 mr-2" />
        <div>{t("business_plan.waring")}</div>
      </div>

      <div className="space-y-2">
        <div className="flex justify-between">
          <p className="text-lg font-semibold">{t("business_plan.known_bp")}</p>
          <div className="flex gap-5">
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-orange-100 rounded-md"></div>
              <p> {t("business_plan.mandatory_fields")}</p>
            </div>{" "}
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-gray-100 rounded-md"></div>
              <p>{t("business_plan.no_editable_fields")}</p>
            </div>
          </div>
        </div>
        <KnownBpTable values={values} setValues={setValues} computedValues={computedValues} />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between">
          <p className="text-lg font-semibold">{t("business_plan.EBITDA")}</p>
          <div className="flex gap-5">
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-orange-100 rounded-md"></div>
              <p> {t("business_plan.mandatory_fields")}</p>
            </div>{" "}
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-gray-100 rounded-md"></div>
              <p>{t("business_plan.no_editable_fields")}</p>
            </div>
          </div>
        </div>
        <EbitdaTable values={values} setValues={setValues} />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between">
          <p className="text-lg font-semibold">{t("business_plan.table_NWC")}</p>
          <div className="flex gap-5">
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-orange-100 rounded-md"></div>
              <p> {t("business_plan.mandatory_fields")}</p>
            </div>{" "}
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-gray-100 rounded-md"></div>
              <p>{t("business_plan.no_editable_fields")}</p>
            </div>
          </div>
        </div>
        <NwcTable values={values} setValues={setValues} computedValues={computedValues} />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between">
          <p className="text-lg font-semibold">{t("business_plan.table_net_total")}</p>
          <div className="flex gap-5">
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-orange-100 rounded-md"></div>
              <p> {t("business_plan.mandatory_fields")}</p>
            </div>{" "}
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 bg-gray-100 rounded-md"></div>
              <p>{t("business_plan.no_editable_fields")}</p>
            </div>
          </div>
        </div>
        <NetTotal values={values} setValues={setValues} />
      </div>

      <div className="flex justify-between w-full">
        <Link to={`../form-finance-review`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinanceBusinessPlan;
