import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { TbStack } from "react-icons/tb";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";
import { CALENDLY_COMMERCIAL } from "../../../services/config";

const Product = () => {
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.valuation_plan) res.data.valuation_plan = "CERTIFIED";
        setCompany(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePayment = async (plan) => {
    setLoading(true);
    let paymenetURL = "";
    if (plan === "NOTATION") paymenetURL = "/payment/notation-start-up";
    else if (plan === "ACCELERATED") paymenetURL = "/payment/accelerated-start-up";
    else paymenetURL = "/payment/certified-start-up";

    try {
      const { ok, data } = await api.post(paymenetURL, { companyId: company._id });
      if (ok) window.location.href = data.url;
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors du traitement du paiement. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const NOTATION = [t("product.notation.one"), t("product.notation.two"), t("product.notation.three"), t("product.notation.four"), t("product.notation.five")];

  const ACCELERATED = [
    t("product.accelerated.one"),
    t("product.accelerated.two"),
    t("product.accelerated.three"),
    t("product.accelerated.four"),
    t("product.accelerated.five"),
    t("product.accelerated.six"),
  ];

  const CERTIFIED = [
    t("product.certified.one.startup"),
    t("product.certified.two.startup"),
    t("product.certified.three.startup"),
    t("product.certified.four.startup"),
    t("product.certified.five.startup"),
    t("product.certified.six.startup"),
    t("product.certified.seven.startup"),
    t("product.certified.eight.startup"),
    t("product.certified.nine.startup"),
    t("product.certified.ten.startup"),
  ];

  if (!company) return <Loader />;

  if (company.valuation_plan === "NOTATION")
    return (
      <div className="w-full space-y-8">
        <div className="w-full">
          <h1 className="text-black text-center font-semibold">{t("product.title")}</h1>
        </div>
        <div className="grid grid-cols-1 gap-8 max-w-md mx-auto">
          <button
            className={`border-2 ${
              company.valuation_plan === "NOTATION" ? "border-primary" : "border-secondary"
            } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
            onClick={() => setCompany({ ...company, valuation_plan: "NOTATION" })}
          >
            <HiOutlineLightningBolt className="text-4xl text-primary" />
            <p className="text-sm font-semibold mt-2 mb-4">{t("product.notation.time")}</p>
            <div className="flex gap-2 items-end text-black">
              <h2 className="text-3xl font-semibold">{t("product.notation.price")}</h2>
              <p className="font-semibold">{t("product.notation.taxes")}</p>
            </div>
            <p className="mt-3 font-semibold uppercase">{t("product.notation.title")}</p>
            <p className="mt-3 font-semibold">Pré & Post-revenu</p>

            <div className="mt-6 flex-1">
              <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
              <ul className="flex flex-col gap-3 mt-4 justify-center">
                {NOTATION?.map((a, i) => (
                  <li className="flex gap-2 items-start text-sm" key={i}>
                    <div className="bg-light_blue rounded-full p-1">
                      <FaCheck className="text-xs" />
                    </div>

                    <p className="flex-1 text-left">{a}</p>
                  </li>
                ))}
              </ul>
            </div>
            <button onClick={() => handlePayment("NOTATION")} className="primary-button mt-4 px-8">
              {t("product.select")}
            </button>
          </button>
        </div>

        <div className="flex flex-col item-center">
          <h2 className="text-lg text-black text-center underline font-medium">{t("product.bizzdev")}</h2>
          <div className="flex items-center justify-center mt-2">
            <a href={CALENDLY_COMMERCIAL} target="_blank" className="primary-button">
              {t("product.contact")}
            </a>
          </div>
        </div>
      </div>
    );

  return (
    <div className="w-full space-y-8">
      <div className="w-full">
        <h1 className="text-black text-center font-semibold">{t("product.title")}</h1>
      </div>
      <div className="grid grid-cols-2 gap-8 max-w-4xl mx-auto">
        <button
          className={`border-2 ${
            company.valuation_plan === "ACCELERATED" ? "border-primary" : "border-secondary"
          } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full max-w-md`}
          onClick={() => setCompany({ ...company, valuation_plan: "ACCELERATED" })}
        >
          <div className="flex items-center gap-1 mb-2">
            <HiOutlineLightningBolt className="text-xl text-primary" />
            <p className="text-lg font-semibold">{t("product.accelerated.time")}</p>
          </div>
          <div className="flex gap-2 items-end text-black">
            <h2 className="text-3xl font-semibold">{t("product.accelerated.price")}</h2>
            <p className="font-semibold">{t("product.accelerated.taxes")}</p>
          </div>
          <p className="mt-2 font-semibold uppercase">{t("product.accelerated.title")}</p>
          <p className="font-semibold">{t("product.accelerated.maturity")}</p>

          <div className="mt-4 flex-1">
            <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
            <ul className="flex flex-col gap-3 mt-4 justify-center">
              {ACCELERATED?.map((item, i) => (
                <li className="flex gap-2 text-sm" key={i}>
                  <div className="bg-light_blue rounded-full p-1">
                    <FaCheck className="text-xs" />
                  </div>

                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={() => handlePayment("ACCELERATED")} className="primary-button mt-4 px-8">
            {loading ? <Loader size={"small"} /> : t("product.select")}
          </button>
        </button>
        <button
          className={`border-2 ${
            company.valuation_plan === "CERTIFIED" ? "border-primary" : "border-secondary"
          } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full max-w-md`}
          onClick={() => setCompany({ ...company, valuation_plan: "CERTIFIED" })}
        >
          <div className="flex items-center gap-1 mb-2">
            <TbStack className="text-xl text-primary" />
            <p className="text-lg font-semibold">{t("product.certified.popular")}</p>
          </div>
          <div className="flex gap-2 items-end text-black">
            <h1 className="text-3xl font-semibold">{t("product.certified.price")}</h1>
            <p className="font-semibold">{t("product.certified.taxes")}</p>
          </div>
          <p className="mt-2 font-semibold uppercase">{t("product.certified.title")}</p>
          <p className="font-semibold">{t("product.certified.maturity")}</p>

          <div className="mt-4">
            <p className="text-sm font-semibold">{t("product.certified.include")}</p>
            <ul className="flex flex-col gap-3 mt-4 justify-center">
              {CERTIFIED?.map((item, i) => (
                <li className="flex gap-2 text-sm" key={i}>
                  <div className="bg-light_blue rounded-full p-1">
                    <FaCheck className="text-xs" />
                  </div>

                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={() => handlePayment("CERTIFIED")} className="primary-button mt-4 px-8">
            {loading ? <Loader size={"small"} /> : t("product.select")}
          </button>
        </button>
      </div>

      <div className="flex flex-col item-center">
        <h2 className="text-lg text-black text-center underline font-medium">{t("product.bizzdev")}</h2>
        <div className="flex items-center justify-center mt-2">
          <a href={CALENDLY_COMMERCIAL} target="_blank" className="primary-button">
            {t("product.contact")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Product;
