import { Route, Routes } from "react-router-dom";
import { useState } from "react";

import SME from "./sme";
import StartUp from "./startup";
import Success from "./success";
import Type from "./type";
import Welcome from "./welcome";

const ValuationGame = () => {
  const [company, setCompany] = useState({});
  return (
    <div className="relative w-full h-screen flex items-center justify-center overflow-hidden">
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/type" element={<Type company={company} setCompany={setCompany} />} />
        <Route path="/sme/*" element={<SME company={company} setCompany={setCompany} />} />
        <Route path="/start-up/*" element={<StartUp company={company} setCompany={setCompany} />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </div>
  );
};

export default ValuationGame;
