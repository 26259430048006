import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import Select from "@/components/Select";

const Strategy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState();

  const ACQUISITION_CHANNELS = [
    t("lable.indicate_types_of_channel._1"),
    t("lable.indicate_types_of_channel._2"),
    t("lable.indicate_types_of_channel._3"),
    t("lable.indicate_types_of_channel._4"),
    t("lable.indicate_types_of_channel._5"),
    t("lable.indicate_types_of_channel._6"),
    t("lable.indicate_types_of_channel._7"),
    t("lable.indicate_types_of_channel._8"),
    t("lable.indicate_types_of_channel._9"),
    t("lable.indicate_types_of_channel._10"),
  ];

  const SOCIAL_NETWORK_PRESENCE = [t("lable.indicate_social_network._1"), t("lable.indicate_social_network._2"), t("lable.indicate_social_network._3")];

  const MEDIA_PRESENCE = [
    t("lable.indicate_media_persence._1"),
    t("lable.indicate_media_persence._2"),
    t("lable.indicate_media_persence._3"),
    t("lable.indicate_media_persence._4"),
  ];

  const INTERNALIZATION_STATUS = [
    t("lable.are_u_crucial_activities_internalised._1"),
    t("lable.are_u_crucial_activities_internalised._2"),
    t("lable.are_u_crucial_activities_internalised._3"),
  ];

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite lors de la mise à jour de la valeur");
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-innovation`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_developed_partnerships_with_other_entities")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="developed_partnerships_yes"
              name="has_partnership"
              checked={values.has_partnership}
              onChange={() => setValues((prev) => ({ ...prev, has_partnership: true }))}
            />
            <label className="text-sm" htmlFor="developed_partnerships_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="developed_partnerships_no"
              name="has_partnership"
              checked={!values.has_partnership}
              onChange={() => setValues((prev) => ({ ...prev, has_partnership: false }))}
            />
            <label className="text-sm" htmlFor="developed_partnerships_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.name_of_these_partners")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className={`${values?.partners ? "border-2 border-primary text-primary filled-input" : "input"}`}
          rows={4}
          placeholder={t("placeholder_enter")}
          value={values?.partners || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, partners: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.do_u_have_protected_intellectual_property")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="intellectual_property_yes"
              name="has_intellectual_properties"
              checked={values.has_intellectual_properties}
              onChange={() => setValues((prev) => ({ ...prev, has_intellectual_properties: true }))}
            />
            <label className="text-sm" htmlFor="intellectual_property_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="intellectual_property_no"
              name="has_intellectual_properties"
              checked={!values.has_intellectual_properties}
              onChange={() => setValues((prev) => ({ ...prev, has_intellectual_properties: false }))}
            />
            <label className="text-sm" htmlFor="intellectual_property_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.describe_intellectual_property")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className={`${values?.intellectual_properties ? "border-2 border-primary text-primary filled-input" : "input"}`}
          rows={4}
          placeholder={t("placeholder_enter")}
          value={values?.intellectual_properties || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, intellectual_properties: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.indicate_types_of_channel")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {ACQUISITION_CHANNELS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.acquisition_channels?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="areas"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.acquisition_channels?.includes(option)) {
                      return { ...prev, acquisition_channels: prev.acquisition_channels.filter((selectedOption) => selectedOption !== option) };
                    } else {
                      return { ...prev, acquisition_channels: [...(prev.acquisition_channels || []), option] };
                    }
                  })
                }
              />
              {values?.acquisition_channels?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.indicate_social_network")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {SOCIAL_NETWORK_PRESENCE.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.social_networks === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.social_networks}
                onChange={() => setValues((prev) => ({ ...prev, social_networks: option }))}
              />
              {values?.social_networks === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.indicate_media_persence")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {MEDIA_PRESENCE.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.medias === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input type="radio" className="hidden" name="customRadio" value={values?.medias} onChange={() => setValues((prev) => ({ ...prev, medias: option }))} />
              {values?.medias === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.are_u_crucial_activities_internalised")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <Select
          items={INTERNALIZATION_STATUS}
          selected={values?.crucial_activities_internalised || ""}
          onSelect={(value) => setValues((prev) => ({ ...prev, crucial_activities_internalised: value }))}
        />
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-market`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Strategy;
