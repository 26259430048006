import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin4Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";

import Modal from "../../../components/Modal";
import TeamModal from "../components/TeamModal";

import api from "@/services/api";
import Loader from "@/components/Loader";

const Team = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleEdit = async (v) => {
    setValues((prev) => ({
      ...prev,
      team: prev.team.map((member, i) => (i === elementIndex ? v : member)),
    }));
    setIsOpen(true);
  };

  const handleDelete = async (l, index) => {
    setValues((prev) => ({
      ...prev,
      team: prev.team.filter((_, i) => i !== index),
    }));
  };

  const handleAdd = async () => {
    const newMember = {};
    setValues((prev) => ({
      ...prev,
      team: [...prev.team, newMember],
    }));
    setElementIndex(values.team.length);
    setIsOpen(true);
  };

  const handleSave = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "TEAM" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "AD" });
      if (res.ok) {
        navigate(`/investisseurs/${id}/create/ad`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <h1 className="text-primary text-xl">Votre équipe</h1>
      <div className="flex flex-col gap-2">
        {values.team?.length > 0 && (
          <>
            <Modal
              show={isOpen}
              onClose={() => {
                setIsOpen(false);
                setElementIndex(null);
              }}
            >
              <TeamModal setOpen={setIsOpen} team={values.team[elementIndex]} setTeam={(v) => handleEdit(v)} />
            </Modal>
            {values?.team?.map((member, index) => (
              <div
                key={index}
                className="appearance-none py-1  focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center"
              >
                <p>{member?.name}</p>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setElementIndex(index);
                      setIsOpen(true);
                    }}
                  >
                    <MdEdit className="w-10 p-2 h-10 border rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-50" />
                  </button>
                  <button onClick={() => handleDelete(member, index)}>
                    <RiDeleteBin4Line className="w-10 p-2 h-10 border rounded-md flex justify-center items-center  cursor-pointer hover:bg-gray-50" />
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        <button className="w-full bg-gray-200 hover:bg-gray-300 py-2 flex mt-5 justify-center items-center gap-3 rounded" onClick={() => handleAdd()}>
          Ajouter
          <FaPlus />
        </button>
      </div>

      <div className="flex right-0 bg-white items-center justify-between gap-4 w-full p-4">
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../logo" className="empty-button w-48">
            Précédent
          </Link>
          <button type="button" className="primary-button w-48" onClick={handleNext}>
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default Team;
