import React, { useEffect, useState } from "react";
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

import UploadFileSVG from "@/assets/svg/upload-file.svg";
import api from "@/services/api.js";
import { DocumentIcon } from "@heroicons/react/24/solid";
import Loader from "@/components/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";

const Documents = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { company_id: id });
        if (res.ok) setDocuments(res.data);
        else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/company/${id}`, { annonce_step: "DOCUMENTS" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/company/${id}`, { annonce_step: "LOCATION" });
      if (res.ok) {
        navigate(`/annonce/${id}/create/location`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      const { data } = await api.postFormData(`/document?company_id=${id}`, formData);
      setDocuments(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <div className="flex flex-col gap-6 px-8">
        <h1 className="text-primary text-xl">Ajouter des documents</h1>
        <p className="text-gray-500 text-sm">Les documents de votre entreprise sont confidentiels. Seul vous pourrez autoriser les acheteurs à les consulter.</p>
        <div className="w-full">
          {documents.length > 0 && (
            <>
              <div className="flex justify-between items-center mb-5">
                <h3 className="flex-1 text-sm font-semibold">Documents</h3>
                <h3 className="w-1/6 text-sm font-semibold text-right">Date d'ajout</h3>
                <h3 className="w-1/6 text-sm font-semibold text-right">Voir</h3>
                <h3 className="w-16 text-sm font-semibold text-right" />
              </div>
              <div className="divide-y divide-secondary">
                {documents.map((item, index) => (
                  <DocRow item={item} index={index} setDocuments={setDocuments} />
                ))}
              </div>
            </>
          )}
        </div>

        <div className="flex justify-center w-full">
          <label
            htmlFor="documents"
            className="bg-white mt-4 p-4 rounded-xl w-full h-full hover:bg-gray-50 transition-all cursor-pointer flex flex-col items-center justify-center border border-secondary border-dashed"
          >
            {loading ? (
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
                <p className="text-primary">Chargement...</p>
              </div>
            ) : (
              <img src={UploadFileSVG} alt="upload" className="w-8 h-8" />
            )}
            <p className="mt-4">Cliquez pour déposez un fichier</p>
          </label>
          <input id="documents" type="file" className="hidden" disabled={loading} multiple onChange={handleFileUpload} />
        </div>
      </div>

      <div className={`flex fixed bottom-0 right-0 bg-white items-center justify-between gap-4 w-[calc((100%-12.5rem)*4/5)] p-4`}>
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../company" className="empty-button w-48">
            Précédent
          </Link>
          <button className="primary-button w-48" onClick={handleNext}>
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

const DocRow = ({ item, index, setDocuments }) => {
  const [file_name, setFileName] = useState(item.file_name);

  useEffect(() => {
    setFileName(item.file_name);
  }, [item]);

  useEffect(() => {
    if (file_name === item.file_name) return;

    const timeout = setTimeout(async () => {
      const res = await api.put(`/document/${item._id}`, { file_name });
      if (res.ok) {
        setDocuments((prev) => {
          const newValue = [...prev];
          newValue[index].file_name = file_name;
          return newValue;
        });
        toast.success("Nom du fichier mis à jour");
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [file_name]);

  const handleDeleteFile = async () => {
    const confirm = window.confirm("Voulez-vous vraiment supprimer ce fichier ?");
    if (!confirm) return;
    try {
      const res = await api.delete(`/document/${item._id}`);
      if (res.ok) setDocuments((prev) => prev.filter((doc) => doc._id !== item._id));
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div key={index} className="flex justify-between items-center py-4">
      <div className="flex-1 items-center flex">
        <DocumentIcon className="w-6 h-6 text-primary mr-4" />
        <input type="text" value={file_name} className="input" onChange={(e) => setFileName(e.target.value)} />
      </div>
      <div className="w-1/6 text-right">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
      <div className="w-1/6 flex justify-end">
        <a href={item.file_url} target="_blank" rel="noreferrer">
          <EyeIcon className="w-6 h-6 text-primary" />
        </a>
      </div>
      <div className="w-16 flex justify-end">
        <button type="button" className="transparent-button" onClick={handleDeleteFile}>
          <TrashIcon className="w-6 h-6 text-primary cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default Documents;
