import React, { useEffect, useState } from "react";
import api from "@/services/api";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PAPPERS_KEY } from "@/services/config.js";
import Loader from "@/components/Loader";

import { BUYER_TYPES, INVESTOR_TYPES, INVESTOR_AMOUNT_RANGES } from "../../../constants/investor";
import MultiSelect from "../components/Multiselect";

const NAF_SECTIONS = {
  "01": "Agriculture, sylviculture et pêche",
  "02": "Industries extractives",
  "03": "Industrie manufacturière",
  "05": "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
  "06": "Production et distribution d'eau ; assainissement, gestion des déchets et dépollution",
  "07": "Construction",
  "08": "Commerce ; réparation d'automobiles et de motocycles",
  "09": "Transports et entreposage",
  10: "Hébergement et restauration",
  11: "Activités des services financiers, hors assurance et caisses de retraite",
  12: "Services auxiliaires financiers et d'assurance",
  13: "Activités immobilières",
  14: "Activités spécialisées, scientifiques et techniques ; activités de services administratifs et de soutien",
  15: "Enseignement",
  16: "Activités pour la santé humaine",
  17: "Activités artistiques, sportives et récréatives",
  18: "Autres activités de services",
  19: "Industries manufacturières et extraction",
  20: "Production et distribution de gaz, d'électricité et d'eau",
  21: "Production et distribution d'eau",
  22: "Assainissement, gestion des déchets et dépollution",
  23: "Activités extractives",
  24: "Industrie chimique",
  25: "Industrie du caoutchouc et des plastiques",
  26: "Industrie textile",
  27: "Industrie de l'habillement",
  28: "Industrie du cuir et de la chaussure",
  29: "Industrie du bois et du papier",
  30: "Industrie de l'imprimerie et de l'édition",
  31: "Industrie de la fabrication de meubles ; industrie manufacturière n.c.a.",
  32: "Construction de machines et équipements n.c.a.",
  33: "Réparation et installation de machines et d'équipements",
  35: "Production et distribution d'électricité",
  36: "Captage, traitement et distribution d'eau",
  37: "Collecte, traitement et élimination des eaux usées ; épuration des eaux usées",
  38: "Collecte, traitement et élimination des déchets ; récupération",
  39: "Dépollution et autres services de gestion des déchets",
  41: "Construction de bâtiments",
  42: "Génie civil",
  43: "Travaux de construction spécialisés",
  45: "Commerce et réparation d'automobiles et de motocycles",
  46: "Commerce de gros, à l'exception des automobiles et des motocycles",
  47: "Commerce de détail, à l'exception des automobiles et des motocycles",
  49: "Transport terrestre et transport par conduites",
  50: "Transports par eau",
  51: "Transports aériens",
  52: "Entreposage et services auxiliaires des transports",
  53: "Activités de poste et de courrier",
  55: "Hébergement",
  56: "Restauration",
  58: "Édition",
  59: "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale",
  60: "Programmation et diffusion",
  61: "Télécommunications",
  62: "Programmation informatique, conseil et activités connexes",
  63: "Services d'information",
  64: "Activités des services financiers, hors assurance et caisses de retraite",
  65: "Assurance",
  66: "Activités auxiliaires de services financiers et d'assurance",
  68: "Activités immobilières",
  69: "Activités juridiques et comptables",
  70: "Activités des sièges sociaux ; conseil de gestion",
  71: "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques",
  72: "Recherche-développement scientifique",
  73: "Publicité et études de marché",
  74: "Autres activités spécialisées, scientifiques et techniques",
  75: "Activités vétérinaires",
  77: "Activités de location et location-bail",
  78: "Activités liées à l'emploi",
  79: "Activités des agences de voyage, voyagistes, services de réservation et activités connexes",
  80: "Enquêtes et sécurité",
  81: "Services relatifs aux bâtiments et aménagement paysager",
  82: "Activités administratives et autres activités de soutien aux entreprises",
  84: "Administration publique et défense ; sécurité sociale obligatoire",
  85: "Enseignement",
  86: "Activités pour la santé humaine",
  87: "Hébergement médico-social et social",
  88: "Action sociale sans hébergement",
  90: "Arts, spectacles et activités récréatives",
  91: "Bibliothèques, archives, musées et autres activités culturelles",
  92: "Organisation de jeux de hasard et d'argent",
  93: "Services funéraires",
  94: "Activités associatives",
  95: "Réparation d'ordinateurs et de biens personnels et domestiques",
  96: "Autres services personnels",
  97: "Activités des ménages en tant qu'employeurs de personnel domestique",
  98: "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
  99: "Activités des organisations et organismes extraterritoriaux",
  "00": "Activités des ménages en tant que producteurs de biens et services pour usage propre, non différenciées",
  // Ajoutez d'autres sections au besoin
};

const SECTEURS = [
  "AdTech",
  "BigData",
  "BioTech",
  "CleanTech",
  "Cybersécurité",
  "Data",
  "DeepTech",
  "FinTech",
  "Alimentation",
  "Santé",
  "RH",
  "IOT",
  "Média",
  "Transport",
  "Voyage",
  "PropTech",
  "Securité",
];

const getEntrepriseActivityFromNaf = (codeNaf) => {
  const section = codeNaf.substring(0, 2);
  return NAF_SECTIONS[section];
};

const Company = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [smartSearchLoading, setSmartSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSmartSearch = async () => {
    setSmartSearchLoading(true);

    const apiUrl = `https://api.pappers.fr/v2/recherche?q=${encodeURIComponent(searchInput)}&api_token=${PAPPERS_KEY}`;
    const headers = { "content-type": "application/json" };

    try {
      const response = await fetch(apiUrl, { headers });
      const data = await response.json();
      setSearchResults(data.resultats || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSmartSearchLoading(false);
    }
  };

  useEffect(() => {
    if (!searchInput) {
      setSearchResults([]);
      return;
    }
    const delaySearch = setTimeout(() => {
      if (searchInput.length < 1) return;
      handleSmartSearch();
    }, 500); // Debounce time of 1 second

    return () => {
      clearTimeout(delaySearch);
    };
  }, [searchInput]);

  const handleOptionClick = async (data) => {
    await fetchDataBySiren(data.siren);
    setSearchResults([]);
    setSearchInput("");
  };

  const fetchDataBySiren = async (siren) => {
    const apiUrl = `https://api.pappers.fr/v2/entreprise?siren=${siren}&api_token=${PAPPERS_KEY}`;
    const headers = { "content-type": "application/json" };
    try {
      const response = await fetch(apiUrl, { headers });
      const data = await response.json();
      setValues({
        ...values,
        metadata: data,
        company_name: data.nom_entreprise,
        company_category: getEntrepriseTypeFromEffectif(data.effectif_min),
        company_activity: getEntrepriseActivityFromNaf(data.code_naf),
        company_siren: data.siren,
        company_started_at: new Date(data.date_creation),
        city: data.siege?.ville || "",
        postal_code: data.siege?.code_postal || "",
        country: data.siege?.pays || "",
        turnover_2: data.finances.filter((data) => data.annee === 2022)[0]?.chiffre_affaires,
        turnover_3: data.finances.filter((data) => data.annee === 2023)[0]?.chiffre_affaires,
        turnover_4: data.finances.filter((data) => data.annee === 2024)[0]?.chiffre_affaires,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "LOCATION" });
      if (res.ok) {
        navigate(`/investisseurs/${id}/create/location`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between">
      <div className="col-span-2 flex flex-col gap-16">
        <div className="flex flex-col gap-5">
          <h1 className="text-primary text-xl">Informations générales</h1>
          {values.annonce_type === "buyer" ? (
            <div className="space-y-2">
              <label className="text-base font-medium" htmlFor="company_website">
              Titre de votre annonce de reprise
              </label>
              <input
                type="text"
                name="buyer_title"
                className="w-full input"
                placeholder="Saisissez un titre"
                required
                value={values.buyer_title || ""}
                onChange={(e) => setValues({ ...values, buyer_title: e.target.value })}
              />
            </div>
          ) : (
            <div className="space-y-2">
              <label className="text-base font-medium" htmlFor="company_website">
                Quel est le nom de votre structure d'investissement ?
              </label>
              <input
                type="text"
                name="structure_name"
                className="w-full input"
                placeholder="Saisissez un nom"
                required
                value={values.structure_name || ""}
                onChange={(e) => setValues({ ...values, structure_name: e.target.value })}
                onInput={(e) => setSearchInput(e.target.value)}
              />
            </div>
          )}
          {values.annonce_type === "buyer" ? (
            <div className="space-y-2">
              <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
                <label className="text-base font-medium" htmlFor="website">
                  Type de repreneur
                </label>
                <MultiSelect options={BUYER_TYPES} values={values.buyer_types} onSelect={(v) => setValues({ ...values, buyer_types: v })} />
              </div>
              <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
                <div className="flex flex-col gap-3">
                  <p className="text-primary text-sm font-medium">Fourchette de reprise</p>
                  <div className="flex items-center gap-3">
                    <p className="text-primary text-xs font-medium">Min</p>
                    <input
                      type="number"
                      name="acquisition_min"
                      placeholder="Montant minimum"
                      value={values.buyer_budget_min}
                      onChange={(e) => setValues({ ...values, buyer_budget_min: e.target.value })}
                      className="input"
                    />
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="text-primary text-xs font-medium">Max</p>

                    <input
                      type="number"
                      name="acquisition_min"
                      placeholder="Montant maximum"
                      value={values.buyer_budget_max}
                      onChange={(e) => setValues({ ...values, buyer_budget_max: e.target.value })}
                      className="input"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="space-y-2">
              <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
                <label className="text-base font-medium" htmlFor="website">
                  Type d'investisseur
                </label>
                <MultiSelect options={INVESTOR_TYPES} values={values.types} onSelect={(v) => setValues({ ...values, types: v })} />
              </div>
              <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
                <label className="text-base font-medium" htmlFor="website">
                  Fourchette d'investissement
                </label>
                <MultiSelect options={INVESTOR_AMOUNT_RANGES} values={values.amount_ranges} onSelect={(v) => setValues({ ...values, amount_ranges: v })} />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-5">
          <h1 className="text-primary text-xl">Informations complémentaires</h1>
          <div className="grid grid-cols-2 gap-16">
            <div className="space-y-2">
              <label className="text-base font-medium" htmlFor="website">
                Site web (optionnel)
              </label>
              <input
                type="text"
                name="website"
                placeholder="https://www.entreprise.com"
                value={values.website}
                onChange={(e) => setValues({ ...values, website: e.target.value })}
                className="input"
              />
            </div>
            <div className="space-y-2">
              <label className="text-base font-medium" htmlFor="linkedin">
                LinkedIn (optionnel)
              </label>
              <input
                type="text"
                name="linkedin"
                placeholder="https://www.linkedin.com/mon-entreprise"
                value={values.linkedin}
                onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
                className="input"
              />
            </div>
          </div>
        </div>
        <div className="flex right-0 bg-white items-center justify-between gap-4 w-full p-4">
          <Link to="/home" type="button" className="transparent-button w-48">
            Annuler
          </Link>
          {values.annonce_type === "buyer" ? (
            <button type="button" className="primary-button w-48" disabled={!values.buyer_title} onClick={handleNext}>
              Suivant
            </button>
          ) : (
            <button type="button" className="primary-button w-48" disabled={!values.structure_name || smartSearchLoading} onClick={handleNext}>
              Suivant
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Company;
