import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { CurrencyEuroIcon } from "@heroicons/react/24/solid";

import api from "@/services/api";
import useStore from "@/services/store";
import ContactModal from "./components/ContactModal";
import ContactFormModal from "./components/ContactFormModal";

const MONTHS = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "aout", "septemebre", "octobre", "novembre", "décembre"];
const AD_TYPE = {
  cession: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Cession</p>,
  reprise: <p className="text-green-700 text-xs border border-yellow-600  p-1 rounded text-center">Reprise</p>,
  levee: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Levée</p>,
};

const View = () => {
  const { user } = useStore();
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [showContactModal, setShowContactModal] = useState(false);
  const [showContactFormModal, setShowContactFormModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const getDepartement = (code) => code && code in DEPARTMENT_NAMES && DEPARTMENT_NAMES[code][0];

  const getDepartmentCode = (postalCode) => {
    if (!postalCode) return null;
    let code;
    if (postalCode.length === 5) code = postalCode.slice(0, 2);
    else if (postalCode.length === 4) code = postalCode.slice(0, 1);
    else return null;
    if (code === "97" || code === "98") code = postalCode.slice(0, 3);
    if (DEPARTMENT_NAMES.hasOwnProperty(code)) return code;
    else return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setCompany(res.data);
          if (searchParams.get("interested")) {
            setShowContactModal(true);
          }
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  if (!company) return <></>;

  return (
    <div className="py-6 px-6">
      <ContactModal show={showContactModal} onNext={() => (setShowContactModal(false), setShowContactFormModal(true))} onClose={() => setShowContactModal(false)} />
      <ContactFormModal show={showContactFormModal} onClose={() => setShowContactFormModal(false)} />

      <div className="flex items-start justify-between gap-8">
        <div className="flex flex-col items-start gap-4 w-[75%] border border-secondary rounded-lg p-6 ml">
          <div className="space-y-6 w-full">
            <div className="flex">{AD_TYPE[company.annonce_type]}</div>
            <h1 className="text-2xl font-semibold text-primary">{company.annonce_title}</h1>

            {company.annonce_type === "levee" ? (
              <>
                <div className="flex items-center justify-end">
                  <label className="text-black text-right mr-5">Besoin de financement</label>
                  {company.annonce_confidential ? (
                    <p className="text-lg font-semibold text-black text-right blur-sm">000000000€</p>
                  ) : (
                    <p className="text-lg font-semibold text-black text-right">
                      {(company.annonce_financing_needs || 0).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-end">
                  <label className="text-black text-right mr-5">Dilution</label>
                  {company.annonce_confidential ? (
                    <p className="text-lg font-semibold text-black text-right blur-sm">00%</p>
                  ) : (
                    <p className="text-lg font-semibold text-black text-right">{company.annonce_dilution}%</p>
                  )}
                </div>
              </>
            ) : (
              <div className="flex items-center justify-end">
                <label className="text-black text-right mr-5">Prix de vente souhaité</label>
                <p className="text-lg font-semibold text-black text-right">
                  {(company.annonce_type === "levee" ? company.annonce_financing_needs : company.annonce_selling_price || 0).toLocaleString("fr", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </p>
              </div>
            )}
            <p className="text-black">{company.annonce_description}</p>
          </div>

          <div className="border-t border-gray-300 border-dashed w-full" />

          <div className="space-y-6 w-full">
            <h3 className="text-xl font-semibold text-primary">Détails</h3>
            <div className="flex items-start space-x-8">
              <div className="flex-col spacy-2">
                <label className="text-gray-800 text-xs">Nom</label>
                <p className={`text-black ${company.annonce_confidential ? "blur-sm" : ""}`}>{company.annonce_confidential ? "Confidentiel" : company.company_name}</p>{" "}
              </div>
              <div className="flex-col spacy-2">
                <label className="text-gray-800 text-xs">Siren</label>
                <p className={`text-black ${company.annonce_confidential ? "blur-sm" : ""}`}>{company.annonce_confidential ? "Confidentiel" : company.company_siren}</p>
              </div>
            </div>
            <div className="flex items-start space-x-8">
              <div className="flex-col spacy-2">
                <label className="text-gray-800 text-xs">Fondée en</label>
                <p className={`text-black ${company.annonce_confidential ? "blur-sm" : ""}`}>
                  {company.annonce_confidential
                    ? "Confidentiel"
                    : `${MONTHS[new Date(company.company_started_at).getMonth()]} ${new Date(company.company_started_at).getFullYear()}`}
                </p>
              </div>
              <div className="flex-col spacy-2">
                <label className="text-gray-800 text-xs">Type d'entreprise</label>
                <p className="text-black">{company.company_category}</p>
              </div>
              <div className="flex-col spacy-2">
                <label className="text-gray-800 text-xs">Secteur d'activité</label>
                <p className="text-black">{company.company_sector}</p>
              </div>
            </div>
            <div className="flex items-start space-x-8">
              <div className="flex-col spacy-2">
                <label className="text-gray-800 text-xs">Site Web</label>
                <p className={`text-black ${company.annonce_confidential ? "blur-sm" : ""}`}>{company.annonce_confidential ? "Confidentiel" : company.company_website}</p>
              </div>
              <div className="flex-col spacy-2">
                <label className="text-gray-800 text-xs">Linkedin</label>
                <p className={`text-black ${company.annonce_confidential ? "blur-sm" : ""}`}>{company.annonce_confidential ? "Confidentiel" : company.company_linkedin}</p>
              </div>
            </div>
            <div className="border-t border-gray-300 border-dashed" />
            <div className="flex items-start">
              <div className="spacy-2 flex-1">
                <label className="text-gray-800 text-xs">Ville</label>
                <p className="text-black">{company.city}</p>
              </div>
              <div className="spacy-2 flex-1">
                <label className="text-gray-800 text-xs">Code postal</label>
                <p className="text-black">{company.postal_code}</p>
              </div>
              <div className="spacy-2 flex-1">
                <label className="text-gray-800 text-xs">Département</label>
                <p className="text-black">{getDepartement(getDepartmentCode(company.postal_code))}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start gap-4 w-[25%]">
          {user._id !== company.user_id ? (
            company.annonce_type === "cession" && (
              <div className="border border-secondary rounded-lg p-6 ml w-full">
                <h2 className="text-primary text-lg">Plus d'informations ?</h2>
                <p className="text-primary text-sm font-medium mt-4">Contacter le vendeur</p>

                <button type="button" className="primary-button mt-6 w-full" onClick={() => setShowContactModal(true)}>
                  Je suis intéressé
                </button>
              </div>
            )
          ) : company.annonce_status === "ACCEPTED" ? (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-primary text-lg">Votre annonce est en ligne</h2>
              <Link to={`/annonce/${id}/edit`} className="primary-button mt-6 w-full">
                Editer
              </Link>
              <p className="text-primary text-opacity-80 text-xs font-medium mt-4">(Votre annonce devra de nouveau être validée)</p>
            </div>
          ) : company.annonce_status === "DRAFT" ? (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-primary text-lg">Votre annonce est en cours de création</h2>

              <Link to={`/annonce/${id}/create`} className="primary-button mt-6 w-full">
                Finaliser
              </Link>
            </div>
          ) : company.annonce_status === "PENDING" ? (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-primary text-lg">Votre annonce est en attente de validation</h2>

              <Link to={`/annonce/${id}/edit`} className="primary-button mt-6 w-full">
                Editer
              </Link>
            </div>
          ) : (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-red-800 text-lg">Votre annonce a été refusée</h2>
              <p className="text-black text-sm font-medium mt-4">Vous pouvez la modifier et la soumettre à nouveau</p>

              <Link to={`/annonce/${id}/edit`} className="primary-button mt-6 w-full">
                Editer
              </Link>
            </div>
          )}
          <div className="flex flex-col items-center border border-secondary rounded-xl p-6 space-y-5 w-full">
            <h3 className="text-l font-semibold text-primary">Informations financières</h3>
            <div className="flex-1 flex-col items-start space-y-3">
              <div className="flex-1 bg-secondary rounded-lg p-4 flex items-center justify-center flex-col">
                <CurrencyEuroIcon className="w-10 h-10 text-primary" />
                <label className="text-gray-800">CA 2022</label>
                <p className="text-black font-bold">{company.annonce_confidential ? "Confidentiel" : company.turnover_2 ? formatNumber(company.turnover_2) : "Non renseigné"}</p>
              </div>
              <div className="flex-1 bg-secondary rounded-lg p-4 flex items-center justify-center flex-col">
                <CurrencyEuroIcon className="w-10 h-10 text-primary" />
                <label className="text-gray-800">CA 2023</label>
                <p className="text-black font-bold">{company.annonce_confidential ? "Confidentiel" : company.turnover_3 ? formatNumber(company.turnover_3) : "Non renseigné"}</p>
              </div>
              <div className="flex-1 bg-secondary rounded-lg p-4 flex items-center justify-center flex-col">
                <CurrencyEuroIcon className="w-10 h-10 text-primary" />
                <label className="text-gray-800">Estimation CA 2024</label>
                <p className="text-black font-bold">{company.annonce_confidential ? "Confidentiel" : company.turnover_4 ? formatNumber(company.turnover_4) : "Non renseigné"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center text-xs text-slate-500 mt-5">
        Linkera fournit des services de mise en relation entre les entreprises cherchant des financements et les investisseurs potentiels. Nous ne participons pas à la négociation
        ou à l’exécution des transactions financières et ne prenons aucun pourcentage sur les levées de fonds. Les informations publiées sur ce site sont fournies à titre
        informatif uniquement et ne constituent pas un conseil en investissement.
      </div>
    </div>
  );
};

const formatNumber = (num) => {
  if (typeof num === "string") return num;
  if (num >= 1000000) return String(Math.round(num / 100000) / 10) + "M";
  return String(Math.round(num / 1000)) + "K";
};

const DEPARTMENT_NAMES = {
  "01": ["Ain", "Auvergne-Rhône-Alpes"],
  "02": ["Aisne", "Hauts-de-France"],
  "03": ["Allier", "Auvergne-Rhône-Alpes"],
  "04": ["Alpes-de-Haute-Provence", "Provence-Alpes-Côte d'Azur"],
  "05": ["Hautes-Alpes", "Provence-Alpes-Côte d'Azur"],
  "06": ["Alpes-Maritimes", "Provence-Alpes-Côte d'Azur"],
  "07": ["Ardèche", "Auvergne-Rhône-Alpes"],
  "08": ["Ardennes", "Grand Est"],
  "09": ["Ariège", "Occitanie"],
  10: ["Aube", "Grand Est"],
  11: ["Aude", "Occitanie"],
  12: ["Aveyron", "Occitanie"],
  13: ["Bouches-du-Rhône", "Provence-Alpes-Côte d'Azur"],
  14: ["Calvados", "Normandie"],
  15: ["Cantal", "Auvergne-Rhône-Alpes"],
  16: ["Charente", "Nouvelle-Aquitaine"],
  17: ["Charente-Maritime", "Nouvelle-Aquitaine"],
  18: ["Cher", "Centre-Val de Loire"],
  19: ["Corrèze", "Nouvelle-Aquitaine"],
  "2A": ["Corse-du-Sud", "Corse"],
  "2B": ["Haute-Corse", "Corse"],
  21: ["Côte-d'Or", "Bourgogne-Franche-Comté"],
  22: ["Côtesd'Armor", "Bretagne"],
  23: ["Creuse", "Nouvelle-Aquitaine"],
  24: ["Dordogne", "Nouvelle-Aquitaine"],
  25: ["Doubs", "Bourgogne-Franche-Comté"],
  26: ["Drôme", "Auvergne-Rhône-Alpes"],
  27: ["Eure", "Normandie"],
  28: ["Eure-et-Loir", "Centre-Val de Loire"],
  29: ["Finistère", "Bretagne"],
  30: ["Gard", "Occitanie"],
  31: ["Haute-Garonne", "Occitanie"],
  32: ["Gers", "Occitanie"],
  33: ["Gironde", "Nouvelle-Aquitaine"],
  34: ["Hérault", "Occitanie"],
  35: ["Ille-et-Vilaine", "Bretagne"],
  36: ["Indre", "Centre-Val de Loire"],
  37: ["Indre-et-Loire", "Centre-Val de Loire"],
  38: ["Isère", "Auvergne-Rhône-Alpes"],
  39: ["Jura", "Bourgogne-Franche-Comté"],
  40: ["Landes", "Nouvelle-Aquitaine"],
  41: ["Loir-et-Cher", "Centre-Val de Loire"],
  42: ["Loire", "Auvergne-Rhône-Alpes"],
  43: ["Haute-Loire", "Auvergne-Rhône-Alpes"],
  44: ["Loire-Atlantique", "Pays de la Loire"],
  45: ["Loiret", "Centre-Val de Loire"],
  46: ["Lot", "Occitanie"],
  47: ["Lot-et-Garonne", "Nouvelle-Aquitaine"],
  48: ["Lozère", "Occitanie"],
  49: ["Maine-et-Loire", "Pays de la Loire"],
  50: ["Manche", "Normandie"],
  51: ["Marne", "Grand Est"],
  52: ["Haute-Marne", "Grand Est"],
  53: ["Mayenne", "Pays de la Loire"],
  54: ["Meurthe-et-Moselle", "Grand Est"],
  55: ["Meuse", "Grand Est"],
  56: ["Morbihan", "Bretagne"],
  57: ["Moselle", "Grand Est"],
  58: ["Nièvre", "Bourgogne-Franche-Comté"],
  59: ["Nord", "Hauts-de-France"],
  60: ["Oise", "Hauts-de-France"],
  61: ["Orne", "Normandie"],
  62: ["Pas-de-Calais", "Hauts-de-France"],
  63: ["Puy-de-Dôme", "Auvergne-Rhône-Alpes"],
  64: ["Pyrénées-Atlantiques", "Nouvelle-Aquitaine"],
  65: ["Hautes-Pyrénées", "Occitanie"],
  66: ["Pyrénées-Orientales", "Occitanie"],
  67: ["Bas-Rhin", "Grand Est"],
  68: ["Haut-Rhin", "Grand Est"],
  69: ["Rhône", "Auvergne-Rhône-Alpes"],
  70: ["Haute-Saône", "Bourgogne-Franche-Comté"],
  71: ["Saône-et-Loire", "Bourgogne-Franche-Comté"],
  72: ["Sarthe", "Pays de la Loire"],
  73: ["Savoie", "Auvergne-Rhône-Alpes"],
  74: ["Haute-Savoie", "Auvergne-Rhône-Alpes"],
  75: ["Paris", "Île-de-France"],
  76: ["Seine-Maritime", "Normandie"],
  77: ["Seine-et-Marne", "Île-de-France"],
  78: ["Yvelines", "Île-de-France"],
  79: ["Deux-Sèvres", "Nouvelle-Aquitaine"],
  80: ["Somme", "Hauts-de-France"],
  81: ["Tarn", "Occitanie"],
  82: ["Tarn-et-Garonne", "Occitanie"],
  83: ["Var", "Provence-Alpes-Côte d'Azur"],
  84: ["Vaucluse", "Provence-Alpes-Côte d'Azur"],
  85: ["Vendée", "Pays de la Loire"],
  86: ["Vienne", "Nouvelle-Aquitaine"],
  87: ["Haute-Vienne", "Nouvelle-Aquitaine"],
  88: ["Vosges", "Grand Est"],
  89: ["Yonne", "Bourgogne-Franche-Comté"],
  90: ["TerritoiredeBelfort", "Bourgogne-Franche-Comté"],
  91: ["Essonne", "Île-de-France"],
  92: ["Hauts-de-Seine", "Île-de-France"],
  93: ["Seine-St-Denis", "Île-de-France"],
  94: ["Val-de-Marne", "Île-de-France"],
  95: ["Val-D'Oise", "Île-de-France"],
  971: ["Guadeloupe", "Guadeloupe"],
  972: ["Martinique", "Martinique"],
  973: ["Guyane", "Guyane"],
  974: ["La Réunion", "La Réunion"],
  976: ["Mayotte", "Mayotte"],
};

export default View;
