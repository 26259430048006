import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import i18n from "./lang";

import Signin from "@/pages/auth/Signin";
import ResetPassword from "@/pages/auth/ResetPassword";
import ForgotPassword from "@/pages/auth/ForgotPassword";
import LoginAs from "./pages/auth/LoginAs";

import Home from "@/pages/home";
import Account from "@/pages/account";
import Annonce from "@/pages/annonce";
import Discussion from "@/pages/discussion";
import OnBoard from "@/pages/onboard";
import MyAnnonce from "@/pages/my-annonce";
import Valuation from "@/pages/valuation";
import Investors from "@/pages/investors";
import Buyers from "@/pages/buyers";
import DataRoom from "./pages/dataroom";
import Notation from "./pages/notation";
import Levee from "./pages/levee";
import MyService from "./pages/my-service";
import Cession from "./pages/cession";
import BuyerService from "./pages/buyer-service";
import ValuationGame from "./pages/valuation-game";

import TopBar from "./components/TopBar";
import Navbar from "@/components/NavBar";
import Loader from "@/components/Loader";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";
import AuthCover from "@/assets/img/auth_cover.png";

import api from "@/services/api";
import useStore from "@/services/store";
import ValuationGameResult from "./pages/valuation-game-result";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route path="/valuation-game/*" element={<ValuationGame />} />

          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Signin />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/connect" element={<LoginAs />} />
          </Route>
          <Route element={<OnBoardLayout />}>
            <Route path="/signup/*" element={<OnBoard />} />
          </Route>

          <Route element={<ProtectedLayout />}>
            <Route path="/home/*" element={<Home />} />
            <Route path="/annonce/*" element={<Annonce />} />
            <Route path="/investisseurs/*" element={<Investors />} />
            <Route path="/buyers/*" element={<Buyers />} />
            <Route path="/valuation/*" element={<Valuation />} />
            <Route path="/notation/*" element={<Notation />} />
            <Route path="/levee/*" element={<Levee />} />
            <Route path="/cession/*" element={<Cession />} />
            <Route path="/buyer-service/*" element={<BuyerService />} />
            <Route path="/valuation-game-result/*" element={<ValuationGameResult />} />
            <Route path="/my-annonce" element={<MyAnnonce />} />
            <Route path="/my-service" element={<MyService />} />
            <Route path="/data-room/*" element={<DataRoom />} />

            <Route path="/discussion/*" element={<Discussion />} />

            <Route path="/account" element={<Account />} />
          </Route>
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-center" />
    </I18nextProvider>
  );
};

const AuthLayout = () => {
  const { user } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/home");
  }, []);

  return (
    <div className="relative w-full h-screen flex items-center justify-center overflow-hidden bg-gray-800 bg-opacity-20">
      <img src={AuthCover} alt="cover" className="absolute top-0 right-0 object-cover -z-10" />
      <div className="flex flex-col items-center w-2/3 px-8 py-16 max-w-3xl justify-center bg-white rounded-2xl border-2 shadow-lg">
        <div className="flex justify-center items-center mb-2">
          <div className="w-36 mb-4">
            <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
          </div>
          <div className="text-5xl text-gray-700 font-medium mb-3 ml-3.5 mr-5">&times;</div>
          <div className="w-44 mb-5">
            <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
          </div>
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const OnBoardLayout = () => {
  return (
    <div className="relative w-full h-screen flex items-center justify-center overflow-hidden bg-gray-800 bg-opacity-20">
      <img src={AuthCover} alt="cover" className="absolute top-0 right-0 object-cover -z-10" />
      <div className="flex flex-col items-center w-2/3 px-8 py-16 max-w-3xl justify-center bg-white rounded-2xl border-2 shadow-lg">
        <div className="flex justify-center items-center mb-2">
          <div className="w-36 mb-4">
            <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
          </div>
          <div className="text-5xl text-gray-700 font-medium mb-3 ml-3.5 mr-5">&times;</div>
          <div className="w-44 mb-5">
            <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
          </div>
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const ProtectedLayout = () => {
  const { setUser } = useStore();

  const [loading, setLoading] = useState(true);
  const redirect = encodeURIComponent(window.location.href.replace(window.location.origin, ""));

  useEffect(() => {
    const refreshUser = async () => {
      try {
        const { ok, data } = await api.get("/user/refresh");
        if (!ok) throw new Error("Failed to refresh user");
        setUser(data.user);
        api.setToken(data.token);
      } catch {
        setUser(null);
        window.location = `/login?redirect=${redirect}`;
      } finally {
        setLoading(false);
      }
    };
    refreshUser();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <nav className="w-72 h-screen sticky top-0">
        <Navbar />
      </nav>
      <main className="w-full overflow-auto">
        <div className="h-14 border-b border-secondary">
          <TopBar />
        </div>
        <Outlet />
      </main>
    </div>
  );
};

export default App;
