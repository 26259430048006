import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";

import api from "@/services/api";
import Modal from "@/components/Modal";

const SECTEURS = [
  "AdTech",
  "BigData",
  "BioTech",
  "CleanTech",
  "Cybersécurité",
  "Data",
  "DeepTech",
  "FinTech",
  "Alimentation",
  "Santé",
  "RH",
  "IOT",
  "Média",
  "Transport",
  "Voyage",
  "PropTech",
  "Securité",
];

const ContactFormModal = ({ show, onClose }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    type: "",
    sector: "",
    role: "",
    company_name: "",
    company_address: "",
    self_address: "",
  });

  const isDisabled = () => {
    if (values.type === "") return true;
    if (values.type === "selfToAnnonce") return !values.self_address;
    else return !values.company_name || !values.company_address || !values.role || !values.sector;
  };

  const handleContact = async () => {
    setLoading(true);
    try {
      const res = await api.post(`/discussion/ad`, { ...values, company_id: id });
      if (res.ok) {
        toast.success("Votre intérêt a bien été pris en compte");
        navigate(`/discussion/${res.data._id}`);
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onClose={onClose} className="w-[90%] md:w-[700px] !p-4">
      <div className="max-h-[80vh] relative">
        <div className="absolute top-2 right-2">
          <button onClick={onClose} className="p-2">
            <RiCloseLine className="w-6 h-6" />
          </button>
        </div>
        <div className="truncate px-10 py-4">
          <div className="flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-4">Formulaire de mise en relation</h3>
          </div>

          <div className="flex flex-col space-y-5">
            {values.type === "Une entreprise" ? (
              <h1 className="text-primary text-xl">Dites-nous davantage sur votre entreprise</h1>
            ) : (
              <h1 className="text-primary text-xl">Dites-nous davantage sur vous</h1>
            )}
            <div className="flex justify-between items-center space-x-5">
              <label className="text-base font-medium" htmlFor="company_name">
                Qui représentez-vous dans cette mise en relation ?
              </label>
              <select className="select focus:ring-primary w-fit" value={values.type} onChange={(e) => setValues({ ...values, type: e.target.value })}>
                <option value="" disabled>
                  Choisir
                </option>
                <option value="selfToAnnonce">Personne physique</option>
                <option value="companyToAnnonce">Personne morale</option>
              </select>
            </div>
            {values.type === "companyToAnnonce" ? (
              <div className="space-y-5">
                <div className="flex justify-between items-center space-x-5">
                  <label className="text-base font-medium" htmlFor="company_name">
                    Secteur de votre entreprise :
                  </label>
                  <select className="select focus:ring-primary w-[50%]" value={values.sector} onChange={(e) => setValues({ ...values, sector: e.target.value })}>
                    <option value="" disabled>
                      Choisir
                    </option>
                    {SECTEURS.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-between items-center space-x-5">
                  <label className="text-base font-medium" htmlFor="company_name">
                    Nom de l'entreprise :
                  </label>
                  <input
                    type="text"
                    name="company_name"
                    className="input focus:ring-primary w-[50%]"
                    value={values.company_name}
                    onChange={(e) => setValues({ ...values, company_name: e.target.value })}
                  />
                </div>
                <div className="flex justify-between items-center space-x-5">
                  <label className="text-base font-medium" htmlFor="company_address">
                    Adresse postale de l'entreprise :
                  </label>
                  <input
                    type="text"
                    name="company_address"
                    className="input focus:ring-primary w-[50%]"
                    value={values.company_address}
                    onChange={(e) => setValues({ ...values, company_address: e.target.value })}
                  />
                </div>
                <div className="flex justify-between items-center space-x-5">
                  <label className="text-base font-medium" htmlFor="role">
                    Votre poste :
                  </label>
                  <input
                    type="text"
                    name="role"
                    className="input focus:ring-primary w-[50%]"
                    value={values.role}
                    onChange={(e) => setValues({ ...values, role: e.target.value })}
                  />
                </div>
              </div>
            ) : values.type === "selfToAnnonce" ? (
              <div className="space-y-5">
                <div className="flex justify-between items-center space-x-5">
                  <label className="text-base font-medium" htmlFor="self_address">
                    Votre adresse postale :
                  </label>
                  <input
                    type="text"
                    name="self_address"
                    className="input focus:ring-primary w-[50%]"
                    value={values.self_address}
                    onChange={(e) => setValues({ ...values, self_address: e.target.value })}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="flex justify-center items-center mt-10">
            <button onClick={handleContact} className="primary-button text-sm" disabled={isDisabled()}>
              Contacter le propriétaire
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactFormModal;
