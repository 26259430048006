import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";
import { CALENDLY_COMMERCIAL } from "@/services/config";

const SellingPrice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_step: "FUNDS" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_step: "AD" });
      if (res.ok) {
        navigate(`/annonce/${id}/create/ad`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="flex flex-col justify-between h-[calc(60vh)]">
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 flex flex-col gap-6">
            <h1 className="text-primary text-xl">Estimer un prix de vente</h1>
            <div className="space-y-2">
              <label className="text-base font-medium" htmlFor="annonce_selling_price">
                Quel est votre prix de vente souhaité ?
              </label>
              <input
                className="input"
                type={"number"}
                step={10000}
                value={values.annonce_selling_price || 0}
                onChange={(e) => setValues({ ...values, annonce_selling_price: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center flex-row">
          <div className="border-t border-primary w-2/5"></div>
          <div className="w-1/5 flex items-center justify-center">
            <div className=" w-16 h-16 border border-primary  text-primary rounded-full flex items-center justify-center">OU</div>
          </div>
          <div className="border-t border-primary w-2/5"></div>
        </div>
        <div className="flex items-center flex-col gap-4">
          <h1 className="text-primary flex flex-col text-center justify-center text-xl">Faire mon estimation par des professionels</h1>
          <a href={CALENDLY_COMMERCIAL} target="_blank" rel="noreferrer" className="primary-button w-68 mr-4">
            Contacter Estimeo by Linkera
          </a>
        </div>

        <div className={`flex fixed bottom-0 right-0 bg-white items-center justify-between gap-4 w-[calc((100%-12.5rem)*4/5)] p-4`}>
          <button type="button" className="empty-button w-48" onClick={handleSave}>
            Sauvegarder et quitter
          </button>
          <div className="flex items-center gap-4">
            <Link to="../financials" className="empty-button w-48">
              Précédent
            </Link>
            <button type="button" className="primary-button w-48" onClick={handleNext}>
              Suivant
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellingPrice;
