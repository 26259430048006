import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";

const Impact = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState();

  const EXPERTISE_IN_SUSTAINABLE_DEVELOPMENT = [
    t("lable.does_management_have_any_expertise_in_sustainable_development._1"),
    t("lable.does_management_have_any_expertise_in_sustainable_development._2"),
    t("lable.does_management_have_any_expertise_in_sustainable_development._3"),
  ];

  const SOCIAL_ENVIRONMENTAL_KPIs = [
    t("lable.does_your_company_have_KPIs_oriented_towards_social_and_environmental_results._1"),
    t("lable.does_your_company_have_KPIs_oriented_towards_social_and_environmental_results._2"),
    t("lable.does_your_company_have_KPIs_oriented_towards_social_and_environmental_results._3"),
    t("lable.does_your_company_have_KPIs_oriented_towards_social_and_environmental_results._4"),
  ];
  const ESG_INTEGRATION_LEVELS = [
    t("lable.do_u_integrate_env_and_social_governance_criteria_into_ur_dev_strategy._1"),
    t("lable.do_u_integrate_env_and_social_governance_criteria_into_ur_dev_strategy._2"),
    t("lable.do_u_integrate_env_and_social_governance_criteria_into_ur_dev_strategy._3"),
  ];

  const POPULATION_COVERAGE = [
    t("lable.how_much_of_the_population_can_your_solution_cover._1"),
    t("lable.how_much_of_the_population_can_your_solution_cover._2"),
    t("lable.how_much_of_the_population_can_your_solution_cover._3"),
  ];

  const BENEFITS_CERTAINTY = [
    t("lable.indicate_the_degree_of_certainty_about_the_benefits_of_your_solution._1"),
    t("lable.indicate_the_degree_of_certainty_about_the_benefits_of_your_solution._2"),
    t("lable.indicate_the_degree_of_certainty_about_the_benefits_of_your_solution._3"),
  ];

  const EXPECTED_BENEFITS_NATURE = [
    t("lable.the_nature_of_the_expected_benefits._1"),
    t("lable.the_nature_of_the_expected_benefits._2"),
    t("lable.the_nature_of_the_expected_benefits._3"),
  ];

  const RELEVANT_AREAS = [
    t("lable.select_intervention_benefiting_from_your_solution._1"),
    t("lable.select_intervention_benefiting_from_your_solution._2"),
    t("lable.select_intervention_benefiting_from_your_solution._3"),
    t("lable.select_intervention_benefiting_from_your_solution._4"),
    t("lable.select_intervention_benefiting_from_your_solution._5"),
    t("lable.select_intervention_benefiting_from_your_solution._6"),
    t("lable.select_intervention_benefiting_from_your_solution._7"),
    t("lable.select_intervention_benefiting_from_your_solution._8"),
    t("lable.select_intervention_benefiting_from_your_solution._9"),
    t("lable.select_intervention_benefiting_from_your_solution._10"),
  ];

  const BENEFITING_GROUPS = [
    t("lable.select_individuals_benefiting_from_your_solution._1"),
    t("lable.select_individuals_benefiting_from_your_solution._2"),
    t("lable.select_individuals_benefiting_from_your_solution._3"),
    t("lable.select_individuals_benefiting_from_your_solution._4"),
    t("lable.select_individuals_benefiting_from_your_solution._5"),
    t("lable.select_individuals_benefiting_from_your_solution._6"),
    t("lable.select_individuals_benefiting_from_your_solution._7"),
    t("lable.select_individuals_benefiting_from_your_solution._8"),
    t("lable.select_individuals_benefiting_from_your_solution._9"),
    t("lable.select_individuals_benefiting_from_your_solution._10"),
    t("lable.select_individuals_benefiting_from_your_solution._11"),
    t("lable.select_individuals_benefiting_from_your_solution._12"),
  ];

  const SUSTAINABLE_DEVELOPMENT_OBJECTIVES = [
    t("lable.which_extented_do_u_integrate_sustainable_development._1"),
    t("lable.which_extented_do_u_integrate_sustainable_development._2"),
    t("lable.which_extented_do_u_integrate_sustainable_development._3"),
    t("lable.which_extented_do_u_integrate_sustainable_development._4"),
  ];

  const SELECTED_SUSTAINABLE_DEVELOPMENT_GOALS = [
    t("lable.select_ustainable_development_benefiting_from_your_solution._1"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._2"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._3"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._4"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._5"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._6"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._7"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._8"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._9"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._10"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._11"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._12"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._13"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._14"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._15"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._16"),
    t("lable.select_ustainable_development_benefiting_from_your_solution._17"),
  ];

  const SOLUTIONS_LEVEL = [
    t("lable.your_solution_according_to_the_problem_addressed._1"),
    t("lable.your_solution_according_to_the_problem_addressed._2"),
    t("lable.your_solution_according_to_the_problem_addressed._3"),
  ];

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite lors de la mise à jour de la valeur");
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-finish`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <p className="text-lg font-semibold">{t("lable.impact.title")}</p>
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.does_management_have_any_expertise_in_sustainable_development")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {EXPERTISE_IN_SUSTAINABLE_DEVELOPMENT.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.expertise_in_sustainable_development === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.expertise_in_sustainable_development}
                onChange={() => setValues((prev) => ({ ...prev, expertise_in_sustainable_development: option }))}
              />
              {values?.expertise_in_sustainable_development === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.was_theme_of_social_responsibility_takeen_into_account")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="responsibility_yes"
              name="is_sustainable_considered_from_creation"
              value={values.is_sustainable_considered_from_creation}
              onChange={() => setValues((prev) => ({ ...prev, is_sustainable_considered_from_creation: true }))}
            />
            <label className="text-sm" htmlFor="responsibility_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="responsibility_no"
              name="is_sustainable_considered_from_creation"
              value={!values.is_sustainable_considered_from_creation}
              onChange={() => setValues((prev) => ({ ...prev, is_sustainable_considered_from_creation: false }))}
            />
            <label className="text-sm" htmlFor="responsibility_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_carried_out_any_specific_action_in_terms_of_social_responsibility")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="specific_action_yes"
              name="has_sustainable_action_made"
              value={values.has_sustainable_action_made}
              onChange={() => setValues((prev) => ({ ...prev, has_sustainable_action_made: true }))}
            />
            <label className="text-sm" htmlFor="specific_action_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="specific_action_no"
              name="has_sustainable_action_made"
              value={!values.has_sustainable_action_made}
              onChange={() => setValues((prev) => ({ ...prev, has_sustainable_action_made: false }))}
            />
            <label className="text-sm" htmlFor="specific_action_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.does_your_company_have_KPIs_oriented_towards_social_and_environmental_results")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {SOCIAL_ENVIRONMENTAL_KPIs.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.environment_kpi === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.environment_kpi}
                onChange={() => setValues((prev) => ({ ...prev, environment_kpi: option }))}
              />
              {values?.environment_kpi === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">{t("lable.please_provide_details_of_the_indicators_in_place")}</label>
        <textarea
          className={`${values?.environment_kpi_details ? "border-2 border-primary text-primary filled-input" : "input min-h-32"}`}
          placeholder={t("placeholder_enter")}
          value={values?.environment_kpi_details || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, environment_kpi_details: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.do_u_integrate_env_and_social_governance_criteria_into_ur_dev_strategy")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {ESG_INTEGRATION_LEVELS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.environment_criteria === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.environment_criteria}
                onChange={() => setValues((prev) => ({ ...prev, environment_criteria: option }))}
              />
              {values?.environment_criteria === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">{t("lable.describe_ESG_criteria")}</label>
        <textarea
          className={`${values?.environment_criteria_details ? "border-2 border-primary text-primary filled-input" : "input min-h-32"}`}
          placeholder={t("placeholder_enter")}
          value={values?.environment_criteria_details || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, environment_criteria_details: e.target.value }))}
        />
      </div>

      <p className="text-lg font-semibold">{t("lable.impact.title._2")}</p>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.does_your_offer_directly_address_issues_related_to_sustainable_development")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="offer_directly_yes"
              name="does_your_offer_directly_address_issues_related_to_sustainable_development"
              value={values.is_offer_environment_issue_related}
              onChange={() => setValues((prev) => ({ ...prev, is_offer_environment_issue_related: true }))}
            />
            <label className="text-sm" htmlFor="offer_directly_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="offer_directly_no"
              name="does_your_offer_directly_address_issues_related_to_sustainable_development"
              value={!values.is_offer_environment_issue_related}
              onChange={() => setValues((prev) => ({ ...prev, is_offer_environment_issue_related: false }))}
            />
            <label className="text-sm" htmlFor="offer_directly_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_much_of_the_population_can_your_solution_cover")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {POPULATION_COVERAGE.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.population_covered_amount === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.population_covered_amount}
                onChange={() => setValues((prev) => ({ ...prev, population_covered_amount: option }))}
              />
              {values?.population_covered_amount === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">{t("lable.provide_details_on_the_proportion_of_the_population_addressed")}</label>
        <textarea
          className={`${values?.population_covered_details ? "border-2 border-primary text-primary filled-input" : "input min-h-32"}`}
          placeholder={t("placeholder_enter")}
          value={values?.population_covered_details || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, population_covered_details: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.your_solution_according_to_the_problem_addressed")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {SOLUTIONS_LEVEL.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.completeness_degree === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.completeness_degree}
                onChange={() => setValues((prev) => ({ ...prev, completeness_degree: option }))}
              />
              {values?.completeness_degree === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.indicate_the_degree_of_certainty_about_the_benefits_of_your_solution")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {BENEFITS_CERTAINTY.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.certainly_degree === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.certainly_degree}
                onChange={() => setValues((prev) => ({ ...prev, certainly_degree: option }))}
              />
              {values?.certainly_degree === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.the_nature_of_the_expected_benefits")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {EXPECTED_BENEFITS_NATURE.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.expected_benefits_nature === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.expected_benefits_nature}
                onChange={() => setValues((prev) => ({ ...prev, expected_benefits_nature: option }))}
              />
              {values?.expected_benefits_nature === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.select_intervention_benefiting_from_your_solution")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {RELEVANT_AREAS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.benefiting_interventions?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="areas"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.benefiting_interventions?.includes(option)) {
                      return {
                        ...prev,
                        benefiting_interventions: prev.benefiting_interventions.filter((selectedOption) => selectedOption !== option),
                      };
                    } else {
                      return { ...prev, benefiting_interventions: [...(prev.benefiting_interventions || []), option] };
                    }
                  })
                }
              />
              {values?.benefiting_interventions?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.select_individuals_benefiting_from_your_solution")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {BENEFITING_GROUPS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.benefiting_individuals?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="areas"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.benefiting_individuals?.includes(option)) {
                      return {
                        ...prev,
                        benefiting_individuals: prev.benefiting_individuals.filter((selectedOption) => selectedOption !== option),
                      };
                    } else {
                      return { ...prev, benefiting_individuals: [...(prev.benefiting_individuals || []), option] };
                    }
                  })
                }
              />
              {values?.benefiting_individuals?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.which_extented_do_u_integrate_sustainable_development")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {SUSTAINABLE_DEVELOPMENT_OBJECTIVES.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.integrated_extendt === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.integrated_extendt}
                onChange={() => setValues((prev) => ({ ...prev, integrated_extendt: option }))}
              />
              {values?.integrated_extendt === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.select_ustainable_development_benefiting_from_your_solution")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {SELECTED_SUSTAINABLE_DEVELOPMENT_GOALS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.benefiting_goals?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="areas"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.benefiting_goals?.includes(option)) {
                      return {
                        ...prev,
                        benefiting_goals: prev.benefiting_goals.filter((selectedOption) => selectedOption !== option),
                      };
                    } else {
                      return {
                        ...prev,
                        benefiting_goals: [...(prev.benefiting_goals || []), option],
                      };
                    }
                  })
                }
              />
              {values?.benefiting_goals?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-finance-business-plan`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Impact;
