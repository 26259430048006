import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import Select from "@/components/Select";

const Market = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState();

  const AREAS = [
    t("lable.current_area._1"),
    t("lable.current_area._2"),
    t("lable.current_area._3"),
    t("lable.current_area._4"),
    t("lable.current_area._5"),
    t("lable.current_area._6"),
    t("lable.current_area._7"),
  ];

  const TARGET_AREAS = [
    t("lable.target_area_for_next_5_years._1"),
    t("lable.target_area_for_next_5_years._2"),
    t("lable.target_area_for_next_5_years._3"),
    t("lable.target_area_for_next_5_years._4"),
    t("lable.target_area_for_next_5_years._5"),
    t("lable.target_area_for_next_5_years._6"),
    t("lable.target_area_for_next_5_years._7"),
  ];

  const SECTOR = [
    t("lable.main_sector._1"),
    t("lable.main_sector._2"),
    t("lable.main_sector._3"),
    t("lable.main_sector._4"),
    t("lable.main_sector._5"),
    t("lable.main_sector._6"),
    t("lable.main_sector._7"),
    t("lable.main_sector._8"),
    t("lable.main_sector._9"),
    t("lable.main_sector._10"),
    t("lable.main_sector._11"),
    t("lable.main_sector._12"),
    t("lable.main_sector._13"),
    t("lable.main_sector._14"),
    t("lable.main_sector._15"),
    t("lable.main_sector._16"),
    t("lable.main_sector._17"),
  ];

  const COMPETITOR_SIZE_OPTIONS = [t("lable.how_big_the_competitors._1"), t("lable.how_big_the_competitors._2"), t("lable.how_big_the_competitors._3")];

  const MARKET_LIFECYCLE = [
    t("lable.how_mature_is_ur_market._1"),
    t("lable.how_mature_is_ur_market._2"),
    t("lable.how_mature_is_ur_market._3"),
    t("lable.how_mature_is_ur_market._4"),
  ];

  const TARGET_CUSTOMERS_COST_ISSUE_OPTIONS = [t("lable.target_customers_face_costs_._1"), t("lable.target_customers_face_costs_._2"), t("lable.target_customers_face_costs_._3")];
  const PRICE_COMPETITION_LEVEL_OPTIONS = [
    t("lable.price_of_total_competing_leve_in_your_market._1"),
    t("lable.price_of_total_competing_leve_in_your_market._2"),
    t("lable.price_of_total_competing_leve_in_your_market._3"),
  ];

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite lors de la mise à jour de la valeur");
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-strategy`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.current_area")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {AREAS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.current_area?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="areas"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.current_area?.includes(option)) {
                      return { ...prev, current_area: prev.current_area.filter((selectedOption) => selectedOption !== option) };
                    } else {
                      return { ...prev, current_area: [...(prev.current_area || []), option] };
                    }
                  })
                }
              />
              {values?.current_area?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.target_area_for_next_5_years")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-wrap gap-3">
          {TARGET_AREAS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.target_area?.includes(option) ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="checkbox"
                className="hidden"
                name="areas"
                value={option}
                onChange={() =>
                  setValues((prev) => {
                    if (prev.target_area?.includes(option)) {
                      return { ...prev, target_area: prev.target_area.filter((selectedOption) => selectedOption !== option) };
                    } else {
                      return { ...prev, target_area: [...(prev.target_area || []), option] };
                    }
                  })
                }
              />
              {values?.target_area?.includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.main_sector")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <Select items={SECTOR} selected={values?.main_activity_sector || ""} onSelect={(value) => setValues((prev) => ({ ...prev, main_activity_sector: value }))} />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_big_the_competitors")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {COMPETITOR_SIZE_OPTIONS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.competitors_size === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.competitors_size}
                onChange={() => setValues((prev) => ({ ...prev, competitors_size: option }))}
              />
              {values?.competitors_size === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_mature_is_ur_market")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <Select items={MARKET_LIFECYCLE} selected={values?.market_maturity || ""} onSelect={(value) => setValues((prev) => ({ ...prev, market_maturity: value }))} />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.does_ur_product_require_authorisations_to_enter_the_market")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="product_yes"
              name="has_product_authorization_needs"
              checked={values.has_product_authorization_needs}
              onChange={() => setValues((prev) => ({ ...prev, has_product_authorization_needs: true }))}
            />
            <label className="text-sm" htmlFor="product_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="product_no"
              name="has_product_authorization_needs"
              checked={!values.has_product_authorization_needs}
              onChange={() => setValues((prev) => ({ ...prev, has_product_authorization_needs: false }))}
            />
            <label className="text-sm" htmlFor="product_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.target_customers_face_costs_")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {TARGET_CUSTOMERS_COST_ISSUE_OPTIONS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.target_customers_costs_multiple_use === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.target_customers_costs_multiple_use}
                onChange={() => setValues((prev) => ({ ...prev, target_customers_costs_multiple_use: option }))}
              />
              {values?.target_customers_costs_multiple_use === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.is_it_easy_for_customer_to_switch_from_one_to_other_competing_solution")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_yes"
              name="is_easy_switch"
              checked={values.is_easy_switch}
              onChange={() => setValues((prev) => ({ ...prev, is_easy_switch: true }))}
            />
            <label className="text-sm" htmlFor="competing_solution_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_no"
              name="is_easy_switch"
              checked={!values.is_easy_switch}
              onChange={() => setValues((prev) => ({ ...prev, is_easy_switch: false }))}
            />
            <label className="text-sm" htmlFor="competing_solution_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.price_of_total_competing_leve_in_your_market")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {PRICE_COMPETITION_LEVEL_OPTIONS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.competitors_pricing_level === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.competitors_pricing_level}
                onChange={() => setValues((prev) => ({ ...prev, competitors_pricing_level: option }))}
              />
              {values?.competitors_pricing_level === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_strong_is_the_price_competing")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {PRICE_COMPETITION_LEVEL_OPTIONS.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.strong_market_pricing === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.strong_market_pricing}
                onChange={() => setValues((prev) => ({ ...prev, strong_market_pricing: option }))}
              />
              {values?.strong_market_pricing === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-offer`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex justify-end w-full gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Market;
