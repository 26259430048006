import { useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";

import CompanyForm from "./form";
import Overview from "./overview";
import Product from "./product";

const New = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [company, setCompany] = useState({
    valuation: true,
    valuation_type: searchParams.get("type")?.toUpperCase() || "START-UP",
    valuation_step: "PRODUCT",
    valuation_status: "PENDING",
    company_name: "",
    company_siren: "",
    company_category: "",
    company_activity: "",
  });
  return (
    <Routes>
      <Route path="/" element={<Product company={company} setCompany={setCompany} />} />
      <Route path="/overview" element={<Overview company={company} />} />
      <Route path="/form" element={<CompanyForm company={company} setCompany={setCompany} />} />
    </Routes>
  );
};

export default New;
