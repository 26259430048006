import React from "react";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";

import SingleSelectBis from "../../components/SingleSelectBis";

const CompetitorModal = ({ setOpen, competitor = {}, setCompetitor, questions }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold"> {t("lable.your_competitors")}</p>
        <IoCloseSharp className="cursor-pointer text-xl" onClick={() => setOpen(false)} />
      </div>

      <div className="mt-5">
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.name")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={competitor?.competitor_name || ""}
            onChange={(e) => setCompetitor({ ...competitor, competitor_name: e.target.value })}
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.competitor_description")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <textarea
            className="input min-h-32"
            placeholder={t("placeholder_enter")}
            value={competitor?.competitor_description || ""}
            onChange={(e) => setCompetitor({ ...competitor, competitor_description: e.target.value })}
          />
        </div>

        <SingleSelectBis
          question={questions?.find((q) => q.key === "type_of_competitor")}
          value={competitor.type_of_competitor}
          onChange={(value) => setCompetitor({ ...competitor, type_of_competitor: value })}
        />

        <div className="mt-8 mb-14 flex justify-end">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompetitorModal;
