import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import CurrencyInput from "react-currency-input-field";

const FinanceSituation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-finance-shareholder`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.is_ur_business_already_profitable")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="profitable_yes"
              name="is_profitable"
              onChange={() => setValues((prev) => ({ ...prev, is_profitable: true }))}
              checked={values?.is_profitable === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="profitable_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="profitable_no"
              name="is_profitable"
              onChange={() => setValues((prev) => ({ ...prev, is_profitable: false }))}
              checked={values?.is_profitable === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="profitable_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.current_cash_balance")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.cash_flow}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, cash_flow: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.current_monthly_cash_burn")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.current_monthly_cash_burn}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, current_monthly_cash_burn: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.net_income_last_year")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.net_income_3}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, net_income_3: value.float }))}
          />
        </div>
      </div>

      <div className="flex justify-between w-full">
        <Link to={`../form-innovation-rnd`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinanceSituation;
