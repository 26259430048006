import { Link } from "react-router-dom";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";

const Welcome = () => {
  return (
    <div className="flex flex-col px-8 py-16 max-w-3xl justify-center bg-white">
      <div className="flex items-center justify-center mb-2">
        <div className="w-36 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-3.5 mr-5">&times;</div>
        <div className="w-44 mb-5">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-2">
        <h1 className="text-2xl text-primary font-semibold">Estimer votre valorisation en 1 minute</h1>
        <p className="text-sm text-gray-700 font-medium pb-4">Remplissez ces quelques questions et obtenez une première fourchette de valorisation !</p>
        <Link to="/valuation-game/type" className="primary-button">
          Démarrer une valorisation
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
