import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftIcon, DocumentTextIcon, EyeIcon } from "@heroicons/react/24/outline";
import { MdReviews } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import { API_URL } from "@/services/config";
import useStore from "@/services/store";

const UserFromView = () => {
  const { id } = useParams();
  const [discussion, setDiscussion] = useState();
  const { user } = useStore();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await api.get(`/discussion/${id}`);

      if (res.ok) setDiscussion(res.data);
      else toast.error("Une erreur est survenue");
    } catch (e) {
      console.error(e);
    }
  };

  if (!discussion) return <Loader />;

  if (!discussion.nda_user_from_signed)
    return (
      <div className="mt-10 flex flex-col justify-center items-center h-96">
        <div className="border rounded-md  flex flex-col justify-center items-center  mx-6 py-10 px-20 text-primary font-semibold gap-4">
          <FaFileContract className="text-5xl" /> Afin de pouvoir poursuivre la mise en relation, veuillez signer le NDA.
          <a
            target="_blank"
            href={`${API_URL}/discussion/${discussion._id}/sign-nda?user_id=${user._id}`}
            className="px-8 py-1 mt-5 border border-primary rounded-md hover:bg-gray-50"
          >
            Signer le NDA
          </a>
        </div>
      </div>
    );

  if (!discussion.nda_user_to_signed)
    return (
      <div className="mt-10 flex flex-col justify-center items-center  h-96">
        <div className="border rounded-md  flex flex-col justify-center items-center  mx-6 py-10 px-20 text-primary font-semibold gap-4">
          <MdReviews className="text-5xl" /> En attente de la signature du NDA par l'autre partie.
        </div>
      </div>
    );

  return (
    <div className="flex items-start gap-8 p-8">
      <div className="w-full grid grid-cols-3 gap-10">
        <div className="space-y-10">
          <div className="space-y-6 border border-secondary rounded-xl p-8">
            <h2 className=" text-black font-semibold">Étape de la négociation</h2>
            <div className="space-y-2 w-full">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-green-800 text-white text-sm font-semibold">1</div>
                <p className="flex-1 text-sm text-green-800 ml-2">Intéret éprouvé</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white bg-green-800">2</div>
                <p className="flex-1 text-sm ml-2 text-green-800">Signature du NDA par l'acquéreur potentiel</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white bg-green-800">3</div>
                <p className="flex-1 text-sm ml-2 text-green-800">Signature du NDA par le propriétaire cédant</p>
              </div>
              <div className="flex items-center justify-center">
                <div
                  className={`flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white ${
                    discussion.status == "ONGOING" ? "bg-green-800" : "bg-gray-400"
                  }`}
                >
                  4
                </div>
                <p className={`flex-1 text-sm ml-2 ${discussion.status == "ONGOING" ? "text-green-800" : "text-gray-400"}`}>Data Room de l'annonce</p>
              </div>
              <div className="flex items-center justify-center">
                <div
                  className={`flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white ${
                    discussion.status == "STOPPED" ? "bg-green-800" : "bg-gray-400"
                  }`}
                >
                  5
                </div>
                <p className={`flex-1 text-sm ml-2 ${discussion.status == "STOPPED" ? "text-primary" : "text-gray-400"}`}>Clôture de la négociation</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-2 border border-secondary rounded-xl p-8 px-22 space-y-6 flex items-center">
          <p className="text-black text-center">
            Cette partie est en cours de développement. <br /> Vous pourrez bientôt consulter les documents partagés par le propriétaire de l'annonce.
          </p>
        </div>
      </div>
    </div>
  );
};

// removed annonce !!

const Documents = ({ company }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { company_id: company._id });
        if (res.ok) setDocuments(res.data);
        else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [company]);

  if (loading) return <Loader />;

  return (
    <div className="col-span-2 space-y-6 border border-secondary rounded-xl p-8">
      <div>
        <h2 className=" text-black font-semibold">Documents partagés</h2>
        <h3 className=" text-black text-sm italic">Vous trouverez ici tous les documents partagés par le propriétaire de l'annonce.</h3>
      </div>
      <div className="space-y-2 w-full">
        {documents.length > 0 ? (
          <div className="divide-y divide-secondary">
            {documents.map((e, index) => (
              <div key={`document-${index}`} className="flex justify-between items-center">
                <div className="flex items-center py-4">
                  <DocumentTextIcon className="w-6 h-6 text-primary mr-4" />
                  <h1 className="font-medium">{e.file_name}</h1>
                </div>
                <a href={e.file_url} target="_blank" rel="noreferrer">
                  <EyeIcon className="w-6 h-6 text-primary" />
                </a>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <p className="text-gray-500">Aucun document</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserFromView;
