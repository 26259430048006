import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Product from "./product";

const New = () => {
  const [investor, setInvestor] = useState({
    buyer: true,
    buyer_plan: "",
  });
  return (
    <Routes>
      <Route path="/" element={<Product investor={investor} setInvestor={setInvestor} />} />
    </Routes>
  );
};

export default New;
