import React from "react";
import { Route, Routes } from "react-router-dom";

import View from "./View";
import Create from "./create";
import List from "./List";
import Edit from "./Edit";

const Investors = () => (
  <Routes>
    <Route path="/:id" element={<View />} />
    <Route path="/:id/create/*" element={<Create />} />
    <Route path="/:id/edit" element={<Edit />} />
    <Route path="/" element={<List />} />
  </Routes>
);

export default Investors;
