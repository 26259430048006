import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftIcon, DocumentTextIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FaFileContract } from "react-icons/fa";

import api from "@/services/api";
import toast from "react-hot-toast";
import UplodFileSVG from "@/assets/svg/upload-file.svg";
import Loader from "@/components/Loader";
import { API_URL } from "@/services/config";
import useStore from "@/services/store";

const UserToView = () => {
  const { id } = useParams();
  const [discussion, setDiscussion] = useState();
  const { user } = useStore();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await api.get(`/discussion/${id}`);
      if (res.ok) setDiscussion(res.data);
      else toast.error("Une erreur est survenue");
    } catch (e) {
      console.error(e);
    }
  };

  if (!discussion) return <Loader />;

  if (!discussion.nda_user_to_signed)
    return (
      <div className="mt-10 flex flex-col justify-center items-center h-96">
        <div className="border rounded-md  flex flex-col justify-center items-center  mx-6 py-10 px-20 text-primary font-semibold gap-4">
          <FaFileContract className="text-5xl" /> Afin de pouvoir poursuivre la mise en relation, veuillez signer le NDA.
          <a
            target="_blank"
            href={`${API_URL}/discussion/${discussion._id}/sign-nda?user_id=${user._id}`}
            className="px-8 py-1 mt-5 border border-primary rounded-md hover:bg-gray-50"
          >
            Signer le NDA
          </a>
        </div>
      </div>
    );

  if (!discussion.nda_user_from_signed)
    return (
      <div className="mt-10 flex flex-col justify-center items-center  h-96">
        <div className="border rounded-md  flex flex-col justify-center items-center  mx-6 py-10 px-20 text-primary font-semibold gap-4">
          <MdReviews className="text-5xl" /> En attente de la signature du NDA par l'autre partie.
        </div>
      </div>
    );

  return (
    <div className="flex items-start gap-8 py-20 px-8">
      <div className="w-12">
        <Link to="/discussion" className="text-primary">
          <ArrowLeftIcon className="w-5 h-5" />
        </Link>
      </div>
      <div className="grid grid-cols-4 gap-10">
        <div className="space-y-10">
          <div className="space-y-6 border border-secondary rounded-xl p-8">
            <h2 className=" text-black font-semibold">Étape de la négociation</h2>
            <div className="space-y-2 w-full">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-green-800 text-white text-sm font-semibold">1</div>
                <p className="flex-1 text-sm text-green-800 ml-2">Intéret éprouvé</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white bg-green-800">2</div>
                <p className="flex-1 text-sm ml-2 text-green-800">Signature du NDA par l'acquéreur potentiel</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white bg-green-800`">3</div>
                <p className="flex-1 text-sm ml-2 text-green-800">Signature du NDA par le propriétaire cédant</p>
              </div>
              <div className="flex items-center justify-center">
                <div
                  className={`flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white ${
                    discussion.status == "ONGOING" ? "bg-green-800" : "bg-gray-400"
                  }`}
                >
                  4
                </div>
                <p className={`flex-1 text-sm ml-2 ${discussion.status == "ONGOING" ? "text-green-800" : "text-gray-400"}`}>Discussion</p>
              </div>
              <div className="flex items-center justify-center">
                <div
                  className={`flex items-center justify-center w-6 h-6 rounded-full text-sm font-semibold text-white ${
                    discussion.status == "STOPPED" ? "bg-green-800" : "bg-gray-400"
                  }`}
                >
                  5
                </div>
                <p className={`flex-1 text-sm ml-2 ${discussion.status == "STOPPED" ? "text-primary" : "text-gray-400"}`}>Clôture de la négociation</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-4 ">
          <Documents discussion={discussion} />
        </div>
      </div>
    </div>
  );
};

const Documents = ({ company }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { company_id: company._id });
        if (res.ok) setDocuments(res.data);
        else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [company]);

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      const { data } = await api.postFormData(`/document?company_id=${company._id}`, formData);
      setDocuments(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
      setLoading(false);
    }
  };

  const handleShare = async (share) => {};

  if (loading) return <Loader />;

  return (
    <section className="w-full border border-secondary rounded-lg p-8 col-span-2">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-xl font-semibold text-black mb-2">Ma Data Room</h1>
          <p className="text-base text-gray-500">Ajoutez et gérez les fichiers de votre annonce</p>
        </div>

        {(dataRoom.users || []).includes(discussion.investor_id) ? (
          <button type="button" className="red-button" onClick={() => handleShare(false)}>
            Arrêter le partage
          </button>
        ) : (
          <button type="button" className="primary-button" onClick={() => handleShare(true)}>
            Partager la Data Room
          </button>
        )}
      </div>
      <div className="w-full mt-6">
        {documents.length > 0 && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="flex-1 text-sm font-semibold pl-12">Documents</h3>
              <h3 className="w-1/4 text-sm font-semibold text-center">Date d'ajout</h3>
              <h3 className="w-20 text-sm font-semibold text-center">Voir</h3>
              <h3 className="w-20" />
            </div>
            <div className="divide-y divide-secondary">
              {documents.map((item, index) => (
                <DocRow key={index} item={item} index={index} setDocuments={setDocuments} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="flex justify-center w-full">
        <label
          htmlFor="documents"
          className="bg-white mt-4 p-4 rounded-xl w-full h-full hover:bg-gray-50 transition-all cursor-pointer flex flex-col items-center justify-center border border-secondary border-dashed"
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
              <p className="text-primary">Chargement...</p>
            </div>
          ) : (
            <img src={UplodFileSVG} alt="upload" className="w-8 h-8" />
          )}
          <p className="mt-4">Cliquez pour déposez un fichier</p>
        </label>
        <input id="documents" type="file" className="hidden" disabled={loading} multiple onChange={handleFileUpload} />
      </div>
    </section>
  );
};

const DocRow = ({ item, index, setDocuments }) => {
  const [file_name, setFileName] = useState(item.file_name);

  useEffect(() => {
    setFileName(item.file_name);
  }, [item]);

  useEffect(() => {
    if (file_name === item.file_name) return;

    const timeout = setTimeout(async () => {
      const res = await api.put(`/document/${item._id}`, { file_name });
      if (res.ok) {
        setDocuments((prev) => {
          const newValue = [...prev];
          newValue[index].file_name = file_name;
          return newValue;
        });
        toast.success("Nom du fichier mis à jour");
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [file_name]);

  const handleDeleteFile = async () => {
    const confirm = window.confirm("Voulez-vous vraiment supprimer ce fichier ?");
    if (!confirm) return;
    try {
      const res = await api.delete(`/document/${item._id}`);
      if (res.ok) setDocuments((prev) => prev.filter((doc) => doc._id !== item._id));
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div key={index} className="flex justify-between items-center py-2">
      <div className="flex-1 items-center flex">
        <DocumentTextIcon className="w-6 h-6 text-primary mr-4" />
        <input type="text" value={file_name} className="input" onChange={(e) => setFileName(e.target.value)} />
      </div>
      <div className="w-1/4 text-center">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
      <div className="w-20 flex justify-center">
        <a href={item.file_url} target="_blank" rel="noreferrer">
          <EyeIcon className="w-6 h-6 text-primary" />
        </a>
      </div>
      <div className="w-20 flex justify-center">
        <button type="button" className="transparent-button" onClick={handleDeleteFile}>
          <TrashIcon className="w-6 h-6 text-primary cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default UserToView;
