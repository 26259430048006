import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { FaCheck } from "react-icons/fa6";
import { TbStack } from "react-icons/tb";

import { CALENDLY_COMMERCIAL } from "@/services/config";
import api from "@/services/api";

import Loader from "@/components/Loader";

const Product = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const [company, setCompany] = useState(null);

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const { ok, data } = await api.get(`/company/${companyId}`);
      if (!ok) throw new Error("Error fetching company data");
      setCompany(data);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const handlePayment = async (plan) => {
    try {
      const { ok, data } = await api.post("/payment/cession", { companyId: company._id, plan });
      if (ok) window.location.href = data.url;
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors du traitement du paiement. Veuillez réessayer.");
    }
  };

  const DIAGNOSTIC = [
    t("product.cession.diagnostic.one"),
    t("product.cession.diagnostic.two"),
    t("product.cession.diagnostic.three"),
    t("product.cession.diagnostic.four"),
    t("product.cession.diagnostic.five"),
    t("product.cession.diagnostic.six"),
  ];

  const RELATION = [
    t("product.cession.realtion.one"),
    t("product.cession.realtion.two"),
    t("product.cession.realtion.three"),
    t("product.cession.realtion.four"),
    t("product.cession.realtion.five"),
  ];

  if (!company) return <Loader />;

  return (
    <div className="px-8">
      <div className="flex flex-col items-center justify-center w-full">
        <div>
          <h1 className="text-center text-xl text-black font-semibold">Préparation sur mesure - Cession</h1>
        </div>
        <div className="py-4 space-y-4">
          <div className="w-full space-y-8">
            <div className="w-full">
              <h1 className="text-black text-center font-semibold">{t("product.title")}</h1>
            </div>
            <div className="grid grid-cols-3 gap-8">
              <div className="grid grid-rows-2 gap-8">
                <button
                  className={`border-2 ${
                    company.cession_plan === "REDACTION" ? "border-primary" : "border-secondary"
                  } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                  onClick={() => setCompany({ ...company, cession_plan: "REDACTION" })}
                >
                  <div className="flex flex-col items-center gap-1 mb-2">
                    <TbStack className="text-3xl text-primary" />
                    <p className="text-sm font-semibold">{t("product.cession.redaction.title")}</p>
                  </div>
                  <div className="flex gap-2 items-end text-black">
                    <h2 className="text-3xl font-semibold">{t("product.cession.redaction.price")}</h2>
                    <p className="font-semibold">{t("product.accelerated.taxes")}</p>
                  </div>
                  <p className="font-semibold">{t("product.cession.redaction.turnover")}</p>

                  <div className="mt-4 flex-1">
                    <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
                    <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                      <li className="flex gap-2 text-sm">
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>
                        <p>{t("product.cession.redaction.one")}</p>
                      </li>
                    </ul>
                  </div>
                  <button onClick={() => handlePayment("REDACTION")} className="primary-button mt-auto px-8">
                    {t("product.order")}
                  </button>
                </button>
                <button
                  className={`border-2 ${
                    company.cession_plan === "STRATEGY" ? "border-primary" : "border-secondary"
                  } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                  onClick={() => setCompany({ ...company, cession_plan: "STRATEGY" })}
                >
                  <div className="flex flex-col items-center gap-1 mb-2">
                    <TbStack className="text-3xl text-primary" />
                    <p className="text-sm font-semibold">{t("product.cession.strategy.title")}</p>
                  </div>
                  <div className="flex gap-2 items-end text-black">
                    <h2 className="text-3xl font-semibold">{t("product.cession.strategy.price")}</h2>
                    <p className="font-semibold">{t("product.accelerated.taxes")}</p>
                  </div>
                  <p className="font-semibold">{t("product.cession.strategy.turnover")}</p>

                  <div className="mt-4 flex-1">
                    <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
                    <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                      <li className="flex gap-2 text-sm">
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>
                        <p>{t("product.cession.strategy.one")}</p>
                      </li>
                    </ul>
                  </div>
                  <button onClick={() => handlePayment("STRATEGY")} className="primary-button mt-auto px-8">
                    {t("product.order")}
                  </button>
                </button>
              </div>
              <button
                className={`border-2 ${
                  company.cession_plan === "DIAGNOSTIC" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                onClick={() => setCompany({ ...company, cession_plan: "DIAGNOSTIC" })}
              >
                <div className="flex flex-col items-center gap-1 mb-2">
                  <TbStack className="text-3xl text-primary" />
                  <p className="text-sm font-semibold">{t("product.cession.diagnostic.title")}</p>
                </div>
                <div className="flex gap-2 items-end text-black">
                  <h1 className="text-3xl font-semibold">{t("product.cession.diagnostic.price")}</h1>
                  <p className="font-semibold">{t("product.certified.taxes")}</p>
                </div>
                <p className="font-semibold">{t("product.cession.diagnostic.turnover")}</p>

                <div className="mt-4">
                  <p className="text-sm font-semibold">{t("product.certified.include")}</p>
                  <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                    {DIAGNOSTIC.map((item, i) => (
                      <li className="flex gap-2 text-sm" key={i}>
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>

                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <button onClick={() => handlePayment("DIAGNOSTIC")} className="primary-button mt-auto px-8">
                  {t("product.order")}
                </button>
              </button>

              <button
                className={`border-2 ${
                  company.cession_plan === "RELATION" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                onClick={() => setCompany({ ...company, cession_plan: "RELATION" })}
              >
                <div className="flex flex-col items-center gap-1 mb-2">
                  <TbStack className="text-3xl text-primary" />
                  <p className="text-sm font-semibold">{t("product.cession.realtion.title")}</p>
                </div>
                <div className="flex gap-2 items-end text-black">
                  <h1 className="text-3xl font-semibold">{t("product.cession.realtion.price")}</h1>
                  <p className="font-semibold">{t("product.certified.taxes")}</p>
                </div>
                <p className="font-semibold">{t("product.cession.realtion.turnover")}</p>

                <div className="mt-4">
                  <p className="text-sm font-semibold">{t("product.certified.include")}</p>
                  <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                    {RELATION.map((item, i) => (
                      <li className="flex gap-2 text-sm" key={i}>
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>

                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <button onClick={() => handlePayment("RELATION")} className="primary-button mt-auto px-8">
                  {t("product.order")}
                </button>
              </button>
            </div>

            <div className="flex flex-col item-center">
              <h2 className="text-lg text-black text-center underline font-medium">{t("product.bizzdev")}</h2>
              <div className="flex items-center justify-center mt-2">
                <a href={CALENDLY_COMMERCIAL} target="_blank" className="primary-button">
                  {t("product.contact")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
