import { Link, useSearchParams } from "react-router-dom";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";
import { useEffect, useState } from "react";
import Loader from "@/components/Loader";
import api from "@/services/api";
import toast from "react-hot-toast";
import { GoCheckCircle } from "react-icons/go";

const Success = () => {
  const [company, setCompany] = useState();

  const [searchParams] = useSearchParams();
  const company_id = searchParams.get("company_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ok, data } = await api.get(`/company/${company_id}`);
        if (!ok) return toast.error("Une erreur s'est produite lors de la récupération des données de l'entreprise");
        setCompany(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  if (!company)
    return (
      <div className="flex flex-col px-8 py-16 w-[42rem] justify-center bg-white">
        <Loader />;
      </div>
    );

  return (
    <div className="flex flex-col px-8 py-16 w-[42rem] justify-center bg-white">
      <div className="flex items-center mb-2">
        <div className="w-24 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="App Logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-1 mr-2">&times;</div>
        <div className="w-32 mb-4">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="Estimeo Logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-4">
        <h1 className="flex items-center text-2xl gap-2 text-primary font-semibold">
          <span className="text-4xl text-green-700 rounded-full bg-green-100">
            <GoCheckCircle />
          </span>
          Données de valorisation envoyées avec succès !
        </h1>
        <p className="text-sm text-gray-700 font-medium">Merci d'avoir soumis vos données de valorisation. Notre équipe les examinera et vous répondra sous peu.</p>
        <div className="flex gap-2 items-center">
          <Link to={`/signup?redirect=/valuation-game-result/${company.company_type}/${company._id}`} className="primary-button">
            Inscrivez-vous
          </Link>
          <span className="font-medium">pour voir vos résultats et profiter de nos services.</span>
        </div>
      </div>
    </div>
  );
};

export default Success;
