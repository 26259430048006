import { Route, Routes } from "react-router-dom";

import New from "./new";
import Product from "./product";
import View from "./view";

const BuyerService = () => {
  return (
    <Routes>
      <Route path="/new/*" element={<New />} />
      <Route path="/:investorId" element={<View />} />
      <Route path="/:investorId/product" element={<Product />} />
    </Routes>
  );
};

export default BuyerService;
