import React from "react";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";

import SingleSelectBis from "../../components/SingleSelectBis";
import CurrencyInput from "react-currency-input-field";

const FundraserModal = ({ setOpen, fundraser = {}, setFundraser, questions }) => {
  const { t } = useTranslation();

  return (
    <div className="max-h-[85vh]">
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold"> {t("lable.title")}</p>
        <IoCloseSharp className="cursor-pointer text-xl" onClick={() => setOpen(false)} />
      </div>

      <div className="mt-5 flex flex-col gap-6">
        <SingleSelectBis
          question={questions?.find((q) => q.key === "round_type")}
          value={fundraser.round_type}
          onChange={(value) => setFundraser({ ...fundraser, round_type: value })}
        />

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.amount_of_funds_raised")}</label>
          <div className="flex gap-2 input justify-between items-center">
            <CurrencyInput
              intlConfig={{ locale: "fr-FR", currency: "EUR" }}
              decimalsLimit={2}
              className=" w-[90%] focus:outline-none tracking-wider"
              value={fundraser?.amount_of_funds_raised || ""}
              onValueChange={(_, name, value) => setFundraser({ ...fundraser, amount_of_funds_raised: value.float })}
            />
          </div>
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.fundraising_date")}</label>
          <input
            className="input"
            placeholder={t("placeholder_date")}
            value={fundraser?.fundraising_date || ""}
            onChange={(e) => setFundraser({ ...fundraser, fundraising_date: e.target.value })}
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.enter_name_of_investores")}</label>
          <textarea
            className="input min-h-32"
            placeholder={t("placeholder_enter")}
            value={fundraser?.investors_name || ""}
            onChange={(e) => setFundraser({ ...fundraser, investors_name: e.target.value })}
          />
        </div>

        <div className="my-5 flex justify-end">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FundraserModal;
