import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import ImageUploadSVG from "../../../assets/svg/upload-image.svg";
import { IoCheckbox } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin4Line } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { MdEdit } from "react-icons/md";

import MultiSelect from "../components/Multiselect";

import api from "@/services/api";
import Loader from "@/components/Loader";

const TeamModal = ({ setOpen, team = {}, setTeam }) => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <IoCloseSharp className="cursor-pointer text-xl" onClick={() => setOpen(false)} />
      </div>
      <div className="mt-5 flex flex-col gap-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">Nom</label>
          <input className="input" placeholder={"Prénom Nom"} value={team?.name || ""} onChange={(e) => setTeam({ ...team, name: e.target.value })} />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">Poste</label>
          <input className="input" placeholder={"Entrer"} value={team?.position || ""} onChange={(e) => setTeam({ ...team, position: e.target.value })} />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">LinkedIn</label>
          <input className="input" placeholder={"Entrer"} value={team?.linkedin || ""} onChange={(e) => setTeam({ ...team, linkedin: e.target.value })} />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">Photo</label>
          <div className="w-24 h-24">
            <ImageInput folder="/team" name="image" value={team?.image} onChange={(e) => setTeam({ ...team, image: e.target.value })} />
          </div>
        </div>

        <div className="mt-8 mb-14 flex justify-end">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}
      >
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

export default TeamModal;
