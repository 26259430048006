import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";

const Financials = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_step: "FINANCIALS" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/company/${id}`, { ...values, annonce_step: "FUNDS" });
      if (res.ok) {
        navigate(`/annonce/${id}/create/funds`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <h1 className="text-primary col-span-2 text-xl mb-6">Quels étaient les chiffres d'affaires ces trois dernières années ?</h1>
      <div className="space-y-6">
        <div className="space-y-2 col-span-1 flex-1">
          <label className="text-base font-medium" htmlFor="turnover_2022">
            2022
          </label>
          <div className="flex items-center">
            <input
              className="input flex-1 rounded-tr-none rounded-br-none z-10"
              type="number"
              name="turnover_2022"
              value={values.turnover_2 || 0}
              onChange={(e) => setValues({ ...values, turnover_2: e.target.value })}
            />
            <p className="text-sm p-3 border border-secondary border-l-0 bg-gray-100 rounded-tr-lg rounded-br-lg">€</p>
          </div>
        </div>
        <div className="space-y-2 col-span-1 flex-1">
          <label className="text-base font-medium" htmlFor="turnover_2023">
            2023
          </label>
          <div className="flex items-center">
            <input
              className="input flex-1 rounded-tr-none rounded-br-none z-10"
              type="number"
              name="turnover_2023"
              value={values.turnover_3 || 0}
              onChange={(e) => setValues({ ...values, turnover_3: e.target.value })}
            />
            <p className="text-sm p-3 border border-secondary border-l-0 bg-gray-100 rounded-tr-lg rounded-br-lg">€</p>
          </div>
        </div>
        <div className="space-y-2 col-span-1 flex-1">
          <label className="text-base font-medium" htmlFor="turnover_2024">
            Estimation pour 2024
          </label>
          <div className="flex items-center">
            <input
              className="input flex-1 rounded-tr-none rounded-br-none z-10"
              type="number"
              name="turnover_2024"
              value={values.turnover_4 || 0}
              onChange={(e) => setValues({ ...values, turnover_4: e.target.value })}
            />
            <p className="text-sm p-3 border border-secondary border-l-0 bg-gray-100 rounded-tr-lg rounded-br-lg">€</p>
          </div>
        </div>
      </div>

      <div className={`flex fixed bottom-0 right-0 bg-white items-center justify-between gap-4 w-[calc((100%-12.5rem)*4/5)] p-4`}>
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../location" className="empty-button w-48">
            Précédent
          </Link>
          <button type="button" className="primary-button w-48" onClick={handleNext}>
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default Financials;
