import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import SingleSelect from "../components/SingleSelect";
import MultiSelect from "../components/MultiSelect";

const StrategyCommunication = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-strategy-growth`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <MultiSelect question={questions?.find((q) => q.key === "acquisition_channels")} values={values} setValues={setValues} />

      <SingleSelect question={questions?.find((q) => q.key === "social_networks")} values={values} setValues={setValues} />

      <SingleSelect question={questions?.find((q) => q.key === "medias")} values={values} setValues={setValues} />

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">Website :</label>
        <input
          className="input"
          placeholder={t("placeholder_link")}
          value={values?.website_link || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, website_link: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">Linkedin :</label>
        <input
          className="input"
          placeholder={t("placeholder_link")}
          value={values?.linkedin_link || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, linkedin_link: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">Instagram :</label>
        <input
          className="input"
          placeholder={t("placeholder_link")}
          value={values?.instagram_link || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, instagram_link: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">Twitter :</label>
        <input
          className="input"
          placeholder={t("placeholder_link")}
          value={values?.twitter_link || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, twitter_link: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">Facebook :</label>
        <input
          className="input"
          placeholder={t("placeholder_link")}
          value={values?.facebook_link || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, facebook_link: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">AngelList :</label>
        <input
          className="input"
          placeholder={t("placeholder_link")}
          value={values?.angelList_link || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, angelList_link: e.target.value }))}
        />
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-strategy-ip`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StrategyCommunication;
