import React, { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";

import api from "../../services/api";

const ForgotPassword = () => {
  const [done, setDone] = useState(false);
  const [email, setEmail] = useState("");

  const handleSend = async () => {
    if (!validator.isEmail(email)) toast.error("Invalid email");

    try {
      const res = await api.post("/user/forgot-password", { email });
      if (res.ok) {
        setDone(true);
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div>
      {done ? (
        <>
          <div className="text-center text-3xl font-semibold text-black mb-12">E-mail envoyé</div>
          <div className="mb-8 text-gray-800 text-center">Un e-mail vous a été envoyé avec un lien pour réinitialiser votre mot de passe.</div>
        </>
      ) : (
        <>
          <h1 className="text-center text-3xl font-semibold text-black mb-12">Mot de passe oublié</h1>
          <div className="mb-8 text-gray-800 text-center">Entrez votre adresse e-mail ci-dessous et nous vous enverrons un lien pour réinitialiser votre mot de passe.</div>
          <div>
            <div className="space-y-2 w-full">
              <label className="text-base font-medium" htmlFor="email">
                E-mail
              </label>
              <input name="email" type="email" id="email" className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <button disabled={!email} className="primary-button w-full mt-10" type="submit" onClick={handleSend}>
              Réinitialiser le mot de passe
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
