import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import SingleSelect from "../components/SingleSelect";

const ImpactCse = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-impact-potential`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <SingleSelect question={questions?.find((q) => q.key === "expertise_in_sustainable_development")} values={values} setValues={setValues} />

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.provide_details_of_the_founders_expertise")}</label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.founders_CR_expertise || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, founders_CR_expertise: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.was_theme_of_social_responsibility_takeen_into_account")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="responsibility_yes"
              name="is_sustainable_considered_from_creation"
              onChange={() => setValues((prev) => ({ ...prev, is_sustainable_considered_from_creation: true }))}
              checked={values?.is_sustainable_considered_from_creation === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="responsibility_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="responsibility_no"
              name="is_sustainable_considered_from_creation"
              onChange={() => setValues((prev) => ({ ...prev, is_sustainable_considered_from_creation: false }))}
              checked={values?.is_sustainable_considered_from_creation === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="responsibility_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.have_you_carried_out_any_specific_action_in_terms_of_social_responsibility")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="specific_action_yes"
              name="has_sustainable_action_made"
              onChange={() => setValues((prev) => ({ ...prev, has_sustainable_action_made: true }))}
              checked={values?.has_sustainable_action_made === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="specific_action_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="specific_action_no"
              name="has_sustainable_action_made"
              onChange={() => setValues((prev) => ({ ...prev, has_sustainable_action_made: false }))}
              checked={values?.has_sustainable_action_made === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="specific_action_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.details_of_action_taken")}</label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.actions_taken_details || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, actions_taken_details: e.target.value }))}
        />
      </div>

      <SingleSelect question={questions?.find((q) => q.key === "environment_kpi")} values={values} setValues={setValues} />

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.please_provide_details_of_the_indicators_in_place")}</label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.environment_kpi_details || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, environment_kpi_details: e.target.value }))}
        />
      </div>

      <SingleSelect question={questions?.find((q) => q.key === "environment_criteria")} values={values} setValues={setValues} />

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.describe_ESG_criteria")}</label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.environment_criteria_details || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, environment_criteria_details: e.target.value }))}
        />
      </div>

      <div className="flex justify-between w-full">
        <Link to={`../form-finance-business-plan`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImpactCse;
