import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";

const FinanceReview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState({});

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-finance-business-plan`);
  };
  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.intangible_assets")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.intangible_assets}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, intangible_assets: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.tangible_fixed")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.tangible_fixed}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, tangible_fixed: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.stocks")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.stocks}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, stocks: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.customer_receivables")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.customer_receivables}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, customer_receivables: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.equity_capital")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.equity_capital}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, equity_capital: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.amout_of_shareholders")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.amout_of_shareholders}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, amout_of_shareholders: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.operating_liabilities")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.operating_liabilities}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, operating_liabilities: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.financial_debt")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.debt}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, debt: value.float }))}
          />
        </div>
      </div>

      <div className="flex justify-between w-full">
        <Link to={`../form-finance-funding`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinanceReview;
