import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";

const Ad = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "AD" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_status: "PENDING" });
      if (res.ok) {
        navigate("/annonce/success");
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <h1 className="text-primary text-xl">Créer votre annonce</h1>

      <div className="space-y-2 col-span-2">
        <label className="text-base font-medium" htmlFor="annonce_description">
          Description
        </label>
        <textarea
          rows={8}
          type="text"
          name="description"
          value={values.description || ""}
          onChange={(e) => setValues({ ...values, description: e.target.value })}
          className="input"
        />
      </div>
      <div className="space-y-2 col-span-2">
        <label className="text-base font-medium" htmlFor="annonce_description">
          Détails et particularités sur vos critères
        </label>
        <textarea rows={8} type="text" name="details" value={values.details || ""} onChange={(e) => setValues({ ...values, details: e.target.value })} className="input" />
      </div>

      <div className="flex right-0 bg-white items-center justify-between gap-4 w-full p-4">
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../team" className="empty-button w-48">
            Précédent
          </Link>
          <button type="button" className="primary-button w-48" onClick={handleSubmit}>
            Publier
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ad;
