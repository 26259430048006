import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { FaHourglassStart } from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { CALENDLY_COMMERCIAL } from "@/services/config";

import LogoPng from "@/assets/img/logo.png";
import Modal from "@/components/Modal";

import api from "@/services/api";
import useStore from "@/services/store";

const AD_TYPE = {
  buyer: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Acquéreur</p>,
  investor: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Investisseur</p>,
};

const View = () => {
  const { user } = useStore();
  const { id } = useParams();
  const [investor, setInvestor] = useState();
  const [showContactModal, setShowContactModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setInvestor(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  if (!investor) return <></>;

  return (
    <div className="py-6 px-6">
      <ContactInvestor investor={investor} show={showContactModal} handleClose={() => setShowContactModal(false)} />
      <div className="flex items-start justify-between gap-8">
        <div className="flex flex-col items-start gap-4 w-[75%] border border-secondary rounded-lg p-6 ml">
          <div className="flex justify-between w-full">
            <div className="space-y-6 w-[70%]">
              <div className="flex">{AD_TYPE[investor.investor_type]}</div>
              {investor.annonce_type === "buyer" ? (
                <h1 className="text-2xl font-semibold text-primary">{investor.buyer_title}</h1>
              ) : (
                <h1 className="text-2xl font-semibold text-primary">{investor.structure_name}</h1>
              )}
              <p className="text-black">{investor.description}</p>
            </div>
            <div className="flex items-center justify-center">
              {investor.logo && <img src={investor.logo} alt={`${investor.structure_name}'s profile`} className="w-36 h-36 rounded-md object-cover mb-3" />}
            </div>
          </div>

          <div className="border-t border-gray-300 border-dashed w-full" />

          <div className="flex justify-between">
            <div className="flex flex-col gap-2 w-[50%]">
              {investor.annonce_type === "buyer" ? (
                <>
                  <label className="text-primary text-l">Type de repreneur</label>
                  <div className="flex flex-wrap w-fit">
                    {investor.buyer_types.map((type, i) => (
                      <p key={i} className="text-m border border-primary rounded p-2 m-2">
                        {type}
                      </p>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <label className="text-primary text-l">Type d'investisseur</label>
                  <div className="flex flex-wrap w-fit">
                    {investor.types.map((type, i) => (
                      <p key={i} className="text-m border border-primary rounded p-2 m-2">
                        {type}
                      </p>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-2 w-[50%]">
              {investor.annonce_type === "buyer" ? (
                <>
                  <label className="text-primary text-l">Budget de reprise</label>
                  {investor.buyer_budget_min && (
                    <p className="text-m border border-primary rounded p-2 m-2">Min: {investor.buyer_budget_min.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
                  )}
                  {investor.buyer_budget_max && (
                    <p className="text-m border border-primary rounded p-2 m-2">Max: {investor.buyer_budget_max.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
                  )}
                </>
              ) : (
                <>
                  <label className="text-primary text-l">Hauteur des investissements</label>
                  <div className="flex flex-wrap w-fit">
                    {investor.amount_ranges.map((amount_range, i) => (
                      <p key={i} className="text-m border border-primary rounded p-2 m-2">
                        {amount_range}
                      </p>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>

          {investor.details?.length > 0 && (
            <>
              <div className="border-t border-gray-300 border-dashed w-full" />

              <div className="space-y-6 w-full">
                <h3 className="text-xl font-semibold text-primary">Détails</h3>
                <p className="text-black">{investor.details}</p>
              </div>
            </>
          )}

          {investor.team.length > 0 && (
            <>
              <div className="border-t border-gray-300 border-dashed w-full" />

              <div className="space-y-6 w-full">
                <h3 className="text-xl font-semibold text-primary">Équipe</h3>
                <div className="flex justify-center mt-12">
                  <div className="overflow-x-auto flex space-x-4">
                    {investor.team.map((member) => (
                      <ProfileCard key={member._id} imageUrl={member.image} name={member.name} position={member.position} linkedin={member.linkedin} />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          {(investor.website || investor.linkedin) && (
            <>
              <div className="border-t border-gray-300 border-dashed w-full" />

              <div className="space-y-6 w-full">
                <h3 className="text-xl font-semibold text-primary">Informations complémentaires</h3>
                <div className="flex flex-wrap items-center gap-x-10 gap-y-5">
                  {investor.website && (
                    <div className="flex flex-col gap-2">
                      <label className="text-primary text-l">Site web</label>
                      <a className="text-black" href={investor.website} target="_blank">
                        {investor.website}
                      </a>
                    </div>
                  )}
                  {investor.linkedin && (
                    <div className="flex flex-col gap-2">
                      <label className="text-primary text-l">LinkedIn</label>
                      <a className="text-black" href={investor.linkedin} target="_blank">
                        {investor.linkedin}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col items-start gap-4 w-[40%]">
          {user._id !== investor.user_id ? (
            <div className="border border-secondary rounded-lg p-6 ml w-full">
              <h2 className="text-primary text-lg">Plus d'informations ?</h2>
              <button type="button" className="primary-button mt-6 w-full" onClick={() => setShowContactModal(true)}>
                Entrer en relation
              </button>
            </div>
          ) : investor.annonce_status === "ACCEPTED" ? (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-primary text-lg">Votre annonce est en ligne</h2>
              <Link to={`/investisseurs/${id}/edit`} className="primary-button mt-6 w-full">
                Editer
              </Link>
              <p className="text-primary text-opacity-80 text-xs font-medium mt-4">(Votre annonce devra de nouveau être validée)</p>
            </div>
          ) : investor.annonce_status === "DRAFT" ? (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-primary text-lg">Votre annonce est en cours de création</h2>

              <Link to={`/investisseurs/${id}/create`} className="primary-button mt-6 w-full">
                Finaliser
              </Link>
            </div>
          ) : investor.annonce_status === "PENDING" ? (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-primary text-lg">Votre annonce est en attente de validation</h2>

              <Link to={`/investisseurs/${id}/edit`} className="primary-button mt-6 w-full">
                Editer
              </Link>
            </div>
          ) : (
            <div className="border border-secondary rounded-lg p-6 ml">
              <h2 className="text-red-800 text-lg">Votre annonce a été refusée</h2>
              <p className="text-black text-sm font-medium mt-4">Vous pouvez la modifier et la soumettre à nouveau</p>

              <Link to={`/investisseurs/${id}/edit`} className="primary-button mt-6 w-full">
                Editer
              </Link>
            </div>
          )}
          <div className="flex flex-col border border-secondary rounded-xl p-6 space-y-5 w-full">
            <h3 className="text-xl self-center font-semibold text-primary">Projets recherchés</h3>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <label className="text-primary text-l">Zones géographiques</label>
                <div className="flex flex-wrap w-fit">
                  {investor.regions.map((region, i) => (
                    <p key={i} className="text-m border border-primary rounded p-2 m-2">
                      {region}
                    </p>
                  ))}
                </div>
              </div>
              {investor.annonce_type === "buyer" ? (
                <div className="flex flex-col gap-2">
                  <label className="text-primary text-l">Maturité des projets</label>
                  <div className="flex flex-wrap w-fit">
                    {investor.buyer_maturities.map((maturity, i) => (
                      <p key={i} className="text-m border border-primary rounded p-2 m-2">
                        {maturity}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <label className="text-primary text-l">Maturité des projets</label>
                  <div className="flex flex-wrap w-fit">
                    {investor.maturities.map((maturity, i) => (
                      <p key={i} className="text-m border border-primary rounded p-2 m-2">
                        {maturity}
                      </p>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-2">
                <label className="text-primary text-l">Modèles économiques</label>
                <div className="flex flex-wrap w-fit">
                  {investor.businesses.map((business, i) => (
                    <p key={i} className="text-m border border-primary rounded p-2 m-2">
                      {business}
                    </p>
                  ))}
                </div>
              </div>
              {investor.annonce_type === "buyer" ? (
                <div className="flex flex-col gap-2">
                  <label className="text-primary text-l">Secteurs d'activités</label>
                  <div className="flex flex-wrap w-fit">
                    {investor.buyer_sectors.map((sector, i) => (
                      <p key={i} className="text-m border border-primary rounded p-2 m-2">
                        {sector}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <label className="text-primary text-l">Secteurs d'activités</label>
                  <div className="flex flex-wrap w-fit">
                    {investor.sectors.map((sector, i) => (
                      <p key={i} className="text-m border border-primary rounded p-2 m-2">
                        {sector}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactInvestor = ({ investor, show, handleClose }) => {
  const { user } = useStore();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [myCompanies, setCompanies] = useState([]);
  const [values, setValues] = useState({
    company_id: "",
    investor_id: investor._id,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.post("/company/search", { user_id: user._id, annonce_status: "ACCEPTED" });
        if (res.ok) {
          setCompanies(res.data);
        }
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
    };
    fetchData();
  }, []);

  const handleContact = async () => {
    setLoading(true);

    try {
      const res = await api.post("/discussion/investor", values);
      if (res.ok) setStep(2);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <div className="truncate px-10 py-4">
        {step === 0 && (
          <>
            <div className="flex flex-col items-center">
              <h3 className="text-2xl font-semibold mb-4">Envie de démarrer?</h3>
            </div>
            <div className="flex justify-center items-center mt-10">
              <div className="flex items-center justify-center relative w-28 h-28">
                <svg className="absolute" width="100%" height="100%">
                  <circle cx="50%" cy="50%" r="45%" fill="none" stroke="#ccc" strokeWidth="4" />
                </svg>
                <svg className="absolute" width="100%" height="100%">
                  <circle cx="50%" cy="50%" r="45%" fill="none" stroke="rgb(57 90 99)" strokeWidth="4" strokeDasharray="142" strokeDashoffset="0" strokeLinecap="round" />
                </svg>
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                  <img src={LogoPng} alt="Logo" className="w-10 h-10" />
                </div>
              </div>
            </div>

            <ul className="mt-10 list-none flex flex-col gap-5 list-inside">
              <li className="flex gap-3 items-center">
                <CgNotes />
                <span className="font-semibold">Étape 1.</span> Complétez intégralement votre annonce
              </li>
              <li className="flex gap-3 items-center">
                <FaHourglassStart />
                <span className="font-semibold">Étape 2.</span> Préparez vous avant de contacter les investisseurs
              </li>
            </ul>

            <div className="flex justify-between items-center mt-10">
              {myCompanies.length === 0 ? (
                <Link to="/my-annonce" className="primary-button text-sm" disabled={loading}>
                  Compléter mon annonce
                </Link>
              ) : (
                <div className="flex space-x-6">
                  <Link to="/my-annonce" className="transparent-button text-sm" disabled={loading}>
                    Compléter mon annonce
                  </Link>
                  <select className="select focus:ring-primary w-fit" value={values.company_id} onChange={(e) => setValues({ ...values, company_id: e.target.value })}>
                    <option value="" disabled>
                      Choisir
                    </option>
                    {myCompanies.map((a) => (
                      <option key={a._id} value={a._id}>
                        {a.annonce_title}
                      </option>
                    ))}
                  </select>

                  <button className="primary-button" disabled={values.company_id === "" || loading} onClick={() => setStep(1)}>
                    Continuer
                  </button>
                </div>
              )}
            </div>
          </>
        )}

        {step === 1 && (
          <>
            <div className="flex flex-col items-center">
              <h3 className="text-2xl font-semibold mb-4">Envie de démarrer?</h3>
            </div>
            <div className="flex justify-center items-center mt-10">
              <div className="flex items-center justify-center relative w-28 h-28">
                <svg className="absolute" width="100%" height="100%">
                  <circle cx="50%" cy="50%" r="45%" fill="none" stroke="#ccc" strokeWidth="4" />
                </svg>
                <svg className="absolute" width="100%" height="100%">
                  <circle cx="50%" cy="50%" r="45%" fill="none" stroke="rgb(57 90 99)" strokeWidth="4" strokeDasharray="350" strokeDashoffset="0" strokeLinecap="round" />
                </svg>
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                  <img src={LogoPng} alt="Logo" className="w-10 h-10" />
                </div>
              </div>
            </div>

            <ul className="mt-10 list-none flex flex-col gap-5 list-inside">
              <li className="flex gap-3 items-center text-green-600">
                <CgNotes />
                <span className="font-semibold">Étape 1.</span> Complétez intégralement votre annonce <IoIosCheckmarkCircle className="text-green-600 text-2xl" />
              </li>
              <li className="flex gap-3 items-center">
                <FaHourglassStart />
                <span className="font-semibold">Étape 2.</span> Préparez vous avant de contacter avec les investisseurs
              </li>
            </ul>

            <div className="flex justify-between items-center mt-10">
              <a href={CALENDLY_COMMERCIAL} target="_blank" rel="noreferrer" className="primary-button">
                Faire valoriser mon entreprise
              </a>
              <button className="primary-button" onClick={handleContact}>
                {loading ? "Contacting..." : "Contacter"}
              </button>
            </div>
          </>
        )}

        {step === 2 && (
          <div className="flex justify-center items-center flex-col">
            <IoIosCheckmarkCircle className="text-6xl text-green-600" />
            <h3 className="text-2xl font-semibold mb-2 mt-5">Réussite</h3>
            <p>Vous avez contacté avec succès l'investisseur.</p>

            <Link to="/discussion" className="primary-button mt-10 mb-3">
              Accéder aux discussions
            </Link>
          </div>
        )}
      </div>
    </Modal>
  );
};

const ProfileCard = ({ imageUrl, name, position, linkedin }) => {
  return (
    <a className="flex flex-col items-center border border-gray-300 p-4 rounded-lg w-52 shadow-md m-2" href={linkedin} target="_blank">
      <img src={imageUrl} alt={`${name}'s profile`} className="w-36 h-36 rounded-md object-cover mb-3" />
      <div className="text-lg font-bold mb-2">{name}</div>
      <div className="text-sm text-gray-600">{position}</div>
    </a>
  );
};

export default View;
