import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiCheckboxCircleFill, RiArrowDownSLine } from "react-icons/ri";

const Option = ({ item, getOptionLabel }) => {
  return (
    <Listbox.Option value={item} className="cursor-pointer select-none py-2 pl-3 pr-9">
      {({ selected }) => (
        <>
          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{getOptionLabel(item)}</span>
          {selected ? (
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
              <RiCheckboxCircleFill className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};

const Select = ({ items, selected, onSelect, getOptionLabel = (option) => option, renderOption = Option, className, placeholder = "Sélectionner" }) => {
  return (
    <div className="w-full">
      <Listbox value={selected} onChange={onSelect}>
        <div className="relative mt-1">
          <Listbox.Button
            className={`relative w-full text-left cursor-cursor appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md py-3 px-3 text-gray-700 leading-tight z-0 ${className}`}
          >
            <span className="block truncate">{selected ? getOptionLabel(selected) : placeholder}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <RiArrowDownSLine className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base absolute shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-10">
              {items.map((item, index) => (
                <Fragment key={index}>{renderOption({ item, getOptionLabel })}</Fragment>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Select;
