import React from "react";

const Modal = ({ show, children, onClose, className = "w-[95%] md:w-2/3" }) => {
  if (!show) return null;

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center">
      <div className="absolute inset-0 z-30 overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm" onClick={onClose} />

      <div className={`absolute left-1/2 top-1/2 z-40 -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white p-8 ${className}`}>{children}</div>
    </div>
  );
};
export default Modal;
