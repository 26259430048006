import React from "react";

import Signup from "../auth/Signup";

const Onboard = () => <Signup />;

export default Onboard;

/*

Kept because need to copy some elements of these files later

import { Route, Routes } from "react-router-dom";

import Create from "./Create";
import Investor from "./Investor";
import Entrepreneur from "./Entrepreneur";

<Routes>
    <Route path="/" element={<Create />} />
    <Route path="/investor" element={<Investor />} />
    <Route path="/entrepreneur" element={<Entrepreneur />} />
  </Routes>

*/
