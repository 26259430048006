import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { IoPersonCircle, IoExitOutline } from "react-icons/io5";

import useStore from "@/services/store";
import api from "@/services/api";

const TopBar = () => {
  return (
    <div className="w-full h-full flex items-center justify-end px-4">
      <Link to="/annonce/new" className="primary-button text-sm h-10 mr-4">
        Créer une annonce
      </Link>
      <ProfileMenu />
    </div>
  );
};

const ProfileMenu = () => {
  const { user, setUser } = useStore();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setUser(null);
    api.removeToken();
    navigate("/login");
  };

  return (
    <Menu as="div" className="relative flex items-center">
      <MenuButton>
        <span className="h-10 w-10 rounded-full border border-secondary bg-white flex items-center justify-center uppercase font-bold text-gray-800 text-sm">
          {user.first_name[0]}
          {user.last_name[0]}
        </span>
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom end"
        className="origin-top-right mt-2 rounded-md bg-black p-2 text-white transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <MenuItem>
          <Link to="/account" className="data-[focus]:bg-gray-600 bg-transparent text-white w-44 flex items-center justify-between rounded-md px-4 py-2 text-sm">
            Mon profil
            <IoPersonCircle className="ml-2 h-5 w-5 text-white" aria-hidden="true" />
          </Link>
        </MenuItem>
        <MenuItem>
          <button className="data-[focus]:bg-gray-600 bg-transparent text-white w-44 flex items-center justify-between rounded-md px-4 py-2 text-sm" onClick={handleLogout}>
            Se deconnecter
            <IoExitOutline className="ml-2 h-5 w-5 text-white" aria-hidden="true" />
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};

export default TopBar;
