import React from "react";
import Modal from "./Modal";

const ConfirmModal = ({ show, title, message, onConfirm, onCancel, cancelClassname = "transparent-button", confirmClassname = "primary-button" }) => {
  return (
    <Modal show={show} onClose={onCancel} className="w-1/2">
      <div className="flex flex-col items-center justify-center w-full px-16">
        <h1 className="text-xl font-bold">{title}</h1>
        <p className="text-center">{message}</p>
        <div className="flex justify-between mt-12 w-full">
          <button onClick={onCancel} className={cancelClassname}>
            Annuler
          </button>
          <button onClick={onConfirm} className={confirmClassname}>
            Confirmer
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
