import React from "react";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";

import SingleSelectBis from "../../components/SingleSelectBis";

const ShareholderModal = ({ setOpen, shareholder = {}, setShareholder, questions }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold"> {t("lable.list_of_main_shareholders")}</p>
        <IoCloseSharp className="cursor-pointer text-xl" onClick={() => setOpen(false)} />
      </div>
      <div className="mt-5 flex flex-col gap-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.name_of_shareholder")}</label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={shareholder?.name_of_shareholder || ""}
            onChange={(e) => setShareholder({ ...shareholder, name_of_shareholder: e.target.value })}
          />
        </div>

        <SingleSelectBis
          question={questions?.find((q) => q.key === "shareholder_profile")}
          value={shareholder.shareholder_profile}
          onChange={(value) => setShareholder({ ...shareholder, shareholder_profile: value })}
        />

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.number_of_shares_head")}</label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={shareholder?.number_of_shares_held || ""}
            onChange={(e) => setShareholder({ ...shareholder, number_of_shares_held: e.target.value })}
          />
        </div>

        <div className="mt-8 mb-14 flex justify-end">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareholderModal;
