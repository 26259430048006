export const TYPES = ["Professionel indépendant", "Startup émergente", "Entreprise de 1-19 employés", "Entreprise de 20+ employés"];

export const getEntrepriseTypeFromEffectif = (min) => {
  if (min < 2) return "Professionel indépendant";
  if (min < 20) return "Entreprise de 1-19 employés";
  if (min < 50) return "Entreprise de 20+ employés";
  return "";
};

export const NAF_SECTIONS = {
  "01": "Agriculture, sylviculture et pêche",
  "02": "Industries extractives",
  "03": "Industrie manufacturière",
  "05": "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
  "06": "Production et distribution d'eau ; assainissement, gestion des déchets et dépollution",
  "07": "Construction",
  "08": "Commerce ; réparation d'automobiles et de motocycles",
  "09": "Transports et entreposage",
  10: "Hébergement et restauration",
  11: "Activités des services financiers, hors assurance et caisses de retraite",
  12: "Services auxiliaires financiers et d'assurance",
  13: "Activités immobilières",
  14: "Activités spécialisées, scientifiques et techniques ; activités de services administratifs et de soutien",
  15: "Enseignement",
  16: "Activités pour la santé humaine",
  17: "Activités artistiques, sportives et récréatives",
  18: "Autres activités de services",
  19: "Industries manufacturières et extraction",
  20: "Production et distribution de gaz, d'électricité et d'eau",
  21: "Production et distribution d'eau",
  22: "Assainissement, gestion des déchets et dépollution",
  23: "Activités extractives",
  24: "Industrie chimique",
  25: "Industrie du caoutchouc et des plastiques",
  26: "Industrie textile",
  27: "Industrie de l'habillement",
  28: "Industrie du cuir et de la chaussure",
  29: "Industrie du bois et du papier",
  30: "Industrie de l'imprimerie et de l'édition",
  31: "Industrie de la fabrication de meubles ; industrie manufacturière n.c.a.",
  32: "Construction de machines et équipements n.c.a.",
  33: "Réparation et installation de machines et d'équipements",
  35: "Production et distribution d'électricité",
  36: "Captage, traitement et distribution d'eau",
  37: "Collecte, traitement et élimination des eaux usées ; épuration des eaux usées",
  38: "Collecte, traitement et élimination des déchets ; récupération",
  39: "Dépollution et autres services de gestion des déchets",
  41: "Construction de bâtiments",
  42: "Génie civil",
  43: "Travaux de construction spécialisés",
  45: "Commerce et réparation d'automobiles et de motocycles",
  46: "Commerce de gros, à l'exception des automobiles et des motocycles",
  47: "Commerce de détail, à l'exception des automobiles et des motocycles",
  49: "Transport terrestre et transport par conduites",
  50: "Transports par eau",
  51: "Transports aériens",
  52: "Entreposage et services auxiliaires des transports",
  53: "Activités de poste et de courrier",
  55: "Hébergement",
  56: "Restauration",
  58: "Édition",
  59: "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale",
  60: "Programmation et diffusion",
  61: "Télécommunications",
  62: "Programmation informatique, conseil et activités connexes",
  63: "Services d'information",
  64: "Activités des services financiers, hors assurance et caisses de retraite",
  65: "Assurance",
  66: "Activités auxiliaires de services financiers et d'assurance",
  68: "Activités immobilières",
  69: "Activités juridiques et comptables",
  70: "Activités des sièges sociaux ; conseil de gestion",
  71: "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques",
  72: "Recherche-développement scientifique",
  73: "Publicité et études de marché",
  74: "Autres activités spécialisées, scientifiques et techniques",
  75: "Activités vétérinaires",
  77: "Activités de location et location-bail",
  78: "Activités liées à l'emploi",
  79: "Activités des agences de voyage, voyagistes, services de réservation et activités connexes",
  80: "Enquêtes et sécurité",
  81: "Services relatifs aux bâtiments et aménagement paysager",
  82: "Activités administratives et autres activités de soutien aux entreprises",
  84: "Administration publique et défense ; sécurité sociale obligatoire",
  85: "Enseignement",
  86: "Activités pour la santé humaine",
  87: "Hébergement médico-social et social",
  88: "Action sociale sans hébergement",
  90: "Arts, spectacles et activités récréatives",
  91: "Bibliothèques, archives, musées et autres activités culturelles",
  92: "Organisation de jeux de hasard et d'argent",
  93: "Services funéraires",
  94: "Activités associatives",
  95: "Réparation d'ordinateurs et de biens personnels et domestiques",
  96: "Autres services personnels",
  97: "Activités des ménages en tant qu'employeurs de personnel domestique",
  98: "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
  99: "Activités des organisations et organismes extraterritoriaux",
  "00": "Activités des ménages en tant que producteurs de biens et services pour usage propre, non différenciées",
  // Ajoutez d'autres sections au besoin
};

export const getEntrepriseActivityFromNaf = (codeNaf) => {
  const section = codeNaf.substring(0, 2);
  return NAF_SECTIONS[section];
};
