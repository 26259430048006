import React from "react";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";

import SingleSelectBis from "../../components/SingleSelectBis";

const IncubatorModal = ({ setOpen, incubator = {}, setIncubator, questions }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold"> {t("lable.structure")}</p>
        <IoCloseSharp className="cursor-pointer text-xl" onClick={() => setOpen(false)} />
      </div>
      <div className="mt-5 flex flex-col gap-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.structure_name")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={incubator?.structure_name || ""}
            onChange={(e) => setIncubator({ ...incubator, structure_name: e.target.value })}
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.current_presense_in_the_structure")} <span className="ml-2 text-red-500">*</span>
          </label>
          <div className="flex gap-10 mt-3">
            <div className="flex gap-4 items-center justify-center">
              <input
                type="radio"
                id="other_entities_yes"
                name="structure_presence"
                onChange={() => setIncubator({ ...incubator, structure_presence: true })}
                checked={incubator?.structure_presence === true}
              />
              <label className="block text-gray-700 text-sm font-bold" htmlFor="other_entities_yes">
                {t("yes")}
              </label>
            </div>
            <div className="flex gap-4 items-center justify-center">
              <input
                type="radio"
                id="other_entities_no"
                name="structure_presence"
                onChange={() => setIncubator({ ...incubator, structure_presence: false })}
                checked={incubator?.structure_presence === false}
              />
              <label className="block text-gray-700 text-sm font-bold" htmlFor="other_entities_no">
                {t("no")}
              </label>
            </div>
          </div>
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.date_of_entry_into_said_structure")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input
            className="input"
            placeholder={t("placeholder_date")}
            value={incubator?.structure_entry_date || ""}
            onChange={(e) => setIncubator({ ...incubator, structure_entry_date: e.target.value })}
          />
        </div>

        <SingleSelectBis
          question={questions?.find((q) => q.key === "structure_profile")}
          value={incubator.structure_profile}
          onChange={(value) => setIncubator({ ...incubator, structure_profile: value })}
        />

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.structure_website")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={incubator?.structure_website || ""}
            onChange={(e) => setIncubator({ ...incubator, structure_website: e.target.value })}
          />
        </div>

        <div className="my-5 flex justify-end">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default IncubatorModal;
