import { useEffect, useState } from "react";

import api from "@/services/api";
import useStore from "@/services/store";
import { Link, useNavigate } from "react-router-dom";
import { CALENDLY_COMMERCIAL, CALENDLY_CEO } from "@/services/config";

import { PiBuildings, PiChartPieSlice, PiHandCoins } from "react-icons/pi";
import { LiaHandshakeSolid } from "react-icons/lia";
import { IoBarChartOutline } from "react-icons/io5";

const ANNONCE_TYPE = {
  cession: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Cession</p>,
  reprise: <p className="text-green-700 text-xs border border-yellow-600  p-1 rounded text-center">Reprise</p>,
  levee: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Levée</p>,
};

const ANNONCE_STATUS = {
  DRAFT: <p className="text-xs text-gray-500">En création</p>,
  PENDING: <p className="text-xs text-yellow-500">En attente</p>,
  ACCEPTED: <p className="text-xs text-green-500">Validée</p>,
  REJECTED: <p className="text-xs text-red-500">Refusée</p>,
};

const DISCUSSION_STATUS = {
  REFUSED: <span className="text-red-500 px-3 text-xs border border-red-500 p-1 rounded text-center">Refusée</span>,
  PENDING: <p className="text-xs text-yellow-600 border border-yellow-500 p-1 rounded text-center">En attente</p>,
  ACCEPTED: <p className="text-xs text-yellow-600 border border-yellow-500 p-1 rounded text-center">En attente</p>,
  ONGOING: <p className="text-xs text-green-500 border border-green-500 p-1 rounded text-center">En cours</p>,
  STOPPED: <p className="text-xs text-red-500 border border-red-500 p-1 rounded text-center">Arrêtée</p>,
};

const PICTURES = [
  "https://th.bing.com/th/id/R.891a1be588a0303ba8b24c1570a01bae?rik=h932gdTVK1psTg&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/OIP.O-kSeGcdCsgSYSKEsqHVdwHaE7?w=1280&h=853&rs=1&pid=ImgDetMain",
  "https://votrepolice.ch/wp-content/uploads/2020/05/entreprises-hd.png",
  "https://www.megahubhk.com/wp-content/uploads/2015/07/Final-Company-Background-Image.jpg",
  "https://www.digitalentrepreneur.fr/wp-content/uploads/2020/05/blog-entrepreneur.jpg",
  "https://www.novalum.fr/wp-content/uploads/2020/09/entreprise-corporate-1.jpg",
  "https://alliance-sciences-societe.fr/wp-content/uploads/2019/10/lorganisation-entreprise-optimiser-activites-comment.jpg",
  "https://th.bing.com/th/id/R.9f33af7b385e6dbca0ae3107c6762dd1?rik=A0g2SBfPXtv8Ag&riu=http%3a%2f%2fwww.hsm-services.fr%2fwp-content%2fuploads%2f2019%2f08%2fmanagement-indispensable-entreprise.jpg&ehk=4BneOG2CFGqM1UBIQ1ofPd1QxEx%2btadmjH3dqrajzoc%3d&risl=&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/R.9cb56bba1d502461114b7a1fc591a639?rik=lBuClpiOga3VVw&pid=ImgRaw&r=0",
  "https://th.bing.com/th/id/OIP.KJJcNR_SJt3Gw3YkSZUj3AHaE8?rs=1&pid=ImgDetMain",
  "https://th.bing.com/th/id/R.6d3fce6fb0c0898a96ce086887b0e194?rik=SUTaab9Pccyr3Q&pid=ImgRaw&r=0",
  "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/V1xq1AADx/videoblocks-group-of-business-people-meeting-around-table-20s-4k_sncaqskgw_thumbnail-1080_01.png",
  "https://www.singaporetranscription.com/wp-content/uploads/2017/01/meeting.jpg",
  "https://c.stocksy.com/a/5ka300/z9/856225.jpg",
  "https://fellow.app/wp-content/uploads/2021/07/2-1.jpg",
  "https://wallpapercave.com/wp/wp8656243.png",
  "https://wallpapercave.com/wp/wp8656441.jpg",
  "https://www.jcount.com/wp-content/uploads/2015/12/business-meeting-to-be-productive.jpg",
  "https://fellow.app/wp-content/uploads/2021/07/2-14.jpg",
  "https://1000decos.fr/wp-content/uploads/2021/03/paysagiste2.jpg",
  "https://th.bing.com/th/id/OIP.bmQ1Z83MU9PQTkvL2JAbgAHaE8?rs=1&pid=ImgDetMain",
];

const Home = () => {
  const { user } = useStore((state) => state);
  const [selectedType, setSelectedType] = useState("cession");

  const [annoncesCession, setAnnoncesCession] = useState([]);
  const [annoncesLevee, setAnnoncesLevee] = useState([]);
  const [annoncesBuyer, setAnnoncesBuyer] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCessions = async () => {
      try {
        const res = await api.post("/company/search", { annonce: true, annonce_type: "cession", annonce_status: "ACCEPTED" });
        if (res.ok) setAnnoncesCession(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchLevees = async () => {
      try {
        const res = await api.post("/company/search", { annonce: true, annonce_type: "levee", annonce_status: "ACCEPTED" });
        if (res.ok) setAnnoncesLevee(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchBuyers = async () => {
      try {
        const res = await api.post("/investor/search", { annonce: true, annonce_type: "buyer", annonce_status: "ACCEPTED" });
        if (res.ok) setAnnoncesBuyer(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCessions();
    fetchLevees();
    fetchBuyers();
  }, []);

  const handleMore = () => {
    if (selectedType === "buyer") navigate("/buyers");
    else if (selectedType === "cession") navigate("/annonce?type=cession");
    else navigate("/annonce?type=levee");
  };

  const handleNew = async (type) => {
    const values = {
      annonce: true,
      annonce_type: type,
      annonce_created_at: new Date(),
    };

    try {
      if (values.annonce_type === "buyer") {
        const res = await api.post("/investor", values);
        if (res.ok) {
          navigate(`/investisseurs/${res.data._id}/create`);
        }
      } else {
        const res = await api.post("/company", values);
        if (res.ok) {
          navigate(`/annonce/${res.data._id}/create`);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="p-6 space-y-6">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Bienvenue {user.first_name}</h1>
        <p className="text-xl text-gray-500">Que souhaitez-vous faire ?</p>
      </header>
      <div className="w-full flex items-stretch h-1/4 justify-between gap-10">
        <button onClick={() => handleNew("cession")} className="flex w-1/6 flex-col justify-center items-center gap-3 p-6 bg-slate-200 hover:bg-slate-300 rounded-xl">
          <PiBuildings size={40} />
          <p className="flex items-center justify-center font-semibold">Céder mon entreprise</p>
        </button>
        <Link to={"/annonce?type=cession"} className="flex w-1/6 flex-col justify-center items-center gap-3 p-6 bg-slate-200 hover:bg-slate-300 rounded-xl">
          <LiaHandshakeSolid size={40} />
          <div>
            <p className="flex items-center justify-center font-semibold">Acheter une</p>
            <p className="flex items-center justify-center font-semibold">entreprise</p>
          </div>
        </Link>
        <Link to={"/valuation/new"} className="flex w-1/6 flex-col justify-center items-center gap-3 p-6 bg-slate-200 hover:bg-slate-300 rounded-xl">
          <IoBarChartOutline size={40} />
          <div>
            <p className="flex items-center justify-center font-semibold">Évaluer mon </p>
            <p className="flex items-center justify-center font-semibold">entreprise</p>
          </div>
        </Link>
        <Link to={"/notation"} className="flex w-1/6 flex-col justify-center items-center gap-3 p-6 bg-slate-200 hover:bg-slate-300 rounded-xl">
          <PiChartPieSlice size={40} />
          <div>
            <p className="flex items-center justify-center font-semibold">Me faire</p>
            <p className="flex items-center justify-center font-semibold">référencer</p>
            <p className="flex items-center justify-center font-semibold">par l'UGAP</p>
          </div>
        </Link>
        <button onClick={() => handleNew("levee")} className="flex w-1/6 flex-col justify-center items-center gap-3 p-6 bg-slate-200 hover:bg-slate-300 rounded-xl">
          <PiHandCoins size={40} />
          <div>
            <p className="flex items-center justify-center font-semibold">Lever des fonds</p>
            <p className="flex items-center justify-center font-semibold">pour mon</p>
            <p className="flex items-center justify-center font-semibold">entreprise</p>
          </div>
        </button>
      </div>

      <div className="w-full flex items-stretch h-1/2 justify-between gap-6">
        <div className="w-2/3 flex flex-col border rounded-xl border-slate-200 p-5 gap-3">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold mb-3">Annonces</h2>
            <button onClick={handleMore} className="rounded-lg py-2 px-3 bg-slate-200 hover:bg-slate-300">
              Voir plus
            </button>
          </div>
          <p>Découvrez des annonces repreneurs et cédants</p>
          <div className="flex items-center gap-5 mb-3">
            <button
              onClick={() => setSelectedType("cession")}
              className={`bg-slate-200 rounded-md px-3 py-2 border ${selectedType === "cession" ? "border-primary" : "border-slate-200"} hover:border-primary`}
            >
              Cession
            </button>
            <button
              onClick={() => setSelectedType("buyer")}
              className={`bg-slate-200 rounded-md px-3 py-2 border ${selectedType === "buyer" ? "border-primary" : "border-slate-200"} hover:border-primary`}
            >
              Reprise
            </button>
            <button
              onClick={() => setSelectedType("levee")}
              className={`bg-slate-200 rounded-md px-3 py-2 border ${selectedType === "levee" ? "border-primary" : "border-slate-200"} hover:border-primary`}
            >
              Levée de fonds
            </button>
          </div>
          {selectedType === "buyer" ? (
            annoncesBuyer.length > 0 ? (
              <div className="grid grid-cols-3 grid-rows-1 gap-4">
                {annoncesBuyer.slice(0, 3).map((e, index) => (
                  <BuyerCard key={index} data={e} />
                ))}
              </div>
            ) : (
              <p className="text-center mt-10">Aucune annonce trouvée</p>
            )
          ) : selectedType === "cession" ? (
            annoncesCession.length > 0 ? (
              <div className="grid grid-cols-3 grid-rows-1 gap-4">
                {annoncesCession.slice(0, 3).map((e, index) => (
                  <AdCard key={index} data={e} />
                ))}
              </div>
            ) : (
              <p className="text-center mt-10">Aucune annonce trouvée</p>
            )
          ) : annoncesLevee.length > 0 ? (
            <div className="grid grid-cols-3 grid-rows-1 gap-4">
              {annoncesLevee.slice(0, 3).map((e, index) => (
                <AdCard key={index} data={e} />
              ))}
            </div>
          ) : (
            <p className="text-center mt-10">Aucune annonce trouvée</p>
          )}
        </div>
        <div className="w-1/3 flex flex-col items-stretch justify-between gap-6">
          <div className="flex flex-1 flex-col items-center justify-center gap-5 border rounded-xl border-slate-200 p-5">
            <h2 className="text-2xl font-semibold">Besoin d'informations ?</h2>
            <p className="text-l">Prenez rendez-vous avec un conseiller</p>
            <a href={CALENDLY_COMMERCIAL} target="_blank" rel="noreferrer" className="primary-button h-10 p-4 my-3">
              Prenez rendez-vous
            </a>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-5 border rounded-xl border-slate-200 p-5">
            <h2 className="text-2xl font-semibold">Devenir partenaire</h2>
            <div className="flex flex-col items-center">
              <p className="text-l">Vous souhaitez rejoindre notre réseau</p>
              <p className="text-l">ou collaborer ?</p>
            </div>
            <a href={CALENDLY_CEO} target="_blank" rel="noreferrer" className="primary-button h-10 p-4 my-3">
              Prenez rendez-vous
            </a>
          </div>
        </div>
      </div>

      {/* <section className="w-full grid grid-cols-2 gap-8 mt-10">
        <MyAnnonces />
        <Discussion />
      </section>
      <section>
        <LeveesDirectory />
      </section>
      <section>
        <CessionsDirectory />
      </section> */}
    </div>
  );
};

//</div> const Discussion = () => {
//   const { user } = useStore();
//   const [discussions, setDiscussions] = useState([]);

//   useEffect(() => {
//     get();
//   }, []);

//   async function get() {
//     try {
//       const res = await api.post("/discussion/search", { user_id: user._id });
//       if (!res.ok) return;
//       setDiscussions(res.data);
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   if (!discussions.length)
//     return (
//       <div>
//         <div className="flex items-center p-2 gap-4">
//           <h1 className="text-xl font-semibold">Mes mises en relation</h1>
//           <Link to={"/discussion"} className="primary-button h-8 px-2 text-xs">
//             Consulter mes mises en relation
//           </Link>
//         </div>
//         <div className="flex flex-col justify-center items-center border border-secondary rounded-lg gap-5 p-10 px-6">
//           <p className="text-base text-gray-500">Aucune mise en relation pour le moment</p>
//           <Link to="/annonce" className="primary-button text-sm h-10 mr-4">
//             Voir les annonces
//           </Link>
//         </div>
//       </div>
//     );

//   return (
//     <div>
//       <div className="flex items-center p-2 gap-4">
//         <h1 className="text-xl font-semibold">Mes mises en relation</h1>
//         <Link to={"/discussion"} className="primary-button h-8 px-2 text-xs">
//           Consulter mes mises en relation
//         </Link>
//       </div>
//       <div className="flex flex-col justify-start items-stretch border border-secondary rounded-lg divide-y">
//         {discussions.slice(0, 5).map((e, index) => {
//           const contact_name =
//             e.annonce_confidential || e.status !== "ONGOING"
//               ? "Confidentiel"
//               : user._id === e.user_to_id
//               ? e.user_from_first_name + " " + e.user_from_last_name
//               : e.user_to_first_name + " " + e.user_to_last_name;
//           return (
//             <Link key={index} to={`/discussion/${e._id}`} className="text-primary text-sm font-semibold hover:bg-slate-50 flex justify-between items-center gap-5 p-5 px-6">
//               <div className="w-[15%]">{ANNONCE_TYPE[e.annonce_type]}</div>
//               <div className="flex-1">{e.annonce_title || e.investor_name}</div>
//               <div className={`flex-1 ${e.annonce_status !== "ONGOING" ? "blur-sm" : ""}`}>{contact_name}</div>
//               <div className="w-[15%]">{DISCUSSION_STATUS[e.annonce_status]}</div>
//             </Link>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// const MyAnnonces = () => {
//   const { user } = useStore((state) => state);
//   const [myAnnonces, setMyAnnonces] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const res = await api.post("/company/search", { annonce: true, user_id: user._id });
//         if (res.ok) setMyAnnonces(res.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchData();
//   }, []);

//   if (!myAnnonces.length)
//     return (
//       <div>
//         <div className="flex items-center p-2 gap-4">
//           <h1 className="text-xl font-semibold">Mes annonces</h1>
//           <Link to={`/my-annonce`} className="primary-button h-8 px-2 text-xs">
//             Consulter mes annonces
//           </Link>
//         </div>
//         <div className="flex flex-col justify-center items-center border border-secondary rounded-lg gap-5 p-10 px-6">
//           <p className="text-base text-gray-500">Aucune annonce pour le moment</p>
//           <Link to="/annonce/new" className="primary-button text-sm h-10 mr-4">
//             Créer une annonce
//           </Link>
//         </div>
//       </div>
//     );

//   return (
//     <div>
//       <div className="flex items-center p-2 gap-4">
//         <h1 className="text-xl font-semibold">Mes annonces</h1>
//         <Link to={`/my-annonce`} className="primary-button h-8 px-2 text-xs">
//           Consulter mes annonces
//         </Link>
//       </div>
//       <div className="flex flex-col justify-start items-stretch border border-secondary rounded-lg divide-y">
//         {myAnnonces.slice(0, 5).map((e, index) => {
//           const link = e.annonce_status === "DRAFT" ? `/annonce/${e._id}/create` : `/annonce/${e._id}`;
//           const date = new Date(e.annonce_created_at || e.created_at);
//           const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
//           return (
//             <Link key={index} to={link} className="text-primary text-sm font-semibold hover:bg-slate-50 flex justify-between items-center gap-5 p-5 px-6">
//               <div className="w-[15%]">{ANNONCE_TYPE[e.annonce_type]}</div>
//               <div className="flex-1"> {e.annonce_title}</div>
//               <div className="flex-1">{formattedDate}</div>
//               <div className="w-[15%]">{ANNONCE_STATUS[e.annonce_status]}</div>
//             </Link>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// const LeveesDirectory = () => {
//   const [annonces, setAnnonces] = useState([]);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const res = await api.post("/company/search", { annonce: true, annonce_type: "levee", annonce_status: "ACCEPTED" });
//         if (res.ok) setAnnonces(res.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <div>
//       <div className="flex items-center p-2 gap-4">
//         <h1 className="text-xl font-semibold">Annonces de levée de fonds</h1>
//         <Link to={`/annonce?type=levee`} className="primary-button h-8 px-2 text-xs">
//           Voir plus
//         </Link>
//       </div>
//       {annonces.length > 0 ? (
//         <div className="grid grid-cols-3 grid-rows-1 gap-4">
//           {annonces.slice(0, 3).map((e, index) => (
//             <Card key={index} data={e} />
//           ))}
//         </div>
//       ) : (
//         <p className="text-center mt-10">Aucune levée de fond trouvée</p>
//       )}
//     </div>
//   );
// };

// const CessionsDirectory = () => {
//   const [annonces, setAnnonces] = useState([]);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const res = await api.post("/company/search", { annonce: true, annonce_type: "cession", annonce_status: "ACCEPTED" });
//         if (res.ok) setAnnonces(res.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <div>
//       <div className="flex items-center p-2 gap-4">
//         <h1 className="text-xl font-semibold">Annonces de cession</h1>
//         <Link to={`/annonce?type=cession`} className="primary-button h-8 px-2 text-xs">
//           Voir plus
//         </Link>
//       </div>
//       {annonces.length > 0 ? (
//         selectedType === "buyer" ? (
//           <div className="grid grid-cols-3 grid-rows-1 gap-4">
//             {annonces.slice(0, 3).map((e, index) => (
//               <BuyerCard key={index} data={e} />
//             ))}
//           </div>
//         ) : (
//           <div className="grid grid-cols-3 grid-rows-1 gap-4">
//             {annonces.slice(0, 3).map((e, index) => (
//               <AdCard key={index} data={e} />
//             ))}
//           </div>
//         )
//       ) : (
//         <p className="text-center mt-10">Aucune annonce trouvée</p>
//       )}
//     </div>
//   );
// };

const AdCard = ({ data }) => {
  const randomIndex = Math.floor(Math.random() * PICTURES.length);
  const randomPictureSrc = PICTURES[randomIndex];

  return (
    <Link to={`/annonce/${data._id}`} className="border border-secondary rounded-xl p-6 space-y-4 hover:border-primary">
      <img alt={data.annonce_title} src={data.annonce_image ? data.annonce_image : randomPictureSrc} className="w-full h-40 object-cover rounded-lg" />
      <div className="flex-1">
        <h1 className="text-base font-medium text-black">{data.annonce_title}</h1>
        <p className="text-sm text-gray-400">{data.company_sector}</p>
        <p className="text-sm text-gray-400">{data.main_business_model}</p>
        {data.turnover_2023 !== undefined && (
          <p className="text-sm text-gray-400">
            {/* Chiffre d'affaires 2023<span className="text-black ml-3">{data.turnover_2023?.toLocaleString("fr", { currency: "EUR", style: "currency" })}</span> */}
          </p>
        )}
        {data.annonce_selling_price !== undefined && (
          <p className="text-sm text-gray-400">
            {/* Prix de vente<span className="text-black ml-3">{data.annonce_selling_price?.toLocaleString("fr", { currency: "EUR", style: "currency" })}</span> */}
          </p>
        )}
        {data.annonce_financing_needs !== undefined && (
          <p className="text-sm text-gray-400">
            {/* Besoin de financement<span className="text-black ml-3">{data.annonce_financing_needs?.toLocaleString("fr", { currency: "EUR", style: "currency" })}</span> */}
          </p>
        )}
      </div>
    </Link>
  );
};

const BuyerCard = ({ data }) => {
  const randomIndex = Math.floor(Math.random() * PICTURES.length);
  const randomPictureSrc = PICTURES[randomIndex];

  return (
    <Link to={`/buyers/${data._id}`} className="border border-secondary rounded-xl p-6 space-y-4 hover:border-primary">
      <img alt={data.buyer_title} src={data.logo ? data.logo : randomPictureSrc} className="w-full h-40 object-cover rounded-lg" />
      <div className="flex-1">
        <h1 className="text-base font-medium text-black">{data.buyer_title}</h1>
        <p className="text-sm text-gray-400">{data.buyer_sectors[0]}</p>
        <p className="text-sm text-gray-400">{data.businesses[0]}</p>
      </div>
    </Link>
  );
};

// const Investors = ({}) => {
//   const randomSortInvestors = INVESTORS.sort(() => 0.5 - Math.random()).slice(0, 4);
//   return (
//     <div className="rounded-lg col-span-2">
//       <h1 className="text-xl font-semibold">Voir les investisseurs</h1>
//       <div className="grid grid-cols-4 gap-4 mt-4">
//         {randomSortInvestors.map((e, index) => (
//           <InvestorCard key={index} data={e} />
//         ))}
//       </div>
//       <div className="flex justify-center py-6">
//         <Link to="/investisseurs" className="empty-button">
//           Voir tous les investisseurs
//         </Link>
//       </div>
//     </div>
//   );
// };

// const InvestorCard = ({ data }) => {
//   const types = Object.entries(data)
//     .filter(([key, value]) => INVESTOR_TYPES.includes(key) && typeof value === "boolean" && value)
//     .map(([key, _]) => (
//       <span key={key} className="p-1 rounded bg-yellow-200 text-black text-xs">
//         {key}
//       </span>
//     ));
//   const maturities = Object.entries(data)
//     .filter(([key, value]) => MATURITIES.includes(key) && typeof value === "boolean" && value)
//     .map(([key, _]) => (
//       <span key={key} className="p-1 rounded bg-blue-200 text-black text-xs">
//         {key}
//       </span>
//     ));
//   const regions = Object.entries(data)
//     .filter(([key, value]) => REGIONS.includes(key) && typeof value === "boolean" && value)
//     .map(([key, _]) => (
//       <span key={key} className="p-1 rounded bg-green-200 text-black text-xs">
//         {key}
//       </span>
//     ));

//   const businesses = Object.entries(data)
//     .filter(([key, value]) => BUSINESSES.includes(key) && typeof value === "boolean" && value)
//     .map(([key, _]) => (
//       <span key={key} className="p-1 rounded bg-purple-200 text-black text-xs">
//         {key}
//       </span>
//     ));

//   return (
//     <Link to={`/investisseurs?investor=${data.Structure}`} className="w-full border border-secondary rounded-xl p-5 truncate cursor-pointer">
//       <h3 className="text-lg font-semibold mb-2 truncate">{data.Structure}</h3>
//       <div className="space-y-2">
//         <div className="flex flex-wrap items-center gap-x-3 gap-y-1">
//           {types.slice(0, 3)}
//           {types.length > 3 && <span className="p-1 rounded bg-yellow-200 text-black text-xs">+{types.length - 2}</span>}
//         </div>
//         <div className="flex flex-wrap items-center gap-x-3 gap-y-1">
//           {maturities.slice(0, 2)}
//           {maturities.length > 2 && <span className="p-1 rounded bg-blue-200 text-black text-xs">+{maturities.length - 2}</span>}
//         </div>
//         <div className="flex flex-wrap items-center gap-x-3 gap-y-1">
//           {regions.slice(0, 2)}
//           {regions.length > 2 && <span className="p-1 rounded bg-green-200 text-black text-xs">+{regions.length - 2}</span>}
//         </div>
//         <div className="flex flex-wrap items-center gap-x-3 gap-y-1">
//           {businesses.slice(0, 2)}
//           {businesses.length > 2 && <span className="p-1 rounded bg-purple-200 text-black text-xs">+{businesses.length - 2}</span>}
//         </div>
//       </div>
//     </Link>
//   );
// };

export default Home;
