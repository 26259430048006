import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine, RiCheckLine } from "react-icons/ri";

const DefaultOption = ({ option, selected, active, getLabel }) => (
  <div className={`${active ? "bg-gray-100" : "bg-transparent"} px-3 py-2 flex items-center justify-between`}>
    <p className={`block truncate text-sm ${selected ? "font-semibold" : "font-normal"}`}>{getLabel(option)}</p>
    {selected && <RiCheckLine />}
  </div>
);

const MultiSelect = ({
  options,
  value,
  onChange,
  disabled = false,
  nullable = true,
  placeholder = "Sélectionner une option",
  width = "w-64",
  position = "left-0 top-full",
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
  renderOption = DefaultOption,
}) => (
  <Listbox value={value} onChange={onChange} disabled={disabled} by={by} multiple={true}>
    {({ open }) => (
      <>
        <div className="relative w-full">
          <div className="w-full flex items-center bg-white justify-between border border-secondary rounded overflow-hidden">
            <Listbox.Button className="flex-1 text-left text-sm py-2 px-3">
              {value.length > 0 ? (
                <p className="truncate text-left text-sm text-primary">
                  {getLabel(value[0])} {value.length > 1 && `+${value.length - 1}`}
                </p>
              ) : (
                <p className="truncate text-black opacity-60 text-left text-sm">{placeholder}</p>
              )}

              {!value.length && !disabled && <div className="absolute inset-y-0 right-0 flex items-center pr-2">{open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>}
            </Listbox.Button>
            {value.length > 0 && !disabled && nullable && (
              <button className="flex items-center text-sm pr-2" onClick={() => onChange([])}>
                <RiCloseLine />
              </button>
            )}
          </div>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className={`absolute z-40 my-1 max-h-64 ${width} ${position} text-base overflow-y-scroll shadow rounded-md bg-white`}>
              {options.map((option, i) => (
                <Listbox.Option key={i} value={option} className="select-none list-none">
                  {({ active, selected }) => renderOption({ option, selected, active, getLabel })}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);

export default MultiSelect;
