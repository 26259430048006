import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { FaCheck } from "react-icons/fa6";
import { TbStack } from "react-icons/tb";

import { CALENDLY_COMMERCIAL } from "@/services/config";
import api from "@/services/api";

import Loader from "@/components/Loader";

import Logos from "@/assets/img/logos.png";

const Product = () => {
  const { investorId } = useParams();
  const { t } = useTranslation();
  const [investor, setInvestor] = useState(null);

  useEffect(() => {
    fetchData();
  }, [investorId]);

  const fetchData = async () => {
    try {
      const { ok, data } = await api.get(`/investor/${investorId}`);
      if (!ok) throw new Error("Error fetching investor data");
      setInvestor(data);
    } catch (error) {
      console.error("Error fetching investor data:", error);
    }
  };

  const handlePayment = async (plan) => {
    try {
      const { ok, data } = await api.post("/payment/buyer", { investorId: investor._id, plan });
      if (ok) window.location.href = data.url;
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors du traitement du paiement. Veuillez réessayer.");
    }
  };

  const RELATION = [
    t("product.buyer.realtion.one"),
    t("product.buyer.realtion.two"),
    t("product.buyer.realtion.three"),
    t("product.buyer.realtion.four"),
    t("product.buyer.realtion.five"),
  ];

  if (!investor) return <Loader />;

  return (
    <div className="px-8">
      <div className="flex flex-col items-center justify-center w-full">
        <div>
          <h1 className="text-center text-xl text-black font-semibold">Préparation sur mesure - Cession</h1>
        </div>
        <div className="py-4 space-y-4">
          <div className="w-full space-y-8">
            <div className="w-full">
              <h1 className="text-black text-center font-semibold">{t("product.title")}</h1>
            </div>
            <div className="grid grid-cols-3 gap-8 max-w-5xl">
              <button
                className={`border-2 ${
                  investor.buyer_plan === "RELATION" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                onClick={() => setInvestor({ ...investor, buyer_plan: "RELATION" })}
              >
                <div className="flex flex-col items-center gap-1 mb-2">
                  <TbStack className="text-3xl text-primary" />
                  <p className="text-sm font-semibold">{t("product.buyer.realtion.title")}</p>
                </div>
                <div className="flex gap-2 items-end text-black">
                  <h1 className="text-3xl font-semibold">{t("product.buyer.realtion.price")}</h1>
                  <p className="font-semibold">{t("product.certified.taxes")}</p>
                </div>
                <p className="font-semibold">{t("product.buyer.realtion.turnover")}</p>

                <div className="mt-4">
                  <p className="text-sm font-semibold">{t("product.certified.include")}</p>
                  <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                    {RELATION.map((item, i) => (
                      <li className="flex gap-2 text-sm" key={i}>
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>

                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <button onClick={() => handlePayment("RELATION")} className="primary-button mt-auto px-8">
                  {t("product.order")}
                </button>
              </button>
              <div className="col-span-2 flex flex-col justify-center items-center space-x-12">
                <span className="text-4xl text-black font-bold">+100</span>
                <p className="font-medium text-center">Entreprises accompagnées chaque année</p>
                <img src={Logos} alt="empty" className="w-full blur-sm" />
              </div>
            </div>
            <div className="flex flex-col item-center">
              <h2 className="text-lg text-black text-center underline font-medium">{t("product.bizzdev")}</h2>
              <div className="flex items-center justify-center mt-2">
                <a href={CALENDLY_COMMERCIAL} target="_blank" className="primary-button">
                  {t("product.contact")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
