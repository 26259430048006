import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import CurrencyInput from "react-currency-input-field";

const Innovation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState();

  const TECHNOLOGY_MATURITY_LEVEL = [
    t("lable.how_mature_are_these_technologies._1"),
    t("lable.how_mature_are_these_technologies._2"),
    t("lable.how_mature_are_these_technologies._3"),
  ];

  const PRODUCT_SOLUTION = [t("lable.does_ur_solution_use_exsiting_tech_or_create_new._1"), t("lable.does_ur_solution_use_exsiting_tech_or_create_new._2")];

  const TECHNOLOGY_DEVELOPMENT = [
    t("lable.stage_maturity_of_your_new_tech._1"),
    t("lable.stage_maturity_of_your_new_tech._2"),
    t("lable.stage_maturity_of_your_new_tech._3"),
    t("lable.stage_maturity_of_your_new_tech._4"),
    t("lable.stage_maturity_of_your_new_tech._5"),
    t("lable.stage_maturity_of_your_new_tech._6"),
    t("lable.stage_maturity_of_your_new_tech._7"),
    t("lable.stage_maturity_of_your_new_tech._8"),
    t("lable.stage_maturity_of_your_new_tech._9"),
  ];

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite lors de la mise à jour de la valeur");
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-finance-situation`);
  };
  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.is_ur_product_based_on_tech_or_innovation")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="set_up_yes"
              name="is_product_based_on_innovation"
              checked={values.is_product_based_on_innovation}
              onChange={() => setValues((prev) => ({ ...prev, is_product_based_on_innovation: true }))}
            />
            <label className="text-sm" htmlFor="set_up_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="set_up_no"
              name="is_product_based_on_innovation"
              checked={!values.is_product_based_on_innovation}
              onChange={() => setValues((prev) => ({ ...prev, is_product_based_on_innovation: false }))}
            />
            <label className="text-sm" htmlFor="set_up_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.does_ur_solution_use_exsiting_tech_or_create_new")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {PRODUCT_SOLUTION.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.solution_technology === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.solution_technology}
                onChange={() => setValues((prev) => ({ ...prev, solution_technology: option }))}
              />
              {values?.solution_technology === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.stage_maturity_of_your_new_tech")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {TECHNOLOGY_DEVELOPMENT.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.tech_maturity_stage === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.tech_maturity_stage}
                onChange={() => setValues((prev) => ({ ...prev, tech_maturity_stage: option }))}
              />
              {values?.tech_maturity_stage === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.is_your_offer_based_on_innovative_tech")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_yes"
              name="is_product_based_on_others_tech_innovation"
              checked={values.is_product_based_on_others_tech_innovation}
              onChange={() => setValues((prev) => ({ ...prev, is_product_based_on_others_tech_innovation: true }))}
            />
            <label className="text-sm" htmlFor="competing_solution_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_no"
              name="is_product_based_on_others_tech_innovation"
              checked={!values.is_product_based_on_others_tech_innovation}
              onChange={() => setValues((prev) => ({ ...prev, is_product_based_on_others_tech_innovation: false }))}
            />
            <label className="text-sm" htmlFor="competing_solution_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_mature_are_these_technologies")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-3">
          {TECHNOLOGY_MATURITY_LEVEL.map((option, i) => (
            <label
              key={i}
              className={`gap-2 items-center rounded p-2 flex cursor-pointer border text-sm ${
                values?.others_tech_maturity_stage === option ? "border-primary text-primary" : "border-secondary text-black"
              }`}
            >
              <input
                type="radio"
                className="hidden"
                name="customRadio"
                value={values?.others_tech_maturity_stage}
                onChange={() => setValues((prev) => ({ ...prev, others_tech_maturity_stage: option }))}
              />
              {values?.others_tech_maturity_stage === option ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4" />}
              {option}
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.enter_the_total_amount_spent_on_research_development")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.amount_spent_rnd ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.amount_spent_rnd || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, amount_spent_rnd: value.float }))}
          />
        </div>
      </div>

      <div>
        <label className="text-black font-semibold text-sm">
          {t("lable.is_the_developed_solution_patentable")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="solution_patentable_yes"
              name="is_solution_patentable"
              checked={values.is_solution_patentable}
              onChange={() => setValues((prev) => ({ ...prev, is_solution_patentable: true }))}
            />
            <label className="text-sm" htmlFor="solution_patentable_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="solution_patentable_no"
              name="is_solution_patentable"
              checked={!values.is_solution_patentable}
              onChange={() => setValues((prev) => ({ ...prev, is_solution_patentable: false }))}
            />
            <label className="text-sm" htmlFor="solution_patentable_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="text-black font-semibold text-sm">
          {t("lable.any_RandD_partnership")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="any_RandD_partnership_yes"
              name="has_rnd_partnership"
              checked={values.has_rnd_partnership}
              onChange={() => setValues((prev) => ({ ...prev, has_rnd_partnership: true }))}
            />
            <label className="text-sm" htmlFor="any_RandD_partnership_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="any_RandD_partnership_no"
              name="has_rnd_partnership"
              checked={!values.has_rnd_partnership}
              onChange={() => setValues((prev) => ({ ...prev, has_rnd_partnership: false }))}
            />
            <label className="text-sm" htmlFor="any_RandD_partnership_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="text-black font-semibold text-sm">
          {t("lable.personal_data")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="data_yes"
              name="is_collection_personal_data"
              checked={values.is_collection_personal_data}
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data: true }))}
            />
            <label className="text-sm" htmlFor="data_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="data_no"
              name="is_collection_personal_data"
              checked={!values.is_collection_personal_data}
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data: false }))}
            />
            <label className="text-sm" htmlFor="data_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="text-black font-semibold text-sm">
          {t("lable.personal_data_protection_procedures_set_up")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="protection_procedures_yes"
              name="is_collection_personal_data_proctected"
              checked={values.is_collection_personal_data_proctected}
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data_proctected: true }))}
            />
            <label className="text-sm" htmlFor="protection_procedures_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="protection_procedures_no"
              name="is_collection_personal_data_proctected"
              checked={!values.is_collection_personal_data_proctected}
              onChange={() => setValues((prev) => ({ ...prev, is_collection_personal_data_proctected: false }))}
            />
            <label className="text-sm" htmlFor="protection_procedures_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-strategy`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
