import { Route, Routes } from "react-router-dom";

import New from "./new";
import Product from "./product";
import View from "./view";

const Levee = () => {
  return (
    <Routes>
      <Route path="/new/*" element={<New />} />
      <Route path="/:companyId" element={<View />} />
      <Route path="/:companyId/product" element={<Product />} />
    </Routes>
  );
};

export default Levee;
