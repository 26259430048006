import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { CALENDLY_ANALYSE } from "../../../services/config";
import api from "@/services/api";
import Loader from "../../../components/Loader";

const Interview = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${companyId}`);
        if (!res.ok) throw new Error("Une erreur s'est produite lors de la récupération des données de l'entreprise.");
        setValues(res.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [companyId]);

  const handleNext = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, { valuation_step: "INTERVIEW_SCHEDULED" });
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de l'étape de l'évaluation.");
      navigate("../interview-scheduled");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <h1 className="font-semibold text-xl"> {t("interview.title")}</h1>
      <p className="text-base text-gray-500"> {t("interview.text")}</p>
      <div className="text-gray-500">
        <div className="flex items-center gap-4 mt-8">
          {isLoading && (
            <div className="w-full h-full flex items-center justify-center">
              <Loader />
            </div>
          )}
          <iframe
            style={{ display: isLoading ? "none" : "block" }}
            src={CALENDLY_ANALYSE}
            width="100%"
            height="900"
            frameBorder="0"
            onLoad={() => setIsLoading(false)}
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="flex justify-end w-full ">
        <button type="button" className="primary-button" onClick={handleNext}>
          J'ai pris mon rendez-vous
        </button>
      </div>
    </div>
  );
};

export default Interview;
