import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentTextIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";

import api from "@/services/api";
import UplodImageSVG from "@/assets/svg/upload-image.svg";
import UplodFileSVG from "@/assets/svg/upload-file.svg";
import ImageUploadSVG from "../../assets/svg/upload-image.svg";
import { MdDeleteOutline } from "react-icons/md";

import ConfirmModal from "@/components/ConfirmModal";
import Loader from "@/components/Loader";

const TYPES = ["Professionel indépendant", "Entreprise de 1-19 employés", "Startup émergente", "Entreprise de 20+ employés"];

const ACTIVITIES = [
  "Agroalimentaire",
  "Banque / Assurance",
  "Bois / Papier / Carton / Imprimerie",
  "BTP / Matériaux de construction",
  "Chimie / Parachimie",
  "Commerce / Négoce / Distribution",
  "Édition / Communication / Multimédia",
  "Électronique / Électricité",
  "Études et conseils",
  "Industrie pharmaceutique",
  "Informatique / Télécoms",
  "Machines et équipements / Automobile",
  "Métallurgie / Travail du métal",
  "Transports / Logistique",
  "Services aux entreprises",
  "Plastique / Caoutchouc",
  "Textile / Habillement / Chaussure",
];

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setCompany(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      if (company.annonce_status === "ACCEPTED") {
        const res = await api.put(`/company/${id}`, { ...company, annonce_status: "PENDING" });
        if (res.ok) {
          toast.success("Votre annonce a bien été modifiée");
        } else {
          toast.error("Une erreur est survenue");
        }
      } else {
        const res = await api.put(`/company/${id}`, company);
        if (res.ok) {
          toast.success("Votre annonce a bien été modifiée");
        } else {
          toast.error("Une erreur est survenue");
        }
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  const handleDelete = async () => {
    try {
      const res = await api.delete(`/company/${id}`);
      if (res.ok) {
        toast.success("Votre annonce a bien été supprimée");
        navigate("/my-annonce");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  if (!company)
    return (
      <div className="flex justify-center items-center">
        <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
        <p className="text-primary">Chargement...</p>
      </div>
    );

  return (
    <div className="p-8">
      <ConfirmModal
        show={showDeleteModal}
        title="Supprimer votre annonce"
        description="Êtes-vous sûr de vouloir supprimer votre annonce ?"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmClassname="red-button"
      />
      <header className="w-full flex items-center justify-between">
        <div className="space-y-2">
          <h1 className="text-3xl font-semibold text-black mb-2">Mon annonce {company.annonce_title}</h1>
          <p className="text-base text-gray-500">Modifier votre annonce</p>
        </div>
        <button type="button" className="primary-button" onClick={handleSave}>
          Enregistrer
        </button>
      </header>

      <div className="grid grid-cols-2 gap-8 my-10">
        <div className="px-4 space-x-6 col-span-2">
          <input
            type="checkbox"
            className="checkbox"
            id="hide_confidential"
            checked={company.annonce_confidential}
            onChange={(e) => setCompany({ ...company, annonce_confidential: e.target.checked })}
          />
          <label className="text-sm" htmlFor="hide_confidential">
            Afficher mon entreprise de manière anonyme sur la plateforme
          </label>
        </div>
        <div className="space-y-2 row-span-3 flex flex-col">
          <label htmlFor="title">Image de votre annonce</label>
          <div className="flex-1">
            <ImageInput name="image" value={company.annonce_image} onChange={(e) => setCompany({ ...company, annonce_image: e.target.value })} />
          </div>
        </div>
        <div className="space-y-2">
          <label htmlFor="title">Titre de l'annonce</label>
          <input
            type="text"
            name="title"
            value={company.annonce_title || ""}
            onChange={(e) => setCompany({ ...company, annonce_title: e.target.value })}
            className="w-full rounded-lg border px-3 py-2"
          />
        </div>
        <div className="space-y-2 row-span-2">
          <label htmlFor="description">Description</label>
          <textarea
            rows={4}
            type="text"
            name="description"
            value={company.annonce_description || ""}
            onChange={(e) => setCompany({ ...company, annonce_description: e.target.value })}
            className="w-full rounded-lg border px-3 py-2"
          />
        </div>
        <GeneralInformation values={company} setValues={setCompany} />
        <ComplementaryInfos values={company} setValues={setCompany} />
        <Finance values={company} setValues={setCompany} />
        {company.annonce_type === "levee" ? <Funds values={company} setValues={setCompany} /> : <SellingPrice values={company} setValues={setCompany} />}

        <Documents company={company} />
      </div>

      <div className="flex items-center justify-between gap-4">
        <button className="transparent-button w-48" onClick={() => navigate(-1)}>
          Retour
        </button>

        <button type="button" className="red-button w-48" onClick={() => setShowDeleteModal(true)}>
          Supprimer
        </button>
      </div>
    </div>
  );
};

const GeneralInformation = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-4">
      <h1 className="text-xl font-medium text-primary">Informations générales</h1>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Nom</p>
        <input className="input flex-1 py-2" value={values.company_name} onChange={(e) => setValues((prev) => ({ ...prev, company_name: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Type</p>
        <select className="select flex-1 py-2" value={values.company_category} onChange={(e) => setValues((prev) => ({ ...prev, company_category: e.target.value }))}>
          <option value="" disabled>
            Choisir la taille d'entreprise
          </option>
          {TYPES.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Siren</p>
        <input className="input flex-1 py-2" value={values.company_siren} onChange={(e) => setValues((prev) => ({ ...prev, company_siren: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Début d'activité</p>
        <input
          type="date"
          className="input flex-1 py-2"
          value={values.company_started_at ? new Date(values.company_started_at).toISOString().split("T")[0] : ""}
          onChange={(e) => setValues((prev) => ({ ...prev, company_started_at: e.target.value }))}
        />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Secteur d'activité</p>
        <select className="select flex-1 py-2" value={values.company_activity} onChange={(e) => setValues((prev) => ({ ...prev, company_activity: e.target.value }))}>
          <option value="" disabled>
            Choisir le secteur d'activité
          </option>
          {ACTIVITIES.map((activity) => (
            <option key={activity} value={activity}>
              {activity}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const ComplementaryInfos = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-4">
      <h1 className="text-xl font-medium text-primary">Informations complémentaires</h1>

      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Pays</p>
        <input className="input flex-1 py-2" value={values.country} onChange={(e) => setValues((prev) => ({ ...prev, country: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Ville</p>
        <input className="input flex-1 py-2" value={values.city} onChange={(e) => setValues((prev) => ({ ...prev, city: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Code postal</p>
        <input className="input flex-1 py-2" value={values.postal_code} onChange={(e) => setValues((prev) => ({ ...prev, postal_code: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Site web</p>
        <input className="input flex-1 py-2" value={values.company_website} onChange={(e) => setValues((prev) => ({ ...prev, company_website: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Linkedin</p>
        <input className="input flex-1 py-2" value={values.company_linkedin} onChange={(e) => setValues((prev) => ({ ...prev, company_linkedin: e.target.value }))} />
      </div>
    </div>
  );
};

const Finance = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-4">
      <h1 className="text-xl font-medium text-primary">Informations Financières</h1>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Chiffre d'affaire 2022</p>
        <input className="input flex-1 py-2" value={values.turnover_2} onChange={(e) => setValues((prev) => ({ ...prev, turnover_2: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Chiffre d'affaire 2023</p>
        <input className="input flex-1 py-2" value={values.turnover_3} onChange={(e) => setValues((prev) => ({ ...prev, turnover_3: e.target.value }))} />
      </div>
      <div className="flex gap-4 items-center">
        <p className="text-primary text-sm font-medium">Estimation chiffre d'affaire 2024</p>
        <input className="input flex-1 py-2" value={values.turnover_4} onChange={(e) => setValues((prev) => ({ ...prev, turnover_4: e.target.value }))} />
      </div>
    </div>
  );
};

const SellingPrice = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-4">
      <h1 className="text-xl font-medium text-primary">Prix de vente souhaité</h1>
      <div className="flex gap-4 items-center h-full">
        <input className="input flex-1 py-2" value={values.annonce_selling_price} onChange={(e) => setValues((prev) => ({ ...prev, annonce_selling_price: e.target.value }))} />
        <span className="text-base">€</span>
      </div>
    </div>
  );
};

const Funds = ({ values, setValues }) => {
  return (
    <div className="border border-secondary rounded-lg p-6 flex flex-col gap-4">
      <h1 className="text-xl font-medium text-primary">Montant de la levée de fond souhaité</h1>
      <div className="flex gap-4 items-center h-full">
        <input className="input flex-1 py-2" value={values.annonce_financing_needs} onChange={(e) => setValues((prev) => ({ ...prev, annonce_financing_needs: e.target.value }))} />
        <span className="text-base">€</span>
        <span className="text-base font-medium mx-3">-</span>
        <input className="input flex-1 py-2" value={values.annonce_dilution} onChange={(e) => setValues((prev) => ({ ...prev, annonce_dilution: e.target.value }))} />
        <span className="text-base">%</span>
      </div>
    </div>
  );
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}
      >
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

// const ImagesInput = ({ value, onChange }) => {
//   const [loading, setLoading] = useState(false);

//   const handleDeleteImage = async (index) => {
//     const newValue = value.filter((_, i) => i !== index);
//     onChange(newValue);
//   };

//   return (
//     <div className="w-full h-full rounded-xl overflow-hidden border border-secondary p-8">
//       <div className="grid grid-cols-4 gap-4">
//         {value &&
//           value.length > 0 &&
//           value.map((v, i) => (
//             <div className="relative flex items-center" key={i}>
//               <img src={v} alt="image" className="object-contain" />
//               <button className="absolute bg-secondary rounded-full p-1 top-0 right-0 hover:text-red-800" onClick={() => handleDeleteImage(i)}>
//                 <TrashIcon className="w-3 h-3" />
//               </button>
//             </div>
//           ))}
//       </div>
//       <label
//         htmlFor="images"
//         className="p-4 rounded-xl w-full h-full bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border hover:bg-secondary border-secondary border-dashed"
//       >
//         {loading ? (
//           <div className="flex items-center gap-2">
//             <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
//           </div>
//         ) : (
//           <img src={UplodImageSVG} alt="upload" className="w-8 h-8" />
//         )}
//       </label>
//       <input
//         id="images"
//         type="file"
//         className="hidden"
//         disabled={loading}
//         multiple
//         onChange={async (e) => {
//           setLoading(true);
//           const formData = new FormData();
//           for (let i = 0; i < e.target.files.length; i++) {
//             formData.append("files", e.target.files[i]);
//           }
//           formData.append("folder", "advertisement");
//           const { data } = await api.postFormData(`/file`, formData);
//           const newValue = [...value, ...data];
//           onChange(newValue);
//           setLoading(false);
//         }}
//       />
//     </div>
//   );
// };

const Documents = ({ company }) => {
  if (!company || !company._id) return null;

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { company_id: company._id });
        if (res.ok) setDocuments(res.data);
        else {
          toast.error("Une erreur est survenue");
        }
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [company._id]);

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      const { data } = await api.postFormData(`/document?company_id=${company._id}`, formData);
      setDocuments(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <section className="w-full border border-secondary rounded-lg p-8 col-span-2">
      <div className="w-full mt-4">
        {documents.length > 0 && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="flex-1 text-sm font-semibold">Documents</h3>
              <h3 className="w-1/4 text-sm font-semibold">Date d'ajout</h3>
              <h3 className="w-20 text-sm font-semibold">Voir</h3>
              <h3 className="w-20" />
            </div>
            <div className="divide-y divide-secondary">
              {documents.map((item, index) => (
                <DocRow key={index} item={item} index={index} setDocuments={setDocuments} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="flex justify-center w-full">
        <label
          htmlFor="documents"
          className="bg-white mt-4 p-4 rounded-xl w-full h-full hover:bg-gray-50 transition-all cursor-pointer flex flex-col items-center justify-center border border-secondary border-dashed"
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
              <p className="text-primary">Chargement...</p>
            </div>
          ) : (
            <img src={UplodFileSVG} alt="upload" className="w-8 h-8" />
          )}
          <p className="mt-4">Cliquez pour déposez un fichier</p>
        </label>
        <input id="documents" type="file" className="hidden" disabled={loading} multiple onChange={handleFileUpload} />
      </div>
    </section>
  );
};

const DocRow = ({ item, index, setDocuments }) => {
  const [file_name, setFileName] = useState(item.file_name);

  useEffect(() => {
    setFileName(item.file_name);
  }, [item]);

  useEffect(() => {
    if (file_name === item.file_name) return;

    const timeout = setTimeout(async () => {
      const res = await api.put(`/document/${item._id}`, { file_name });
      if (res.ok) {
        setDocuments((prev) => {
          const newValue = [...prev];
          newValue[index].file_name = file_name;
          return newValue;
        });
        toast.success("Nom du fichier mis à jour");
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [file_name]);

  const handleDeleteFile = async () => {
    const confirm = window.confirm("Voulez-vous vraiment supprimer ce fichier ?");
    if (!confirm) return;
    try {
      const res = await api.delete(`/document/${item._id}`);
      if (res.ok) setDocuments((prev) => prev.filter((doc) => doc._id !== item._id));
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div key={index} className="flex justify-between items-center py-2">
      <div className="flex-1 items-center flex">
        <DocumentTextIcon className="w-6 h-6 text-primary mr-4" />
        <input type="text" value={file_name} className="input" onChange={(e) => setFileName(e.target.value)} />
      </div>
      <div className="w-1/4">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
      <div className="w-20 flex justify-center">
        <a href={item.file_url} target="_blank" rel="noreferrer">
          <EyeIcon className="w-6 h-6 text-primary" />
        </a>
      </div>
      <div className="w-20 flex justify-center">
        <button type="button" className="transparent-button" onClick={handleDeleteFile}>
          <TrashIcon className="w-6 h-6 text-primary cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default Edit;
