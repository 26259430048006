import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";

const Location = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "LOCATION" });
      if (res.ok) {
        navigate("/home");
        toast.success("Votre annonce a bien été sauvegardée");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleNext = async () => {
    try {
      const res = await api.put(`/investor/${id}`, { ...values, annonce_step: "CRITERIA" });
      if (res.ok) {
        navigate(`/investisseurs/${id}/create/criteria`);
        toast.success("Vos données ont bien été sauvegardées");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col justify-between h-full w-full gap-6">
      <h1 className="text-primary text-xl">Où se situe votre structure ?</h1>
      <div className="space-y-2 flex-1">
        <label className="text-base font-medium" htmlFor="country">
          Pays
        </label>
        <input type="text" name="country" value={values.country} onChange={(e) => setValues({ ...values, country: e.target.value })} className="input" />
      </div>
      <div className="space-y-2 flex-1">
        <label className="text-base font-medium" htmlFor="postal_code">
          Code Postal
        </label>
        <input type="text" name="postal_code" value={values.postal_code} onChange={(e) => setValues({ ...values, postal_code: e.target.value })} className="input" />
      </div>
      <div className="space-y-2 flex-1">
        <label className="text-base font-medium" htmlFor="city">
          Ville
        </label>
        <input type="text" name="city" value={values.city} onChange={(e) => setValues({ ...values, city: e.target.value })} className="input" />
      </div>
      <div className="space-y-2 flex-1">
        <label className="text-base font-medium" htmlFor="address">
          Adresse
        </label>
        <input type="text" name="address" value={values.address} onChange={(e) => setValues({ ...values, address: e.target.value })} className="input" />
      </div>

      <div className="flex right-0 bg-white items-center justify-between gap-4 w-full p-4">
        <button type="button" className="empty-button w-48" onClick={handleSave}>
          Sauvegarder et quitter
        </button>
        <div className="flex items-center gap-4">
          <Link to="../company" className="empty-button w-48">
            Précédent
          </Link>
          <button type="button" className="primary-button w-48" onClick={handleNext}>
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default Location;
