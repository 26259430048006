import React from "react";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <div className="mt-32 flex justify-center">
      <div className="w-1/2 flex flex-col justify-center">
        <h1 className="text-2xl text-center font-semibold text-black mb-2">Votre annonce a bien été créée</h1>
        <p className="text-base text-center text-gray-500 mb-10">Nous allons examiner votre annonce et vous notifierons lorsqu'elle sera approuvée</p>
        <Link to="/my-annonce" className="primary-button">
          Voir mes annonces
        </Link>
      </div>
    </div>
  );
};

export default Success;
