import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import toast from "react-hot-toast";
import api from "@/services/api";

const Finish = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, { valuation_step: "DOCUMENTS" });
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la validation du formulaire");
      toast.success("Formulaire validé avec succès");
      navigate(`../documents`);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="w-full space-y-8 px-40">
      <h1 className="text-center text-2xl font-semibold my-8">{t("validate.title")}</h1>
      <p className="text-center">{t("validate.text")}</p>
      <div className="flex justify-center w-full">
        <button className="primary-button px-10" onClick={handleSubmit}>
          {t("validate.button")}
        </button>
      </div>
    </div>
  );
};

export default Finish;
