import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import SingleSelect from "../components/SingleSelect";
import Boolean from "../components/Boolean";

const OfferProduct = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [questions, setQuestions] = useState();
  const [values, setValues] = useState();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const res = await api.post("/question/search", { limit: 300, startup: true });
        if (res.ok) {
          setQuestions(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchQuestions();

    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          if (!res.data.plan) res.data.plan = "certified";
          setValues(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-offer-competition`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <SingleSelect question={questions?.find((q) => q.key === "maturity_stage")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "product_stage")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "stage_of_concept")} values={values} setValues={setValues} />
      <Boolean question={questions?.find((q) => q.key === "market_presence")} values={values} setValues={setValues} />
      {!values.market_presence && <SingleSelect question={questions?.find((q) => q.key === "time_to_market")} values={values} setValues={setValues} />}
      {!values.market_presence && <SingleSelect question={questions?.find((q) => q.key === "spending_left_until_market")} values={values} setValues={setValues} />}
      <SingleSelect question={questions?.find((q) => q.key === "stage_of_adoption")} values={values} setValues={setValues} />

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.problems_on_the_market")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.market_challenges || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, market_challenges: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.solution_to_these_problems")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.problem_solution || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, problem_solution: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.value_proposation")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className="input min-h-32"
          placeholder={t("placeholder_enter")}
          value={values?.value_proposation || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, value_proposation: e.target.value }))}
        />
      </div>

      <div className="flex justify-between w-full">
        <Link to={`../form-human-support`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferProduct;
