import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CheckIcon, DocumentTextIcon, EyeIcon, NoSymbolIcon, TrashIcon } from "@heroicons/react/24/outline";

import api from "@/services/api";
import useStore from "@/services/store";
import Loader from "@/components/Loader";
import UplodFileSVG from "@/assets/svg/upload-file.svg";
import Modal from "@/components/Modal";

// TODO REFACTO

const DataRoom = () => {
  const { user } = useStore();
  const [loading, setLoading] = useState(false);
  const [dataRooms, setDataRooms] = useState([]);
  const [dataRoom, setDataRoom] = useState();

  useEffect(() => {
    const fetchDataRooms = async () => {
      try {
        setLoading(true);
        const res = await api.get("/dataroom");
        if (res.ok) {
          setDataRooms(res.data);
          if (res.data.length > 0) setDataRoom(res.data[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
        setLoading(false);
      }
    };
    fetchDataRooms();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="h-full p-8 space-y-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Data Room</h1>
        <p className="text-base text-gray-500">Gérez et suivez les fichiers de vos annonces</p>
      </header>

      <div className="w-full flex justify-between items-center">
        <div className="flex items-center">
          <select
            className="select w-56 py-2"
            value={dataRoom?._id}
            onChange={(e) => {
              const a = dataRooms.find((a) => a._id === e.target.value);
              if (a) setDataRoom(a);
            }}
          >
            {dataRooms.map((e) => (
              <option key={e._id} value={e._id}>
                {e.company_name}
              </option>
            ))}
          </select>
          {dataRoom && dataRoom.owner_id === user._id && <span className="ml-4 border border-primary rounded py-1 px-4">Mon annonce</span>}
        </div>
      </div>

      <Documents dataRoom={dataRoom} />
      <Access dataRoom={dataRoom} />
    </div>
  );
};

const Documents = ({ dataRoom }) => {
  const { user } = useStore();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!dataRoom) return;
    const fetchData = async () => {
      try {
        const query = new URLSearchParams();
        query.append("data_room_id", dataRoom._id);
        const res = await api.get(`/document?${query.toString()}`);
        if (res.ok) setDocuments(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
    };
    fetchData();
  }, [dataRoom]);

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      const query = new URLSearchParams();
      query.append("data_room_id", dataRoom._id);
      const res = await api.postFormData(`/document?${query.toString()}`, formData);
      if (res.ok) setDocuments(res.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
      setLoading(false);
    }
  };

  const handleDeleteFile = async (item) => {
    const confirm = window.confirm("Voulez-vous vraiment supprimer ce fichier ?");
    if (!confirm) return;
    try {
      await api.delete(`/document/${item._id}`);
      const query = new URLSearchParams();
      query.append("data_room_id", dataRoom._id);
      const res = await api.get(`/document?${query.toString()}`);
      if (res.ok) setDocuments(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (!dataRoom) return null;
  if (loading) return <Loader />;

  return (
    <section className="w-full border border-secondary rounded-lg p-8">
      <h1 className="text-2xl font-semibold text-black mb-2">Mes fichiers</h1>
      <p className="text-base text-gray-500">Ajoutez et gérez les fichiers de votre annonce</p>
      <div className="w-full mt-6">
        {documents.length > 0 && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="flex-1 text-sm font-semibold pl-12">Documents</h3>
              <h3 className="w-1/4 text-sm font-semibold text-center">Date d'ajout</h3>
              <h3 className="w-20 text-sm font-semibold text-center">Voir</h3>
              <h3 className="w-20" />
            </div>
            <div className="divide-y divide-secondary">
              {documents.map((item, index) => (
                <DocRow
                  dataRoom={dataRoom}
                  key={index}
                  item={item}
                  index={index}
                  setDocuments={setDocuments}
                  onDelete={handleDeleteFile}
                  canEdit={dataRoom.owner_id === user._id}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {dataRoom.owner_id === user._id && (
        <div className="flex justify-center w-full">
          <label
            htmlFor="documents"
            className="bg-white mt-4 p-2 rounded-xl w-full h-full hover:bg-gray-50 transition-all cursor-pointer flex flex-col items-center justify-center border border-secondary border-dashed"
          >
            {loading ? (
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full animate-bounce" />
                Chargement...
              </div>
            ) : (
              <img src={UplodFileSVG} alt="upload" className="w-8 h-8" />
            )}
            <p className="mt-2">Cliquez pour déposez un fichier</p>
          </label>
          <input id="documents" type="file" className="hidden" disabled={loading} multiple onChange={handleFileUpload} />
        </div>
      )}
    </section>
  );
};

const DocRow = ({ item, index, setDocuments, onDelete, canEdit }) => {
  const [file_name, setFileName] = useState(item.file_name);

  useEffect(() => {
    setFileName(item.file_name);
  }, [item]);

  useEffect(() => {
    if (file_name === item.file_name) return;

    const timeout = setTimeout(async () => {
      const res = await api.put(`/document/${item._id}`, { file_name });
      if (res.ok) {
        setDocuments((prev) => {
          const newValue = [...prev];
          newValue[index].file_name = file_name;
          return newValue;
        });
        toast.success("Nom du fichier mis à jour");
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [file_name]);

  return (
    <div key={index} className="flex justify-between items-center py-2">
      <div className="flex-1 items-center flex">
        <DocumentTextIcon className="w-6 h-6 text-primary mr-4" />
        {canEdit ? (
          <input type="text" value={file_name} className="input py-2 focus-visible:ring-0" onChange={(e) => setFileName(e.target.value)} />
        ) : (
          <p className="font-medium">{file_name}</p>
        )}
      </div>
      <div className="w-1/4 text-center">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
      <div className="w-20 flex justify-center">
        <a href={item.file_url} target="_blank" rel="noreferrer">
          <EyeIcon className="w-6 h-6 text-primary" />
        </a>
      </div>
      <div className="w-20 flex justify-center">
        {canEdit && (
          <button type="button" className="transparent-button" onClick={() => onDelete(item)}>
            <TrashIcon className="w-6 h-6 text-primary cursor-pointer" />
          </button>
        )}
      </div>
    </div>
  );
};

const Access = ({ dataRoom }) => {
  const { user } = useStore();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);

  useEffect(() => {
    if (!dataRoom) return;
    fetchUsers();
  }, [dataRoom]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/user?data_room_id=${dataRoom._id}`);
      if (res.ok) setUsers(res.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
      setLoading(false);
    }
  };

  const handleAddUser = async (user) => {
    try {
      const newUsers = dataRoom.users || [];
      newUsers.push(user._id);
      const res = await api.put(`/dataroom/${dataRoom._id}`, { users: newUsers });
      if (res.ok) {
        setUsers((prev) => [...prev, user]);
        toast.success("Utilisateur ajouté à la data room");
      }
      setShowAccessModal(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleDeleteUser = async (user) => {
    try {
      const newUsers = dataRoom.users || [];
      const index = newUsers.indexOf(user._id);
      if (index === -1) return;
      newUsers.splice(index, 1);
      const res = await api.put(`/dataroom/${dataRoom._id}`, { users: newUsers });
      if (res.ok) {
        setUsers((prev) => prev.filter((u) => u._id !== user._id));
        toast.success("Utilisateur supprimé de la data room");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  if (!dataRoom) return null;
  if (dataRoom.owner_id !== user._id) return null;
  if (loading) return <Loader />;

  return (
    <section className="w-full border border-secondary rounded-lg p-8">
      <h1 className="text-2xl font-semibold text-black mb-2">Accès à la Data Room</h1>
      <p className="text-base text-gray-500">Surveillez et gérez les personnes ayant accès à la data room</p>
      <div className="w-full mt-6">
        {users.length > 0 && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="w-1/5 text-sm font-semibold">Nom</h3>
              <h3 className="w-1/5 text-sm font-semibold">Prénom</h3>
              <h3 className="flex-1 text-sm font-semibold">Email</h3>
              <h3 className="w-1/6 text-sm font-semibold">Role</h3>
              <h3 className="w-20" />
            </div>
            <div className="divide-y divide-secondary">
              {users.map((item, index) => (
                <UserRow key={index} item={item} onDelete={handleDeleteUser} canEdit={dataRoom.owner_id === user._id} />
              ))}
            </div>
          </>
        )}
      </div>
      {dataRoom.owner_id === user._id && (
        <div className="flex justify-center w-full">
          <button
            className="bg-white mt-4 p-2 rounded-xl w-full h-full hover:bg-gray-50 transition-all cursor-pointer flex flex-col items-center justify-center border border-secondary border-dashed"
            onClick={() => setShowAccessModal(true)}
          >
            Ajouter un utilisateur
          </button>
          <AddAccesModal show={showAccessModal} setShow={setShowAccessModal} onAdd={handleAddUser} />
        </div>
      )}
    </section>
  );
};

const UserRow = ({ item, index, onDelete, canEdit }) => {
  const { user } = useStore();
  return (
    <div key={index} className="flex justify-between items-center py-2">
      <div className="w-1/5">{item.last_name}</div>
      <div className="w-1/5">{item.first_name}</div>
      <div className="flex-1">{item.email}</div>
      <div className="w-1/6">{item.role}</div>
      <div className="w-20 flex justify-end">
        {user._id === item._id ? (
          <span className="text-gray-500">Propriétaire</span>
        ) : canEdit ? (
          <button type="button" className="transparent-button" onClick={() => onDelete(item)}>
            <TrashIcon className="w-6 h-6 text-primary cursor-pointer" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

const AddAccesModal = ({ show, setShow, onAdd }) => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        if (email.length === 0) return;
        setLoading(true);
        const query = new URLSearchParams();
        query.append("email", email);
        query.append("limit", "1");
        const res = await api.get(`/user?${query.toString()}`);
        if (res.ok && res.total === 1) setUser(res.data[0]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [email]);

  return (
    <Modal show={show} onClose={() => setShow(false)} className="w-[50vw] h-[60vh] flex flex-col">
      <h1 className="text-2xl font-semibold text-black mb-2">Ajouter un utilisateur</h1>
      <p className="text-base text-gray-500">Ajoutez un utilisateur à la data room</p>

      <div className="flex-1 flex flex-col justify-between mt-6">
        <div className="space-y-2">
          <label htmlFor="email" className="flex flex-col mt-6">
            Entrez l'email de l'utilisateur
          </label>

          <input type="email" id="email" className="input py-2 mb-4" value={email} onChange={(e) => setEmail(e.target.value)} />
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="border-l-primary animate-spin inline-block w-6 h-6 border-2 rounded-full" role="status" />
            </div>
          ) : user ? (
            <div className="flex justify-center items-center">
              <CheckIcon className="w-6 h-6 text-green-600" />
              <p className="ml-4 text-xs text-green-600">Utilisateur trouvé</p>
            </div>
          ) : email.length > 0 ? (
            <div className="flex justify-center items-center">
              <NoSymbolIcon className="w-6 h-6 text-gray-600" />
              <p className="ml-4 text-xs text-gray-600">Utilisateur non trouvé</p>
            </div>
          ) : null}
        </div>
        <div className="flex items-center justify-between mt-4">
          <button type="button" className="empty-button" onClick={() => setShow(false)}>
            Annuler
          </button>
          {user && (
            <button type="button" className="primary-button" onClick={() => onAdd(user)}>
              Ajouter
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DataRoom;
