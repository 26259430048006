import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import CurrencyInput from "react-currency-input-field";

const Situation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState({});

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite lors de la mise à jour de la valeur");
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-finance-business-plan`);
  };

  if (!values) return <Loader />;

  const total =
    (values?.funds_usage_recruitment || 0) +
    (values?.funds_usage_operational_costs || 0) +
    (values?.funds_usage_rnd || 0) +
    (values?.funds_usage_communication || 0) +
    (values?.funds_usage_managers_salary || 0) +
    (values?.funds_usage_other || 0);

  return (
    <div className="w-full space-y-8 px-40">
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.is_ur_business_already_profitable")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="profitable_yes"
              name="is_profitable"
              checked={values.is_profitable}
              onChange={() => setValues((prev) => ({ ...prev, is_profitable: true }))}
              onBlur={() => handleChange("is_profitable", true)}
            />
            <label className="text-sm" htmlFor="profitable_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="profitable_no"
              name="is_profitable"
              checked={!values.is_profitable}
              onChange={() => setValues((prev) => ({ ...prev, is_profitable: false }))}
              onBlur={() => handleChange("is_profitable", false)}
            />
            <label className="text-sm" htmlFor="profitable_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.current_cash_balance")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.cash_flow ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.cash_flow || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, cash_flow: value.float }))}
            onBlur={() => handleChange("cash_flow", values.cash_flow)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.current_monthly_cash_burn")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.current_monthly_cash_burn ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.current_monthly_cash_burn || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, current_monthly_cash_burn: value.float }))}
            onBlur={() => handleChange("current_monthly_cash_burn", values.current_monthly_cash_burn)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_recevied_non_repavable_grants")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="repavable_grants_yes"
              name="has_received_non_repavable_grants"
              checked={values.has_received_non_repavable_grants}
              onChange={() => setValues((prev) => ({ ...prev, has_received_non_repavable_grants: true }))}
              onBlur={() => handleChange("has_received_non_repavable_grants", true)}
            />
            <label className="text-sm" htmlFor="repavable_grants_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="repavable_grants_no"
              name="has_received_non_repavable_grants"
              checked={!values.has_received_non_repavable_grants}
              onChange={() => setValues((prev) => ({ ...prev, has_received_non_repavable_grants: false }))}
              onBlur={() => handleChange("has_received_non_repavable_grants", false)}
            />
            <label className="text-sm" htmlFor="repavable_grants_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.total_amount_of_subsidies_you_have_received")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.total_amount_subsidies_received ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.total_amount_subsidies_received || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, Numb: value.float }))}
            onBlur={() => handleChange("total_amount_subsidies_received", values.total_amount_subsidies_received)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.are_u_looking_for_funds")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="funds_yes"
              name="is_looking_for_funds"
              checked={values.is_looking_for_funds}
              onChange={() => setValues((prev) => ({ ...prev, is_looking_for_funds: true }))}
              onBlur={() => handleChange("is_looking_for_funds", true)}
            />
            <label className="text-sm" htmlFor="funds_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="funds_no"
              name="is_looking_for_funds"
              checked={!values.is_looking_for_funds}
              onChange={() => setValues((prev) => ({ ...prev, is_looking_for_funds: false }))}
              onBlur={() => handleChange("is_looking_for_funds", false)}
            />
            <label className="text-sm" htmlFor="funds_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.amounts_of_funds_need_to_cover_for_next_18_months")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.funds_needed ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.funds_needed || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, funds_needed: value.float }))}
            onBlur={() => handleChange("funds_needed", values.funds_needed)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.targeted_dilution_level")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.dilution_target ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.dilution_target || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, dilution_target: Number(e.target.value) }))}
            onBlur={() => handleChange("dilution_target", values.dilution_target)}
          />
          <span className="px-6 border-l"> % </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.intended_use_of_funds")}
          <span className="ml-2 text-red-500">*</span>
        </label>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.recruit")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_recruitment || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_recruitment: Number(e.target.value) }))}
            onBlur={() => handleChange("funds_usage_recruitment", values.funds_usage_recruitment)}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.operational_costs")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_operational_costs || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_operational_costs: Number(e.target.value) }))}
            onBlur={() => handleChange("funds_usage_operational_costs", values.funds_usage_operational_costs)}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.conducting")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_rnd || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_rnd: Number(e.target.value) }))}
            onBlur={() => handleChange("funds_usage_rnd", values.funds_usage_rnd)}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.operations")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_communication || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_communication: Number(e.target.value) }))}
            onBlur={() => handleChange("funds_usage_communication", values.funds_usage_communication)}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.managers")}</p>

          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_managers_salary || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_managers_salary: Number(e.target.value) }))}
            onBlur={() => handleChange("funds_usage_managers_salary", values.funds_usage_managers_salary)}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.other_uses")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_other || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_other: Number(e.target.value) }))}
            onBlur={() => handleChange("funds_usage_other", values.funds_usage_other)}
          />
          <span>%</span>
        </div>

        <div className="flex justify-between items-center mt-3">
          <p>{t("lable.total")}</p> <div>{total || 0} %</div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.when_finalize_the_fundraising")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className={`${values?.fundraising_finalized_at ? "border-2 border-primary text-primary filled-input" : "input"}`}
          placeholder={t("placeholder_enter")}
          value={values?.fundraising_finalized_at || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, fundraising_finalized_at: e.target.value }))}
          onBlur={() => handleChange("fundraising_finalized_at", values.fundraising_finalized_at)}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.percentage_of_share_you_are_willing_to_diverse_for_fundraising")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.share_percentage_divest ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.share_percentage_divest || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, share_percentage_divest: e.target.value }))}
            onBlur={() => handleChange("share_percentage_divest", values.share_percentage_divest)}
          />
          <span className="px-6 border-l"> % </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_raised_funds_from_pro_in_past")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="raised_funds_yes"
              name="has_raised_funds_in_past"
              checked={values.has_raised_funds_in_past}
              onChange={() => setValues((prev) => ({ ...prev, has_raised_funds_in_past: true }))}
              onBlur={() => handleChange("has_raised_funds_in_past", true)}
            />
            <label className="text-sm" htmlFor="raised_funds_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="raised_funds_no"
              name="has_raised_funds_in_past"
              checked={!values.has_raised_funds_in_past}
              onChange={() => setValues((prev) => ({ ...prev, has_raised_funds_in_past: false }))}
              onBlur={() => handleChange("has_raised_funds_in_past", false)}
            />
            <label className="text-sm" htmlFor="raised_funds_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_ever_been_valued")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="valued_yes"
              name="has_been_valued"
              checked={values.has_been_valued}
              onChange={() => setValues((prev) => ({ ...prev, has_been_valued: true }))}
              onBlur={() => handleChange("has_been_valued", true)}
            />
            <label className="text-sm" htmlFor="valued_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="valued_no"
              name="has_been_valued"
              checked={!values.has_been_valued}
              onChange={() => setValues((prev) => ({ ...prev, has_been_valued: false }))}
              onBlur={() => handleChange("has_been_valued", false)}
            />
            <label className="text-sm" htmlFor="valued_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.last_valuation")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.last_valuation ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.last_valuation || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, last_valuation: value.float }))}
            onBlur={() => handleChange("last_valuation", values.last_valuation)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.when_was_last_valuation")} <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className={`${values?.last_valuation_date ? "border-2 border-primary text-primary filled-input" : "input"}`}
          placeholder={t("placeholder_date")}
          value={values?.last_valuation_date || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, last_valuation_date: e.target.value }))}
          onBlur={() => handleChange("last_valuation_date", values.last_valuation_date)}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.context_and_who_conducted_it")} <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className={`${values?.last_valuation_context ? "border-2 border-primary text-primary filled-input" : "input min-h-32"}`}
          placeholder={t("placeholder_enter")}
          value={values?.last_valuation_context || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, last_valuation_context: e.target.value }))}
          onBlur={() => handleChange("last_valuation_context", values.last_valuation_context)}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.net_income_last_year")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.net_income_5 ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.net_income_5 || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, net_income_5: value.float }))}
            onBlur={() => handleChange("net_income_5", values.net_income_5)}
          />
        </div>
      </div>

      <p className=" text-lg font-semibold"> {t("lable.recent_company_balance_sheet")}</p>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.intangible_assets")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.intangible_assets ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.intangible_assets || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, intangible_assets: value.float }))}
            onBlur={() => handleChange("intangible_assets", values.intangible_assets)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.tangible_fixed")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.tangible_fixed ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.tangible_fixed || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, tangible_fixed: value.float }))}
            onBlur={() => handleChange("tangible_fixed", values.tangible_fixed)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.stocks")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.stocks ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.stocks || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, stocks: value.float }))}
            onBlur={() => handleChange("stocks", values.stocks)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.customer_receivables")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.customer_receivables ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.customer_receivables || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, customer_receivables: value.float }))}
            onBlur={() => handleChange("customer_receivables", values.customer_receivables)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.equity_capital")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.equity_capital ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.equity_capital || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, equity_capital: value.float }))}
            onBlur={() => handleChange("equity_capital", values.equity_capital)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.amout_of_shareholders")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.amout_of_shareholders ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.amout_of_shareholders || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, amout_of_shareholders: value.float }))}
            onBlur={() => handleChange("amout_of_shareholders", values.amout_of_shareholders)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.operating_liabilities")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.operating_liabilities ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.operating_liabilities || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, operating_liabilities: value.float }))}
            onBlur={() => handleChange("operating_liabilities", values.operating_liabilities)}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.financial_debt")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.debt ? "border-2 border-primary text-primary filled-input" : "flex gap-2 input justify-between items-center "}`}>
          <CurrencyInput
            intlConfig={{ locale: "fr-FR", currency: "EUR" }}
            decimalsLimit={2}
            className=" w-[90%] focus:outline-none tracking-wider"
            placeholder={t("placeholder_enter")}
            value={values?.debt || ""}
            onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, debt: value.float }))}
            onBlur={() => handleChange("debt", values.debt)}
          />
        </div>
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-innovation`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Situation;
