export const BUYER_TYPES = ["Entreprise", "Individuel", "Fond d'investissement", "Autre"];
export const BUYER_MATURITIES = ["Indépendant", "Startup", "TPE", "PME", "ETI", "GE"];

export const INVESTOR_TYPES = ["BA", "Club BA", "VC", "CVC", "Family Office", "Fonds Capital Developpement", "Crowdfunding"];
export const INVESTOR_BUSINESS_MODELS = ["Audience", "Commission", "E-commerce", "Manufacturing", "Performance", "Service", "Abonnement"];
export const INVESTOR_REGIONS = [
  "Auvergne-Rhône-Alpes",
  "Hauts-de-France",
  "Provence-Alpes-Côte d'Azur",
  "Grand Est",
  "Occitanie",
  "Normandie",
  "Nouvelle-Aquitaine",
  "Centre-Val de Loire",
  "Corse",
  "Bourgogne-Franche-Comté",
  "Bretagne",
  "Pays de la Loire",
  "Île-de-France",
  "Guadeloupe",
  "Martinique",
  "Guyane",
  "La Réunion",
  "Mayotte",
  "Nord-Ouest",
  "Nord-Est",
  "Sud-Ouest",
  "Sud-Est",
  "France",
  "Europe",
  "UK",
  "Autre",
];
export const INVESTOR_AMOUNT_RANGES = [
  "moins de 100,000€",
  "100,000€ à 500,000€",
  "500,000€ à 1,000,000€",
  "1,000,000 à 1,500,000€",
  "1,500,000€ à 2,000,000€",
  "plus de 2,000,000€",
];
export const INVESTOR_MATURITIES = ["Pre-seed", "Seed", "Pre-Serie A", "Serie A", "Serie B et +"];
export const INVESTOR_SECTORS_STARTUP = [
  "AdTech",
  "Bigdata",
  "BioTech",
  "PropTech",
  "FinTech",
  "Santé",
  "IoT",
  "Médias",
  "Transport",
  "Voyage",
  "CleanTech",
  "Données",
  "DeepTech",
  "Alimentation",
  "RH",
  "Cybersécurité",
  "Sécurité",
];

export const INVESTOR_SECTORS_PME = [
  "Biens de consommation",
  "Biens d’équipement",
  "Distribution",
  "Energie / Service publics",
  "Immobilier",
  "Industrie automobile",
  "Matériaux",
  "Média / Communication",
  "Santé / médical",
  "Services aux consommateurs",
  "Services aux entreprises",
  "Technologies de l’information",
  "Télécommunication",
  "Transport & logistique",
  "Services financiers",
];
