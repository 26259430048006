import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import Loader from "@/components/Loader";
import toast from "react-hot-toast";

const HumanSupport = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          if (!res.data.plan) res.data.plan = "certified";
          setValues(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la mise à jour de la valeur");
      toast.success("Valeur mise à jour avec succès");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNext = async () => {
    await handleChange();
    navigate(`../form-offer-product`);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.is_backup_by_expert")}</label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input type="radio" id="yes" name="expert_backed" onChange={() => setValues((prev) => ({ ...prev, expert_backed: true }))} checked={values?.expert_backed === true} />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input type="radio" id="no" name="expert_backed" onChange={() => setValues((prev) => ({ ...prev, expert_backed: false }))} checked={values?.expert_backed === false} />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>
      {values.expert_backed && (
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.how_many_experts")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={values?.experts_count}
            onChange={(e) => setValues((prev) => ({ ...prev, experts_count: e.target.value }))}
          />
        </div>
      )}

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.is_backup_by_scientific")}</label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="scientific_yes"
              name="sci_committee_support"
              onChange={() => setValues((prev) => ({ ...prev, sci_committee_support: true }))}
              checked={values?.sci_committee_support === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="scientific_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="scientific_no"
              name="sci_committee_support"
              onChange={() => setValues((prev) => ({ ...prev, sci_committee_support: false }))}
              checked={values?.sci_committee_support === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="scientific_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>
      {values.sci_committee_support && (
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.how_many_scientific")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={values?.scientific_count}
            onChange={(e) => setValues((prev) => ({ ...prev, scientific_count: e.target.value }))}
          />
        </div>
      )}

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.is_assisted_by_legal_expert")}</label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="legal_expert_yes"
              name="legal_assistance"
              onChange={() => setValues((prev) => ({ ...prev, legal_assistance: true }))}
              checked={values?.legal_assistance === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="legal_expert_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="legal_expert_no"
              name="legal_assistance"
              onChange={() => setValues((prev) => ({ ...prev, legal_assistance: false }))}
              checked={values?.legal_assistance === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="legal_expert_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.is_assisted_by_an_accountant")}</label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="accountant_yes"
              name="accountant_support"
              onChange={() => setValues((prev) => ({ ...prev, accountant_support: true }))}
              checked={values?.accountant_support === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="accountant_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="accountant_no"
              name="accountant_support"
              onChange={() => setValues((prev) => ({ ...prev, accountant_support: false }))}
              checked={values?.accountant_support === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="accountant_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="flex justify-end w-full">
        <div className="flex items-center gap-2">
          <button className="empty-button disabled:hover:bg-white px-10" onClick={handleChange}>
            {t("save")}
          </button>
          <button className="primary-button disabled:hover:bg-white px-10" onClick={handleNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HumanSupport;
