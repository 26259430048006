import { Link } from "react-router-dom";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";

const Values = ({ company, setCompany }) => {
  const isDataValid = () => company.valuation_game_equity_percentage && company.valuation_game_funds_sought;

  return (
    <div className="flex flex-col px-8 py-8 w-[42rem] h-full justify-center bg-white">
      <div className="flex items-center mb-2">
        <div className="w-24 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-1 mr-2">&times;</div>
        <div className="w-32 mb-4">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-8">
        <h1 className="text-xl text-primary font-semibold">Informations financières</h1>
        <div className="space-y-4 w-full">
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Quel est le montant des fonds recherchés ? (en euros)</p>
            <input
              type="number"
              name="valuation_game_funds_sought"
              placeholder="Saisissez le montant des fonds recherchés"
              value={company.valuation_game_funds_sought || ""}
              onChange={(e) => setCompany({ ...company, valuation_game_funds_sought: e.target.value })}
              className="input shadow"
            />
          </div>

          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Quel pourcentage du capital êtes-vous prêt à céder ?</p>
            <input
              type="number"
              name="valuation_game_equity_percentage"
              max={100}
              min={0}
              placeholder="Saisissez le pourcentage du capital à céder"
              value={company.valuation_game_equity_percentage || ""}
              onChange={(e) => setCompany({ ...company, valuation_game_equity_percentage: e.target.value })}
              className="input shadow"
            />
          </div>
        </div>

        <div className="flex gap-4">
          <Link to="/valuation-game/start-up/company" className="primary-button px-8">
            Précédent
          </Link>

          {isDataValid() ? (
            <Link to="/valuation-game/start-up/valuation" className="primary-button px-8">
              Suivant
            </Link>
          ) : (
            <button disabled className="primary-button px-8">
              Suivant
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Values;
