import { useTranslation } from "react-i18next";

const Boolean = ({ question, values, setValues }) => {
  const { t } = useTranslation();

  if (!question || !values) return null;
  return (
    <div>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {question.question_fr} <span className="ml-2 text-red-500">*</span>
      </label>
      <div className="flex gap-10 mt-3">
        <div className="flex gap-4 items-center justify-center">
          <input
            type="radio"
            id={`${question.key}_yes`}
            name={question.key}
            onChange={() => setValues((prev) => ({ ...prev, [question.key]: true }))}
            checked={values[question.key] === true}
          />
          <label className="block text-gray-700 text-sm font-bold" htmlFor={`${question.key}_yes`}>
            {t("yes")}
          </label>
        </div>
        <div className="flex gap-4 items-center justify-center">
          <input
            type="radio"
            id={`${question.key}_no`}
            name={question.key}
            onChange={() => setValues((prev) => ({ ...prev, [question.key]: false }))}
            checked={values[question.key] === false}
          />
          <label className="block text-gray-700 text-sm font-bold" htmlFor={`${question.key}_no`}>
            {t("no")}
          </label>
        </div>
      </div>
    </div>
  );
};

export default Boolean;
