import React from "react";

import { IoCheckbox } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin4Line } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { MdEdit } from "react-icons/md";

const MultiSelect = ({ options, values, onSelect }) => {
  if (!options.length) return null;
  return (
    <div>
      <div className="flex flex-wrap gap-3">
        {options.map((option, i) => (
          <label
            key={i}
            className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer ${
              (values || []).includes(option) ? "border-2 border-primary text-primary" : "border border-gray-300 text-gray-500"
            }`}
          >
            <input
              type="checkbox"
              className="hidden"
              value={option}
              onChange={() => onSelect((values || []).includes(option) ? values.filter((v) => v !== option) : [...values, option])}
            />
            {(values || []).includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4"></div>}
            {option}
          </label>
        ))}
      </div>
    </div>
  );
};

export default MultiSelect;
