import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import api from "@/services/api";

const LoginAs = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    api.setToken(searchParams.get("token"));
    navigate("/");
  }, []);
  return <></>;
};

export default LoginAs;
