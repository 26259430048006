import { Link } from "react-router-dom";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";

const Company = ({ company, setCompany }) => {
  const isDataValid = () => company.company_name && company.valuation_game_creation_year && company.valuation_game_sector && company.valuation_game_reason;

  return (
    <div className="flex flex-col px-8 py-8 w-[42rem] h-full justify-center bg-white">
      <div className="flex items-center mb-2">
        <div className="w-24 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-1 mr-2">&times;</div>
        <div className="w-32 mb-4">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-8">
        <h1 className="text-xl text-primary font-semibold">Informations générales d'entreprise</h1>
        <div className="space-y-4 w-full">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div className="space-y-2">
              <p className="text-gray-600 font-medium">Nom de l'entreprise</p>
              <input
                type="text"
                name="company_name"
                placeholder="Saisissez le nom de l'entreprise"
                value={company.company_name || ""}
                onChange={(e) => setCompany({ ...company, company_name: e.target.value })}
                className="input shadow"
              />
            </div>
            <div className="space-y-2">
              <p className="text-gray-600 font-medium">Année de création</p>
              <input
                type="text"
                name="valuation_game_creation_year"
                placeholder="2019"
                value={company.valuation_game_creation_year || ""}
                onChange={(e) => setCompany({ ...company, valuation_game_creation_year: e.target.value })}
                className="input shadow"
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Quel est votre secteur d'activité ?</p>
            <select
              name="valuation_game_sector"
              value={company.valuation_game_sector || ""}
              onChange={(e) => setCompany({ ...company, valuation_game_sector: e.target.value })}
              className="input shadow"
            >
              <option value="">Sélectionnez votre secteur</option>
              <option value="agroalimentaire">Agroalimentaire</option>
              <option value="automobile">Automobile / Naval / Aéronautique</option>
              <option value="consumer_goods">Biens et services aux consommateurs</option>
              <option value="commerce">Commerce</option>
              <option value="distribution">Distribution</option>
              <option value="energy_cleantech">Energie / Utilities / Cleantech</option>
              <option value="real_estate_construction">Immobilier / Construction</option>
              <option value="ecommerce_internet">Internet et e-commerce</option>
              <option value="software_it_services">Logiciels et services informatiques</option>
              <option value="raw_materials">Matières premières et transformées</option>
              <option value="industrial_products_services">Produits et services industriels</option>
              <option value="electronics_it_products">Produits électroniques et informatiques</option>
              <option value="pharma_biotech">Produits pharma, médicaux et biotech</option>
              <option value="health_beauty">Santé / Beauté</option>
              <option value="business_services">Service aux entreprises</option>
              <option value="telecom_media_advertising">Télécoms / Médias / Publicité</option>
              <option value="textile_luxury">Textile / Luxe / Décoration</option>
              <option value="tourism_hospitality">Tourisme / Hotellerie / Restauration / Loisirs</option>
              <option value="transport_logistics">Transport / Logistique</option>
            </select>
          </div>
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Raison de l'évaluation</p>
            <div className="flex gap-2 flex-wrap">
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Lever des fonds" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Lever des fonds" })}
              >
                <p className="text-sm text-center font-semibold">Lever des fonds</p>
              </button>
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Vendre mon entreprise" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Vendre mon entreprise" })}
              >
                <p className="text-sm text-center font-semibold">Vendre mon entreprise</p>
              </button>
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Acheter une entreprise" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Acheter une entreprise" })}
              >
                <p className="text-sm text-center font-semibold">Acheter une entreprise</p>
              </button>
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Autre" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Autre" })}
              >
                <p className="text-sm text-center font-semibold">Autre</p>
              </button>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <Link to="/valuation-game/type" className="primary-button px-8">
            Précédent
          </Link>

          {isDataValid() ? (
            <Link to="/valuation-game/sme/values" className="primary-button px-8">
              Suivant
            </Link>
          ) : (
            <button disabled className="primary-button px-8">
              Suivant
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Company;
