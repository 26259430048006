import React from "react";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";

import SingleSelectBis from "../../components/SingleSelectBis";

const ExecutiveModal = ({ setOpen, leader = {}, setLeader, questions }) => {
  const { t } = useTranslation();

  return (
    <div className="max-h-[85vh]">
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold"> {t("lable.executive")}</p>
        <IoCloseSharp className="cursor-pointer text-xl" onClick={() => setOpen(false)} />
      </div>

      <div className="my-6 flex flex-col gap-5">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("lable.leader")}
            <span className="ml-2 text-red-500">*</span>
          </label>
          <input className="input" placeholder={t("placeholder_enter")} value={leader?.leader || ""} onChange={(e) => setLeader({ ...leader, leader: e.target.value })} />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.is_leader_founder")}</label>
          <div className="flex gap-10 mt-3">
            <div className="flex gap-4 items-center justify-center">
              <input type="radio" id="yes" name="leaderFounder" onChange={() => setLeader({ ...leader, is_leader_founder: true })} checked={leader?.is_leader_founder === true} />
              <label className="block text-gray-700 text-sm font-bold" htmlFor="yes">
                {t("yes")}
              </label>
            </div>
            <div className="flex gap-4 items-center justify-center">
              <input type="radio" id="no" name="leaderFounder" onChange={() => setLeader({ ...leader, is_leader_founder: false })} checked={leader?.is_leader_founder === false} />
              <label className="block text-gray-700 text-sm font-bold" htmlFor="no">
                {t("no")}
              </label>
            </div>
          </div>
        </div>

        <SingleSelectBis
          question={questions?.find((q) => q.key === "leader_title")}
          value={leader.leader_title}
          onChange={(value) => setLeader({ ...leader, leader_title: value })}
        />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "commitment_of_leader")}
          value={leader.commitment_of_leader}
          onChange={(value) => setLeader({ ...leader, commitment_of_leader: value })}
        />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "training_type")}
          value={leader.training_type}
          onChange={(value) => setLeader({ ...leader, training_type: value })}
        />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "leader_diploma")}
          value={leader.leader_diploma}
          onChange={(value) => setLeader({ ...leader, leader_diploma: value })}
        />

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.institutions")}</label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={leader?.institutions || ""}
            onChange={(e) => setLeader({ ...leader, institutions: e.target.value })}
          />
        </div>

        <SingleSelectBis question={questions?.find((q) => q.key === "prof_exp")} value={leader.prof_exp} onChange={(value) => setLeader({ ...leader, prof_exp: value })} />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "industry_exp")}
          value={leader.industry_exp}
          onChange={(value) => setLeader({ ...leader, industry_exp: value })}
        />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "managerial_skills")}
          value={leader.managerial_skills}
          onChange={(value) => setLeader({ ...leader, managerial_skills: value })}
        />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "technical_experience")}
          value={leader.technical_experience}
          onChange={(value) => setLeader({ ...leader, technical_experience: value })}
        />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "sales_marketing_skills")}
          value={leader.sales_marketing_skills}
          onChange={(value) => setLeader({ ...leader, sales_marketing_skills: value })}
        />

        <SingleSelectBis question={questions?.find((q) => q.key === "financial")} value={leader.financial} onChange={(value) => setLeader({ ...leader, financial: value })} />

        <SingleSelectBis
          question={questions?.find((q) => q.key === "entrepreneurial")}
          value={leader.entrepreneurial}
          onChange={(value) => setLeader({ ...leader, entrepreneurial: value })}
        />

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.linkedin_profile")}</label>
          <input
            className="input"
            placeholder={t("placeholder_enter")}
            value={leader?.linkedin_profile || ""}
            onChange={(e) => setLeader({ ...leader, linkedin_profile: e.target.value })}
          />
        </div>

        <div className="flex justify-end mt-10 mb-20">
          <div className="flex gap-4">
            <button className="empty-button px-10" onClick={() => setOpen(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutiveModal;
