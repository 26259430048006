import React from "react";
import { Route, Routes } from "react-router-dom";

import List from "./List";
import New from "./New";
import Create from "./create";
import Success from "./Success";
import View from "./View";
import Edit from "./Edit";

const Advertisement = () => (
  <Routes>
    <Route path="/" element={<List />} />
    <Route path="/new" element={<New />} />
    <Route path="/success" element={<Success />} />
    <Route path="/:id/create/*" element={<Create />} />
    <Route path="/:id/edit" element={<Edit />} />
    <Route path="/:id" element={<View />} />
  </Routes>
);

export default Advertisement;
