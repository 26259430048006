import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Listbox } from "@headlessui/react";
import toast from "react-hot-toast";

import Loader from "@/components/Loader";
import Select from "@/components/Select";
import api from "@/services/api";
import { PAPPERS_KEY } from "@/services/config";
import useStore from "@/services/store";
import { NAF_SECTIONS, TYPES, getEntrepriseActivityFromNaf, getEntrepriseTypeFromEffectif as getEntrepriseCategoryFromEffectif } from "@/utils";

const CompanyForm = ({ company, setCompany }) => {
  const [myCompanies, setMyCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { user } = useStore();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { ok, data } = await api.post("/company/search", { user_id: user._id });
      if (!ok) throw new Error("Error fetching data");
      setMyCompanies(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStart = async () => {
    try {
      const { ok, data } = await api.put(`/company/${selectedCompany._id}`, {
        valuation: true,
        valuation_type: company.valuation_type,
        valuation_step: company.valuation_step,
        valuation_status: company.valuation_status,
      });
      if (ok) handlePayment(data);
      else toast.error("Erreur lors de la mise à jour de l'entreprise. Veuillez réessayer.");
    } catch (error) {
      console.error(error);
      toast.error("Erreur inattendue lors de la mise à jour de l'entreprise. Veuillez réessayer.");
    }
  };

  const handleCreate = async () => {
    try {
      const { ok, data } = await api.post("/company", company);
      if (ok) handlePayment(data);
      else toast.error("Erreur lors de la création de l'entreprise. Veuillez réessayer.");
    } catch (error) {
      console.error(error);
      toast.error("Erreur inattendue lors de la création de l'entreprise. Veuillez réessayer.");
    }
  };

  const handlePayment = async (valuation) => {
    let paymenetURL = "";
    if (valuation.valuation_type === "START-UP") {
      if (valuation.valuation_plan === "ACCELERATED") paymenetURL = "/payment/accelerated-start-up";
      else paymenetURL = "/payment/certified-start-up";
    } else if (valuation.valuation_plan === "ACCELERATED") paymenetURL = "/payment/accelerated-sme";
    else paymenetURL = "/payment/certified-sme";

    try {
      const { ok, data } = await api.post(paymenetURL, { companyId: valuation._id });
      if (ok) window.location.href = data.url;
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors du traitement du paiement. Veuillez réessayer.");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="max-w-3xl mx-auto">
      {myCompanies.length > 0 && (
        <>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-xl font-semibold text-black mb-8 text-center">Démarrez une notation avec une start-up existante</h2>
            <Select
              className="py-4"
              items={myCompanies}
              selected={selectedCompany}
              onSelect={(v) => setSelectedCompany(v)}
              getOptionValue={(item) => item._id}
              getOptionLabel={(item) => item.company_name}
              renderOption={CompanyOption}
            />
          </div>
          {selectedCompany && (
            <div className="w-full my-2 flex justify-center items-center gap-4">
              <button className="primary-button" onClick={handleStart}>
                Continuer
              </button>
            </div>
          )}
          <div className="flex items-center flex-row my-4">
            <div className="border-t border-primary w-2/5" />
            <div className="w-1/5 flex items-center justify-center">
              <div className=" w-16 h-16 border border-primary  text-primary rounded-full flex items-center justify-center">OU</div>
            </div>
            <div className="border-t border-primary w-2/5" />
          </div>
        </>
      )}

      <CompanyTextForm values={company} setValues={setCompany} />

      <div className="w-full flex justify-center items-center gap-4">
        <div className="flex justify-between w-1/2 my-10">
          <Link to="../overview" className="empty-button disabled:hover:bg-white px-10">
            {t("previous")}
          </Link>
          {company.company_name && company.company_category && company.company_activity && (
            <button onClick={handleCreate} className="primary-button px-10">
              Continuer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const CompanyTextForm = ({ values, setValues }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (!search) return setCompanies([]);

    const timeout = setTimeout(() => {
      if (search.length < 1) return;
      fetchPappersByName();
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  const fetchPappersByName = async () => {
    setLoading(true);

    try {
      const url = `https://api.pappers.fr/v2/recherche?q=${encodeURIComponent(search)}&api_token=${PAPPERS_KEY}`;
      const response = await fetch(url, { "content-type": "application/json" });
      const data = await response.json();
      setCompanies(data.resultats || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPappersBySiren = async (siren) => {
    const apiUrl = `https://api.pappers.fr/v2/entreprise?siren=${siren}&api_token=${PAPPERS_KEY}`;
    const headers = { "content-type": "application/json" };
    try {
      const response = await fetch(apiUrl, { headers });
      const data = await response.json();
      setValues({
        ...values,
        metadata: data,
        company_name: data.nom_entreprise,
        company_category: getEntrepriseCategoryFromEffectif(data.effectif_min),
        company_activity: getEntrepriseActivityFromNaf(data.code_naf),
        company_siren: data.siren,
        company_started_at: new Date(data.date_creation),
        city: data.siege?.ville || "",
        postal_code: data.siege?.code_postal || "",
        country: data.siege?.pays || "",
        turnover_2022: data.finances.filter((data) => data.annee === 2022)[0]?.chiffre_affaires,
        turnover_2023: data.finances.filter((data) => data.annee === 2023)[0]?.chiffre_affaires,
        turnover_2024: data.finances.filter((data) => data.annee === 2024)[0]?.chiffre_affaires,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOptionClick = async (data) => {
    await fetchPappersBySiren(data.siren);
    toast.success("Certaines données de l'entreprise ont été pré-remplies. Veuillez vérifier les informations.");
    setCompanies([]);
    setSearch("");
  };

  return (
    <section className="h-full flex flex-col items-center gap-6">
      <h2 className="text-xl font-semibold text-center text-black mb-8">Démarrez une évaluation avec une nouvelle entreprise</h2>
      <div className="space-y-2 w-full">
        <p className="text-center text-black font-semibold">Nom de l'entreprise</p>
        <div className="relative w-full">
          <div className="relative mt-1">
            <input
              type="text"
              name="company_name"
              className="w-full input"
              placeholder="ACME"
              required
              value={values.company_name}
              onChange={(e) => setValues({ ...values, company_name: e.target.value })}
              onInput={(e) => setSearch(e.target.value)}
            />
          </div>
          {loading && (
            <div className="absolute mt-1 w-96 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none text-sm z-50">
              <div className="text-xs text-gray-500">Recherche en cours...</div>
            </div>
          )}
          {companies.length > 0 && (
            <div className="absolute mt-1 w-96 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none text-sm z-50">
              {companies.map((option, index) => (
                <div key={index} onClick={() => handleOptionClick(option)} className="cursor-pointer py-2 px-4 hover:bg-gray-100">
                  <span className={`block truncate`}>{option.nom_entreprise}</span>
                  <span className={`block truncate text-xs text-gray-500`}>{`SIREN: ${option.siren}`}</span>
                  <span className={`block truncate text-xs text-gray-500`}>{`${option.siege?.ville}, ${option.siege?.code_postal}, ${option.siege?.adresse || ""}`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="space-y-2 w-full">
        <p className="text-center text-black font-semibold">Siren</p>
        <input
          type="text"
          name="company_siren"
          placeholder="Saisissez le numero SIREN"
          value={values.company_siren}
          onChange={(e) => setValues({ ...values, company_siren: e.target.value })}
          className="input"
        />
      </div>

      <div className="space-y-2 w-full">
        <p className="text-center text-black font-semibold">Quelle est la taille votre entreprise ?</p>
        <select className="select" value={values.company_category} onChange={(e) => setValues((prev) => ({ ...prev, company_category: e.target.value }))}>
          <option value="" disabled>
            Choisir la taille d'entreprise
          </option>
          {TYPES.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="space-y-2">
        <p className="text-center text-black font-semibold">Dans quel secteur d'activité votre entreprise excerce-t-elle ?</p>
        <select className="select" value={values.company_activity} onChange={(e) => setValues((prev) => ({ ...prev, company_activity: e.target.value }))}>
          <option value="" disabled>
            Choisir le secteur d'activité
          </option>
          {Object.entries(NAF_SECTIONS).map(([key, value]) => (
            <option key={key} value={value}>
              {`${key} - ${value}`}
            </option>
          ))}
        </select>
      </div>
    </section>
  );
};

const CompanyOption = ({ item }) => {
  return (
    <Listbox.Option value={item} className="cursor-pointer select-none py-2 pl-3 pr-9">
      {({ selected }) => (
        <div className="flex items-center gap-2">
          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{item.company_name}</span>
          {item.valuation && <span className="bg-primary p-1 rounded  text-xs text-white">Évaluation en cours</span>}
        </div>
      )}
    </Listbox.Option>
  );
};

export default CompanyForm;
