import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FaCheck } from "react-icons/fa6";
import { TbStack } from "react-icons/tb";

import { CALENDLY_COMMERCIAL } from "@/services/config";

const Product = ({ company, setCompany }) => {
  const { t } = useTranslation();

  const DIAGNOSTIC = [
    t("product.levee.diagnostic.one"),
    t("product.levee.diagnostic.two"),
    t("product.levee.diagnostic.three"),
    t("product.levee.diagnostic.four"),
    t("product.levee.diagnostic.five"),
    t("product.levee.diagnostic.six"),
  ];

  const RELATION = [
    t("product.levee.realtion.one"),
    t("product.levee.realtion.two"),
    t("product.levee.realtion.three"),
    t("product.levee.realtion.four"),
    t("product.levee.realtion.five"),
  ];

  return (
    <div className="px-8">
      <div className="flex flex-col items-center justify-center w-full">
        <div>
          <h1 className="text-center text-xl text-black font-semibold">Préparation sur mesure - Levée de fonds</h1>
        </div>
        <div className="py-4 space-y-4">
          <div className="w-full space-y-8">
            <div className="w-full">
              <h1 className="text-black text-center font-semibold">{t("product.title")}</h1>
            </div>
            <div className="grid grid-cols-3 gap-8 max-w-5xl">
              <div className="grid grid-rows-2 gap-8">
                <button
                  className={`border-2 ${
                    company.levee_plan === "PITCH_DECK" ? "border-primary" : "border-secondary"
                  } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                  onClick={() => setCompany({ ...company, levee_plan: "PITCH_DECK" })}
                >
                  <div className="flex flex-col items-center gap-1 mb-2">
                    <TbStack className="text-3xl text-primary" />
                    <p className="text-sm font-semibold">{t("product.levee.pitch_deck.title")}</p>
                  </div>
                  <div className="flex gap-2 items-end text-black">
                    <h2 className="text-3xl font-semibold">{t("product.levee.pitch_deck.price")}</h2>
                    <p className="font-semibold">{t("product.accelerated.taxes")}</p>
                  </div>
                  <p className="font-semibold">{t("product.levee.pitch_deck.maturity")}</p>

                  <div className="mt-4 flex-1">
                    <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
                    <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                      <li className="flex gap-2 text-sm">
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>
                        <p>{t("product.levee.pitch_deck.one")}</p>
                      </li>
                    </ul>
                  </div>
                  <Link to="form" className="primary-button mt-auto px-8">
                    {t("product.order")}
                  </Link>
                </button>
                <button
                  className={`border-2 ${
                    company.levee_plan === "BP" ? "border-primary" : "border-secondary"
                  } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                  onClick={() => setCompany({ ...company, levee_plan: "BP" })}
                >
                  <div className="flex flex-col items-center gap-1 mb-2">
                    <TbStack className="text-3xl text-primary" />
                    <p className="text-sm font-semibold">{t("product.levee.bp.title")}</p>
                  </div>
                  <div className="flex gap-2 items-end text-black">
                    <h2 className="text-3xl font-semibold">{t("product.levee.bp.price")}</h2>
                    <p className="font-semibold">{t("product.accelerated.taxes")}</p>
                  </div>
                  <p className="font-semibold">{t("product.levee.bp.maturity")}</p>

                  <div className="mt-4 flex-1">
                    <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
                    <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                      <li className="flex gap-2 text-sm">
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>
                        <p>{t("product.levee.bp.one")}</p>
                      </li>
                    </ul>
                  </div>
                  <Link to="form" className="primary-button mt-auto px-8">
                    {t("product.order")}
                  </Link>
                </button>
              </div>
              <button
                className={`border-2 ${
                  company.levee_plan === "DIAGNOSTIC" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                onClick={() => setCompany({ ...company, levee_plan: "DIAGNOSTIC" })}
              >
                <div className="flex flex-col items-center gap-1 mb-2">
                  <TbStack className="text-3xl text-primary" />
                  <p className="text-sm font-semibold">{t("product.levee.diagnostic.title")}</p>
                </div>
                <div className="flex gap-2 items-end text-black">
                  <h1 className="text-3xl font-semibold">{t("product.levee.diagnostic.price")}</h1>
                  <p className="font-semibold">{t("product.certified.taxes")}</p>
                </div>
                <p className="font-semibold">{t("product.levee.diagnostic.maturity")}</p>

                <div className="mt-4">
                  <p className="text-sm font-semibold">{t("product.certified.include")}</p>
                  <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                    {DIAGNOSTIC.map((item, i) => (
                      <li className="flex gap-2 text-sm" key={i}>
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>

                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <Link to="form" className="primary-button mt-auto px-8">
                  {t("product.order")}
                </Link>
              </button>

              <button
                className={`border-2 ${
                  company.levee_plan === "RELATION" ? "border-primary" : "border-secondary"
                } shadow-md rounded-lg p-6 text-primary flex flex-col items-center h-full`}
                onClick={() => setCompany({ ...company, levee_plan: "RELATION" })}
              >
                <div className="flex flex-col items-center gap-1 mb-2">
                  <TbStack className="text-3xl text-primary" />
                  <p className="text-sm font-semibold">{t("product.levee.realtion.title")}</p>
                </div>
                <div className="flex gap-2 items-end text-black">
                  <h1 className="text-3xl font-semibold">{t("product.levee.realtion.price")}</h1>
                  <p className="font-semibold">{t("product.certified.taxes")}</p>
                </div>
                <p className="font-semibold">{t("product.levee.realtion.maturity")}</p>

                <div className="mt-4">
                  <p className="text-sm font-semibold">{t("product.certified.include")}</p>
                  <ul className="flex flex-col gap-3 my-4 justify-center text-left">
                    {RELATION.map((item, i) => (
                      <li className="flex gap-2 text-sm" key={i}>
                        <div className="bg-light_blue rounded-full p-1 self-start">
                          <FaCheck className="text-xs" />
                        </div>

                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <Link to="form" className="primary-button mt-auto px-8">
                  {t("product.order")}
                </Link>
              </button>
            </div>

            <div className="flex flex-col item-center">
              <h2 className="text-lg text-black text-center underline font-medium">{t("product.bizzdev")}</h2>
              <div className="flex items-center justify-center mt-2">
                <a href={CALENDLY_COMMERCIAL} target="_blank" className="primary-button">
                  {t("product.contact")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
